import React, { useEffect, useState } from 'react';
import { Page } from 'components';
import EntityService from 'api/services/EntityService';
import { brands } from 'constants/brands';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';
import BrandCartStatistics from './components/BrandCartStatistics';

const SuperAdminDashboard = () => {
  const { t } = useTranslation('pages');

  const [carts, setCarts] = useState(null);
  const isReady = carts !== null;

  useEffect(() => {
    const fetch = async () => {
      const fetchedCarts = await EntityService.getOwnerStats();
      setCarts(pick(fetchedCarts, brands));
    };
    fetch();
  }, []);

  return (
    <Page loading={!isReady} withBackButton={false}>
      <div className="d-flex flex-row mb-4 align-items-center">
        <h1 className="m-0 mr-4">{t('dashboard.cartsStatistics')}</h1>
      </div>
      {!!carts &&
        Object.keys(carts).map(brand => (
          <BrandCartStatistics brand={brand} brandStats={carts[brand]} />
        ))}
    </Page>
  );
};

export default SuperAdminDashboard;
