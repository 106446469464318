import styled from 'styled-components';

export const StyledOverview = styled.div`
  position: relative;
  margin: 40px;
  border: 2px solid #0e43ba;
  padding: 20px;
  padding-top: 0;
  background-color: rgba(255, 255, 255, 0.45);
`;

export const InfoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: #0e43ba;
  color: white;
  font-family: cursive;
  font-size: 28px;
  text-align: center;
  line-height: 40px;
  border-radius: 0 0 50px 0;
  font-weight: 1000;
`;

const headingStyles = `
  font-family: 'MetaOT' !important;
  color: #120e02;
  margin-bottom: 8px;
  margin-top: 13px;
  text-align: center;
  letter-spacing: 0px;
`;

export const StyledHeading = styled.h2`
  ${headingStyles}
  font-size: 24px;
  font-weight: 1000;
  line-height: 32px;
`;

export const StyledDescription = styled.h2`
  ${headingStyles}
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 300px;
  height: 48px;
  text-transform: uppercase;
  ${({ $isFilled }) =>
    $isFilled
      ? `
    background: #120e02;
    color: #fff;
    border: none;
    `
      : `
    background: #fff;
    border: 1px solid #737373;
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px;
`;
