import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import useEntityForm from 'hooks/useEntityForm';
import { pick } from 'lodash';

const SizeForm = ({ type = 'create', initialValues, FormGroup, onActionSuccess }) => {
  const { t } = useTranslation('app');
  const { onSubmit, validationSchema } = useEntityForm(type, 'size');

  return (
    <Form
      initialValues={
        pick(initialValues, ['name', 'description', 'uid']) || {
          name: '',
          description: '',
        }
      }
      onSubmit={values => {
        onSubmit(values, onActionSuccess);
      }}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={t('name')}>
          <Form.Input type="text" name="name" />
        </FormGroup>
        <FormGroup label={t('description')}>
          <Form.Input as="textarea" name="description" />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(type)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

SizeForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  onActionSuccess: PropTypes.func,
};

export default SizeForm;
