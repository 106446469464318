import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { TransparentButton } from './styles';

const CloseButton = props => (
  <TransparentButton {...props}>
    <CloseIcon />
  </TransparentButton>
);

export default CloseButton;
