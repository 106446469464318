/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import capitalize from 'utils/capitalize';

import * as Ui from './AddedProduct.styles';

const AddedProduct = ({ productId, image, name, price, onClick, variants }) => {
  const [maskVisible, setMaskVisibility] = useState(false);
  const { t } = useTranslation(['pages', 'app']);
  return (
    <Ui.AddedProduct
      onMouseEnter={() => setMaskVisibility(true)}
      onMouseLeave={() => setMaskVisibility(false)}
    >
      <img alt="product" src={image} />
      <div className="data">
        <p
          className="name"
          dangerouslySetInnerHTML={{
            __html: capitalize(name),
          }}
        />
        <p>{price}</p>
        <p>
          {t('Looks.selectedVariants')} <b>{variants}</b>
        </p>
      </div>
      {maskVisible && (
        <Ui.Mask onClick={() => onClick(productId)}>
          <p>{t('app:remove')}</p>
        </Ui.Mask>
      )}
    </Ui.AddedProduct>
  );
};

AddedProduct.propTypes = {
  productId: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  variants: PropTypes.number,
  onClick: PropTypes.func,
};

export default AddedProduct;
