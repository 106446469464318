import CoreService from './CoreService';

class ReportService extends CoreService {
  /* eslint-disable no-useless-constructor */
  constructor(endpoint) {
    super(endpoint);
  }

  getAvailableReports() {
    return this.api.get(`/reports/available`);
  }

  getReportsHistory() {
    return this.api.get(`/reports`);
  }

  sendReportRequest(reportName, startDate, endDate) {
    return this.api.post('/reports', {
      report_name: reportName,
      start_date: startDate,
      end_date: endDate,
    });
  }
}

const instance = new ReportService('/v3');

export default instance;
