import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import { sortBy } from 'lodash';

const VALID_SOURCES = ['checkout', 'banner', 'header', ''];

const BrandCartStatistics = ({ brand, brandStats }) => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'dashboard' });

  const { cartsCount, sources } = brandStats;

  const validSources = sources.filter(s => VALID_SOURCES.includes(s.source));

  const notPresentSources = VALID_SOURCES.filter(
    s => !validSources.map(vs => vs.source).includes(s),
  );

  const agumentedSources = sortBy(
    [
      ...validSources,
      ...notPresentSources.map(source => ({ source, cart_with_items: 0, cart_without_items: 0 })),
    ],
    ['source'],
  );

  const getReadbleSource = source => (source ? prefixT(source) : prefixT('unknown'));

  const readableLabes = agumentedSources.map(s => ({ ...s, source: getReadbleSource(s.source) }));

  return (
    <Card>
      <Card.Header>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h1 className="m-0 mr-4">{brand}</h1>
          <p className="font-weight-bold">
            {prefixT('totalCarts')}: {cartsCount}
          </p>
        </div>
      </Card.Header>
      <Card.Body>
        <ResponsiveContainer width="100%" height="100%" minHeight="250px">
          <BarChart data={readableLabes}>
            <XAxis dataKey="source" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="cart_with_items" name={prefixT('cartsWithItems')} fill="#8884d8" />
            <Bar dataKey="cart_without_items" name={prefixT('cartsWithoutItems')} />
          </BarChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};

BrandCartStatistics.propTypes = {
  brand: PropTypes.string.isRequired,
  brandStats: PropTypes.shape({
    cartsCount: PropTypes.number,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string.isRequired,
        cart_with_items: PropTypes.number.isRequired,
        cart_without_items: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default BrandCartStatistics;
