import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toastr } from 'utils';
import { useSelector } from 'react-redux';
import { managerSelector, ownerSelector, brandSelector } from 'redux/auth/selectors';
import EntityService from 'api/services/EntityService';

export const useDashboardData = () => {
  const { t } = useTranslation('toastr');

  const isOwner = useSelector(managerSelector);
  const isManager = useSelector(ownerSelector);
  const isOwnerPortal = isOwner || isManager;
  const { preview } = useSelector(brandSelector);
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);

  const isAveda = preview === 'AVEDA';

  useEffect(() => {
    const callback = async () => {
      try {
        const response = await (isOwnerPortal
          ? EntityService.getOwnerDashboardUrl()
          : EntityService.getAdminDashboardUrl());

        setDashboardData(response);
        setLoading(false);
      } catch (e) {
        toastr.error(t('error'), e.message);
      }
    };
    callback();
  }, []);

  return { isOwnerPortal, isAveda, loading, dashboardData };
};
