import moment from 'moment/moment';
import momentTz from 'moment-timezone';

export const getRawDate = (date = '') => date?.substring(0, 10);
export const getRawTime = (date = '') => date?.substring(11, 16);

export const formatDateToTimeZone = (date, timezone) => {
  let momentDate;
  if (date) {
    momentDate = momentTz(date);
  }
  if (timezone) {
    momentDate = momentDate.tz(timezone, false);
  }
  return momentDate;
};

export const formatDate = date => {
  const formattedDate = getRawDate(date.toISOString(true));
  const hours = moment(date).hours();
  const minutes = moment(date).minutes();

  return `${formattedDate}T${hours > 9 ? hours : `0${hours}`}:${
    `${minutes}`.length === 1 ? `0${minutes}` : minutes
  }:00Z`;
};

export const createExactDate = dateTime =>
  new Date(
    dateTime.year(),
    dateTime.month(),
    dateTime.date(),
    dateTime.hours(),
    dateTime.minutes(),
    dateTime.seconds(),
  );

export const getInitialDate = (date, timezone) => {
  if (date) {
    return formatDate(createExactDate(formatDateToTimeZone(date, timezone)));
  }
  return '';
};
