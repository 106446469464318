import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormWrapper } from 'components/Modal/styles';
import { Wrapper, Modal, Form, Button } from 'components';
import useEntityForm from 'hooks/useEntityForm';
import { pick } from 'lodash';

const ColorForm = ({ type = 'create', initialValues, FormGroup, onActionSuccess }) => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'colors' });
  const { t } = useTranslation('app');

  const { onSubmit, validationSchema, dependencies } = useEntityForm(type, 'color', {
    dependencies: ['color_category', 'sku'],
  });

  return (
    <Form
      initialValues={
        pick(initialValues, ['name', 'hex_value', 'inventory', 'uid', 'category']) || {
          name: '',
          hex_value: '',
          inventory: 0,
          category: null,
        }
      }
      onSubmit={values => {
        onSubmit(values, onActionSuccess);
      }}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={t('name')}>
          <Form.Input type="text" name="name" />
        </FormGroup>
        <FormGroup label={prefixT('hex')}>
          <Form.ColorPicker name="hex_value" />
        </FormGroup>
        <FormGroup label={prefixT('colorCategory')}>
          <Form.MultiSelect
            options={dependencies.color_category.map(item => ({
              value: item.id,
              label: item.name,
            }))}
            isMulti={false}
            name="category"
          />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(type)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

ColorForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  onActionSuccess: PropTypes.func,
};

export default ColorForm;
