export const LOCATION_SELECT = 'LOCATION_SELECT';
export const UPDATE_HEADER = 'UPDATE_HEADER';

export const UPDATE_SELECTED_LOCATION = 'UPDATE_SELECTED_LOCATION';
export const SET_SAVING = 'SET_SAVING';

export const GET_LOCATION_GROUP = 'GET_LOCATION_GROUP';
export const GET_LOCATION_GROUP_SUCCESS = 'GET_LOCATION_GROUP_SUCCESS';
export const DELETE_LOCATION_GROUP = 'DELETE_LOCATION_GROUP';
export const CREATE_LOCATION_GROUP = 'CREATE_LOCATION_GROUP';
export const UPDATE_LOCATION_GROUP = 'UPDATE_LOCATION_GROUP';

export const CREATE_LOCATION_GROUP_SUCCESS = 'CREATE_LOCATION_GROUP_SUCCESS';
export const UPDATE_LOCATION_GROUP_SUCCESS = 'UPDATE_LOCATION_GROUP_SUCCESS';

export const DELETE_LOCATION_GROUP_SUCCESS = 'DELETE_LOCATION_GROUP_SUCCESS';
export const ADD_LOCATION_PAGE_ELEMENT = 'ADD_LOCATION_PAGE_ELEMENT';
export const ADD_LOCATION_PAGE_ELEMENT_SUCCESS = 'ADD_LOCATION_PAGE_ELEMENT_SUCCESS';
export const UPDATE_LOCATION_PAGE_ELEMENT = 'UPDATE_LOCATION_PAGE_ELEMENT';
export const UPDATE_LOCATION_PAGE_ELEMENT_SUCCESS = 'UPDATE_LOCATION_PAGE_ELEMENT_SUCCESS';
export const UPDATE_LOCATION_PAGE_ELEMENT_POSITION = 'UPDATE_LOCATION_PAGE_ELEMENT_POSITION';
export const UPDATE_LOCATION_PAGE_ELEMENT_POSITION_SUCCESS =
  'UPDATE_LOCATION_PAGE_ELEMENT_POSITION_SUCCESS';
export const DELETE_LOCATION_PAGE_ELEMENT = 'DELETE_LOCATION_PAGE_ELEMENT';
export const DELETE_LOCATION_PAGE_ELEMENT_SUCCESS = 'DELETE_LOCATION_PAGE_ELEMENT_SUCCESS';

export const GET_CUSTOM_ARTIST = 'GET_CUSTOM_ARTIST';
export const GET_CUSTOM_ARTIST_SUCCESS = 'GET_CUSTOM_ARTIST_SUCCESS';
export const RESET_CUSTOM_ARTIST = 'RESET_CUSTOM_ARTIST';
export const RESET_CUSTOM_ARTIST_SUCCESS = 'RESET_CUSTOM_ARTIST_SUCCESS';
export const PUBLISH_LOCATION_PAGE_ELEMENTS = 'PUBLISH_LOCATION_PAGE_ELEMENTS';
export const PUBLISH_LOCATION_PAGE_ELEMENTS_SUCCESS = 'PUBLISH_LOCATION_PAGE_ELEMENTS_SUCCESS';
export const DELETE_TOUR_PAGE_MODULE = 'DELETE_LAST_PAGE_MODULE';
