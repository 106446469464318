import CoreService from './CoreService';

class ImageService extends CoreService {
  uploadSignature(data) {
    return this.api.post('/v1/utils/uploadSignature', data);
  }

  uploadCloudinary(file, signature) {
    return new Promise((resolve, reject) => {
      if (signature.url.indexOf('https://api.cloudinary.com') !== 0) {
        reject(new Error('Invalid domain.'));
      }

      const data = new FormData();

      Object.entries(signature.params).forEach(([key, val]) => {
        data.append(key, val);
      });

      data.append(`file`, file);

      this.api
        .post(signature.url, data, {
          requireAuth: false,
        })
        .then(response => resolve(response))
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default new ImageService('');
