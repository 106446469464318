import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { Form, Modal, Table, Wrapper } from '../../components';
import ReportService from '../../api/services/ReportService';

const ReportsPage = ({ showReportsHistory = true }) => {
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'reports' });

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'set_reports':
        return { ...state, reports: payload, reportsLoaded: true };
      case 'set_reports_history':
        return { ...state, reportsHistory: payload, reportsHistoryLoaded: true };
      case 'clear':
        return {
          ...state,
          ...initialState,
        };
      case 'select_report':
        return { ...state, selectedReport: payload };
      case 'deselect_report':
        return { ...state, selectedReport: null };
      default:
        return state;
    }
  };

  const initialState = {
    reports: [],
    reportsLoaded: false,
    reportsHistory: [],
    reportsHistoryLoaded: false,
    selectedReport: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const formatTime = timeStr => {
    const date = new Date(timeStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const formatIdentifierToTitle = input => {
    let formattedString = input?.replace(/_/g, ' ').toLowerCase();
    formattedString = formattedString?.replace(/\b\w/g, letter => letter.toUpperCase());
    return formattedString;
  };

  const mapMinutesToString = calculationTime => {
    const seconds = Math.round(calculationTime * 60);

    if (seconds < 60) {
      return 'less than a minute';
    }

    const minutes = Math.round(calculationTime);
    return `${minutes} minutes`;
  };

  const fetchReports = () => {
    ReportService.getAvailableReports().then(response => {
      const updatedReports = response.map(report => ({
        ...report,
        last_request: report.last_request
          ? `last requested ${formatTime(report.last_request)}`
          : prefixT('reportNotRunning'),
        report_name_formated: formatIdentifierToTitle(report.name),
      }));

      dispatch({ type: 'set_reports', payload: updatedReports });
    });
  };

  const fetchHistory = () => {
    ReportService.getReportsHistory().then(response => {
      const updatedReports = response.map(report => ({
        ...report,
        completed_at: formatTime(report.completed_at),
        report_name_formated: formatIdentifierToTitle(report.report_title),
        calculation_time: mapMinutesToString(report.calculation_time),
      }));
      dispatch({ type: 'set_reports_history', payload: updatedReports });
    });
  };

  useEffect(() => {
    fetchReports();
    fetchHistory();
  }, []);

  const RequestReportModal = () => {
    const defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
    const [startDate, setStartDate] = useState(defaultStartDate.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    return (
      <Modal
        header={{
          title: prefixT('modalTitle'),
          subTitle: prefixT('modalSubtitle'),
        }}
        open={state.selectedReport}
        onClose={() => dispatch({ type: 'deselect_report' })}
        wide
      >
        <Modal.ClosableBody>
          {({ closePopup }) => (
            <Form
              initialValues={{}}
              onSubmit={async () => {
                await sendRequestButtonHandler(state.selectedReport.name, startDate, endDate);
                closePopup();
              }}
            >
              <Row>
                <div>{prefixT('selectedReport')}</div>
                <div>{state.selectedReport.report_name_formated}</div>
              </Row>
              <Row>
                <div>{prefixT('reportDescription')}</div>
                <div>{state.selectedReport.description}</div>
              </Row>

              {state.selectedReport.data_range_required === true && (
                <>
                  <Row>
                    <div>{prefixT('startData')}</div>
                    <div>
                      <input
                        type="date"
                        value={startDate}
                        onChange={event => setStartDate(event.target.value)}
                        style={{ maxWidth: '200px' }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div>{prefixT('endDate')}</div>
                    <div>
                      <input
                        type="date"
                        value={endDate}
                        onChange={event => setEndDate(event.target.value)}
                        style={{ maxWidth: '200px' }}
                      />
                    </div>
                  </Row>
                </>
              )}
              <br />
              <Wrapper d-flex justify-content-end>
                <Form.Submit>{prefixT('submitButton')}</Form.Submit>
              </Wrapper>
            </Form>
          )}
        </Modal.ClosableBody>
      </Modal>
    );
  };

  const sendRequestButtonHandler = (reportName, startDate, endDate) => {
    ReportService.sendReportRequest(reportName, startDate, endDate)
      .then(() => {
        toastr.success(prefixT('requestSendSuccess'));
        dispatch({ type: 'clear' });
        fetchReports();
        fetchHistory();
      })
      .catch(() => {
        toastr.error(prefixT('requestSendFailure'));
      });
  };

  const reports_table_columns = [
    {
      Header: prefixT('t1Name'),
      accessor: 'report_name_formated',
    },
    {
      Header: prefixT('t1Description'),
      accessor: 'description',
      sortable: false,
    },
    {
      Header: prefixT('t1RunningRequest'),
      accessor: 'last_request',
      sortable: false,
    },
  ];

  const history_table_columns = [
    {
      Header: prefixT('rowTitle'),
      accessor: 'report_name_formated',
    },
    {
      Header: prefixT('rowTime'),
      accessor: 'calculation_time',
    },
    {
      Header: prefixT('rowConfirmation'),
      accessor: 'completed_at',
      sortable: true,
    },
  ];

  const Row = ({ children }) => (
    <div style={{ display: 'flex', width: '100%', padding: '0 5%' }}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          style: {
            flex: index === 0 ? '1' : '2', // Apply flex ratio
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            ...child.props.style,
          },
        }),
      )}
    </div>
  );

  function DateInput({ date, setDate }) {
    return (
      <input
        type="date"
        value={date}
        onChange={event => setDate(event.target.text)}
        style={{ maxWidth: '200px' }}
      />
    );
  }

  Row.propTypes = {
    children: PropTypes.node.isRequired,
  };
  DateInput.propTypes = {
    date: PropTypes.string.isRequired,
    setDate: PropTypes.func.isRequired,
  };

  ReportsPage.propTypes = {
    showReportsHistory: PropTypes.bool,
  };

  return (
    <Fragment>
      <Container className="mt-5">
        <Table data={state.reports} loading={!state.reportsLoaded} columns={reports_table_columns}>
          <Table.Header title={prefixT('availableReports')}>
            <RequestReportModal />
          </Table.Header>
          <Table.Content
            deletable={false}
            onRowClick={selectedRow => {
              dispatch({
                type: 'select_report',
                payload: state.reports.find(report => report.id === selectedRow.id),
              });
            }}
          />
        </Table>

        {showReportsHistory && (
          <Table
            data={state.reportsHistory}
            loading={!state.reportsHistoryLoaded}
            columns={history_table_columns}
          >
            <Table.Header title={prefixT('deliveredReports')} />
            <Table.Content deletable={false} />
          </Table>
        )}
      </Container>
    </Fragment>
  );
};

export default ReportsPage;
