import styled from 'styled-components';

export const CoverPhotoWrapper = styled.div`
  position: relative;
  #dropzone-wrapper,
  #crop-wrapper {
    z-index: 100;
    width: 100%;
  }
`;

export const PhotosInnerWrapper = styled.div.attrs({
  className: 'position-relative',
})`
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

export const ImageWrapper = styled.div.attrs({})`
  max-width: 200px;
  position: relative;
  #cover-photo {
    // min-height: 200px;
    max-width: 200px;

    width: 100%;
  }
  .remove-photo {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #ffffffaa;
    border-radius: 50%;
    cursor: pointer;
  }
`;
