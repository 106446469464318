/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr/lib';
import locationReducer from 'redux/locations/reducer';
import tourReducer from 'redux/tour/reducer';
import authReducer from './redux/auth/reducer';
import entityReducer from './redux/entities/reducer';
import looksReducer from './redux/looks/reducer';
import featuredArtistsReducer from './redux/featuredArtists/reducer';
import { REDUCER_KEY as FEATURED_ARTISTS_REDUCER_KEY } from './redux/featuredArtists/constants';
import modalReducer from './redux/modal/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(history) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    auth: authReducer,
    entities: entityReducer,
    location: locationReducer,
    looks: looksReducer,
    [FEATURED_ARTISTS_REDUCER_KEY]: featuredArtistsReducer,
    modal: modalReducer,
    tourType: tourReducer,
  });

  return rootReducer;
}
