import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { superAdminSelector } from 'redux/auth/selectors';
import { useSelector } from 'react-redux';
import SuperAdminDashboard from './SuperAdminDashboard';
import { EmbeddedDashboard } from './components/EmbeddedDashboard/EmbeddedDashboard';

const Wrapper = ({ children }) => (
  <Container>
    <Row>
      <Col>{children}</Col>
    </Row>
  </Container>
);

Wrapper.propTypes = {
  children: PropTypes.any,
};

const Dashboard = () => {
  const isSuperAdmin = useSelector(superAdminSelector);

  if (isSuperAdmin)
    return (
      <Wrapper>
        <SuperAdminDashboard />
      </Wrapper>
    );

  return <EmbeddedDashboard />;
};
export default Dashboard;
