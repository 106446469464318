import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Card, Form, Wrapper } from 'components';
import * as Yup from 'yup';
import { registerUser } from 'redux/auth/actions';
import { Link } from 'react-router-dom';
import { AVEDA_LOGIN } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import aveda from '../../assets/img/owner_portal_logo.png';

const AvedaRegisterPage = () => {
  const { t } = useTranslation(['ownerPortal', 'pages', 'validation']);
  const query = useQuery();
  const dispatch = useDispatch();
  const formInitialValues = {
    name: query.get('name') || '',
    email: query.get('email') || '',
    password: '',
    passwordRepeat: '',
    verificationCode: query.get('verification_code') || '',
  };
  const formValidationSchema = Yup.object({
    name: Yup.string()
      .min(3, t('validation:atLeastChars', { number: 3 }))
      .required(t('validation:required')),
    email: Yup.string()
      .email('Invalid email address')
      .required(t('validation:required')),
    password: Yup.string()
      .min(6, t('validation:atLeastChars', { number: 6 }))
      .required(t('validation:required')),
    passwordRepeat: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    verificationCode: Yup.string().required(),
  });
  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '18px' }}>
            <Image className="mx-auto d-block w-75" alt="AVEDA" src={aveda} />
            <div style={{ padding: '16px 36px' }}>
              <h1 className="display-4 text-center text-uppercase mb-3">
                {t('avedaRegistration.register')}
              </h1>
              <p className="text-muted text-center mb-5">{t('avedaRegistration.subtitle')}</p>
              <Form
                initialValues={formInitialValues}
                onSubmit={async values => {
                  dispatch(registerUser({ ...values, isAveda: true }));
                }}
                validationSchema={formValidationSchema}
                validateOnMount
                initialTouched={{ name: true, email: true, verificationCode: true }}
              >
                <Form.Group>
                  <Form.Label>{t('adminRegistration.name')}</Form.Label>
                  <Form.Input
                    name="name"
                    type="text"
                    placeholder={t('adminRegistration.namePlaceholder')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('adminRegistration.email')}</Form.Label>
                  <Form.Input name="email" type="email" placeholder="jane@formik.com" />
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>{t('adminRegistration.password')}</Form.Label>
                    </Col>
                  </Row>
                  <Form.Input
                    type="password"
                    name="password"
                    placeholder={t('adminRegistration.passwordPlaceholder')}
                  />
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>{t('adminRegistration.rePassword')}</Form.Label>
                    </Col>
                  </Row>
                  <Form.Input
                    type="password"
                    name="passwordRepeat"
                    placeholder={t('adminRegistration.rePasswordPlaceholder')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('avedaRegistration.verificationCode')}</Form.Label>
                  <Form.Input name="verificationCode" type="text" />
                </Form.Group>

                <Wrapper mb-3>
                  <Form.Submit size="lg" variant="dark" block>
                    {t('avedaRegistration.submit')}
                  </Form.Submit>
                </Wrapper>
                <Wrapper d-flex justify-content-center align-items-center mt-5>
                  <Link to={AVEDA_LOGIN} className="h3 text-muted">
                    <p className="pr-3"> {t('adminRegistration.backToLogin')} </p>
                  </Link>
                </Wrapper>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AvedaRegisterPage;
