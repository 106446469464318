import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { useLocation } from 'react-router';

import { Form, Button } from 'components';
import { SkuForm } from 'containers/forms';
import Sidebar from 'components/Modal/modal';
import { useTranslation } from 'react-i18next';

const EditVariant = ({ cell }) => {
  const {
    id,
    sku,
    description,
    uid,
    color,
    inventory,
    size,
    retail_price,
    product_code,
    upc_code,
    is_primary,
    isEditable,
  } = cell.row.original;
  const { t } = useTranslation(['pages', 'app']);
  const params = useLocation();
  const productId = params.pathname.split('/').slice(-1)[0];

  return (
    <Sidebar
      trigger={
        <Button variant="link" className="text-center w-100">
          {id}
        </Button>
      }
      header={{
        title: t('product.variant'),
        subTitle: t('app:edit'),
      }}
      sidebar
    >
      <SkuForm
        initialValues={{
          sku,
          description,
          uid,
          base_product: productId,
          color: color?.id,
          inventory,
          retail_price,
          product_code,
          upc_code,
          id,
          is_primary,
          size,
        }}
        type="update"
        FormGroup={Form.GroupHorizontal}
        isEditable={isEditable}
      />
    </Sidebar>
  );
};

EditVariant.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        sku: PropTypes.string,
        description: PropTypes.string,
        uid: PropTypes.string,
        color: shape({
          id: PropTypes.string,
        }),
        inventory: PropTypes.number,
        retail_price: PropTypes.number,
        product_code: PropTypes.string,
        upc_code: PropTypes.string,
        is_primary: PropTypes.bool,
        isEditable: PropTypes.bool,
        size: shape({
          id: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default EditVariant;
