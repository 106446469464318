import { takeLatest, call, select, delay } from 'redux-saga/effects';
import * as types from './constants';

function* waitForConditionSaga(selector, regExp, action) {
  while (true) {
    const statePart = yield select(selector);
    if (regExp.test(statePart)) {
      action();
      break;
    }

    yield delay(100);
  }
}

export const utilsSagas = [
  // eslint-disable-next-line func-names
  takeLatest(types.WAIT_FOR_STATE_TO_MATCH, function*({ selector, regExp, action }) {
    yield call(waitForConditionSaga, selector, regExp, action);
  }),
];
