import styled from 'styled-components';

export const ProductSearchWrapper = styled.div.attrs({
  className: 'd-flex justify-content-between',
})`
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;

  .input-group-prepend {
    span {
      height: 100%;
      width: 30px;

      i {
        margin: 0 auto;
      }
    }
  }
`;

export const MultiselectWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 50px;

  flex: 1;
  > div {
    flex: 1;
    > div {
      border: 1px solid #e2ebf6;
      min-height: 50px;
    }
  }
`;

export const DropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #e9ecef;
  p {
    margin: 0;
    font-size: 13px;
  }

  desc {
    font-size: 11px;
  }

  span {
    font-size: 13px;
    color: orange;
    font-size: bold;
  }
`;

export const WrapperAutocomplete = styled.div`
  --aa-font-size: 12px;
  --aa-search-input-height: 38px;
  --aa-input-border-color-rgb: 233, 236, 239;
  --aa-input-border-color-alpha: 1;
  width: 50%;
  .aa-Form {
    height: 50px;
  }
  .aa-Form:focus-within {
    border-color: #2684ff;
    box-shadow: none;
  }
`;
