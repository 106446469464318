import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

import * as Ui from './DraftModal.styles';

export const DraftModal = ({ trigger, features }) => {
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'Looks' });
  const translations = {
    is_name_hint_dismissed: prefixT('nameHint'),
    is_cover_photo_hint_dismissed: prefixT('coverPhotoHint'),
    is_message_hint_dismissed: prefixT('messageHint'),
    is_add_product_hint_dismissed: prefixT('addProductHint'),
  };

  return (
    <Modal
      className="w-100"
      header={{
        title: prefixT('draftModalTitle'),
        subTitle: prefixT('draftModalSubtitle'),
      }}
      closeOnDocumentClick
      closeOnEscape
      closeButton
      trigger={trigger}
    >
      <ListGroup>
        {Object.keys(features).map(feature => (
          <ListGroup.Item className="d-flex justify-content-between">
            <Ui.FeatureName checked={features[feature]}>{translations[feature]}</Ui.FeatureName>
            {features[feature] ? <Ui.CheckIcon /> : <Ui.XIcon />}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Modal>
  );
};

DraftModal.propTypes = {
  trigger: PropTypes.node,
  features: PropTypes.objectOf({
    is_name_hint_dismissed: PropTypes.bool,
    is_cover_photo_hint_dismissed: PropTypes.bool,
    is_message_hint_dismissed: PropTypes.bool,
    is_add_product_hint_dismissed: PropTypes.bool,
  }),
};
