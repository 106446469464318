/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import stylistStatus from 'constants/stylistStatus';
import { Button } from 'react-bootstrap';

const SelectFilter = ({ column, filters, defaultValue = '', setFilter }) => {
  const [filterValue, setValue] = React.useState(
    () => filters.find(filter => filter.id === column)?.value || defaultValue,
  );

  const onChange = val => {
    setValue(val);
    setFilter(column, val);
  };

  useEffect(() => {
    onChange('TODO');
  }, []);

  return (
    <div className="d-flex flex-row">
      <Button
        onClick={() => onChange('TODO')}
        className="p-4 py-2 bg-light m-2 rounded-0"
        active={filterValue === 'TODO'}
        variant="light"
        style={{ cursor: 'pointer' }}
        role="button"
      >
        <span className="badge bg-warning p-2 mx-1"> </span>
        <span className={filterValue === 'TODO' ? 'font-weight-bold' : 'font-weight-normal'}>
          To do
        </span>
      </Button>
      <Button
        onClick={() => onChange(stylistStatus.APPROVED)}
        className="p-4 py-2 bg-light m-2 rounded-0"
        active={filterValue === stylistStatus.APPROVED}
        variant="light"
        style={{ cursor: 'pointer' }}
        role="button"
      >
        <span className="badge bg-success p-2 mx-1"> </span>
        <span
          className={
            filterValue === stylistStatus.APPROVED ? 'font-weight-bold' : 'font-weight-normal'
          }
        >
          Published
        </span>
      </Button>
      <Button
        onClick={() => onChange(stylistStatus.REJECTED)}
        className="p-4 py-2 bg-light m-2 rounded-0"
        active={filterValue === stylistStatus.REJECTED}
        variant="light"
        style={{ cursor: 'pointer' }}
        role="button"
      >
        <span className="badge bg-danger p-2 mx-1"> </span>
        <span
          className={
            filterValue === stylistStatus.REJECTED ? 'font-weight-bold' : 'font-weight-normal'
          }
        >
          Rejected
        </span>
      </Button>
      <Button
        onClick={() => onChange('')}
        className="p-4 py-2 bg-light m-2 rounded-0"
        active={filterValue === ''}
        variant="light"
        style={{ cursor: 'pointer' }}
        role="button"
      >
        <span className={filterValue === '' ? 'font-weight-bold' : 'font-weight-normal'}>All</span>
      </Button>
    </div>
  );
};

SelectFilter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  column: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
};

export default SelectFilter;
