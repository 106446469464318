import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { userSelector, userLoggingSelector } from 'redux/auth/selectors';
import { ADMIN_TOOL_LOGIN, MY_LOCATIONS, TERMS } from 'constants/routes';
import { Helmet } from 'react-helmet';
import { capitalize } from 'lodash';
import permissions from 'constants/permissions';
import { useTranslation } from 'react-i18next';
import { toastr } from '../../utils';

export const PermissionContext = React.createContext();

export function ProtectedRoute({ component: Component, condition, entity, ...rest }) {
  const user = useSelector(userSelector);
  const { t } = useTranslation('app');
  const isUserLogging = useSelector(userLoggingSelector);
  const isAdminToolUser = user.is_mac_admin || user.is_brand_admin || user.is_mac_super_admin;

  const getUserPermissions = () => {
    let role;
    if (user.is_mac_admin || user.is_brand_admin) {
      role = 'admin';
    }
    if (user.is_mac_super_admin) {
      role = 'superAdmin';
    }

    if (!entity) {
      return {
        read: true,
        create: true,
        update: true,
        delete: true,
      };
    }

    return {
      read: permissions[role].read.includes(entity),
      create: permissions[role].create.includes(entity),
      update: permissions[role].update.includes(entity),
      delete: permissions[role].delete.includes(entity),
    };
  };
  if (!user.acceptedTerms && !isAdminToolUser) return <Redirect to={{ pathname: TERMS }} />;

  return (
    <Route
      {...rest}
      render={props => {
        // eslint-disable-next-line
        const pageName = props.location.pathname.split('/').slice(-1)[0];

        if (isUserLogging) {
          return null;
        }

        if (typeof condition !== 'undefined' && !condition) {
          toastr.error(t('noAccess'), t('noAccessRedirect'));
          return <Redirect to={MY_LOCATIONS} />;
        }

        if (user.email) {
          return (
            <PermissionContext.Provider
              value={{
                permission: getUserPermissions(),
              }}
            >
              <Helmet>
                <title>{capitalize(pageName)}</title>
              </Helmet>
              <Component {...props} />
            </PermissionContext.Provider>
          );
        }

        return (
          <Redirect
            to={{
              pathname: ADMIN_TOOL_LOGIN, // eslint-disable-next-line
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  entity: PropTypes.string,
  condition: PropTypes.bool.isRequired,
  rest: PropTypes.any,
};
