const entities = {
  brand: 'brand',
  brand_content: 'brand_content',
  bundle: 'bundle',
  category: 'category',
  color: 'color',
  color_category: 'color_category',
  ingredient: 'ingredient',
  product: 'product',
  product_group: 'product_group',
  region: 'region',
  retailer: 'retailer',
  size: 'size',
  sku: 'sku',
  tier: 'tier',
  theme: 'theme',
  admin: 'admin',
  location: 'location',
  available_sellers: 'available_sellers',
  available_stores: 'available_stores',
  available_single_product_stores: 'available_single_product_stores',
  welcome_screen: 'welcome_screen',
  store_templates: 'store_templates',
  product_image: 'product_image',
  learning_center: 'learning_center',
  learning_center_post: 'learning_center_post',
  custom_artists: 'custom_artists',
  owner_group_page: 'owner_group_page',
  location_page_module: 'location_page_module',
};

export const API_V4_ENTITIES = [entities.location, entities.retailer, entities.product];

export default entities;
