import {
  ASSIGN_TO_ENTITY,
  ASSIGN_TO_ENTITY_SUCCESS,
  CLEAR,
  DELETE_VARIANT,
  DELETE_VARIANT_SUCCESS,
  FETCH_FLAG_DOWN,
  IMPORT_SELLERS,
  QR_CODE_FETCHED,
  UPDATE_VARIANT,
  UPDATE_VARIANT_SUCCESS,
} from './constants';

const actions = ['CREATE', 'UPDATE', 'FETCH', 'DELETE'];

export const entityActions = actions.reduce(
  (o, key) => ({
    ...o,
    [`${key.toLowerCase()}Entity`]: (entity, data, onActionSuccess, onFailureAction) => ({
      type: `${key}_${entity.toUpperCase()}`,
      payload: { data, onActionSuccess, onFailureAction },
    }),
    [`${key.toLowerCase()}EntitySuccess`]: (entity, data) => ({
      type: `${key}_${entity.toUpperCase()}_SUCCESS`,
      payload: data,
    }),
    [`${key.toLowerCase()}EntityFailure`]: (entity, data) => ({
      type: `${key}_${entity.toUpperCase()}_FAILURE`,
      payload: data,
    }),
  }),
  {},
);

export function assignTo(entityType, entityId, values) {
  return {
    type: ASSIGN_TO_ENTITY,
    payload: { entityType, entityId, values },
  };
}

export function assignToSuccess(entityType, entity) {
  return {
    type: ASSIGN_TO_ENTITY_SUCCESS,
    payload: { entityType, entity },
  };
}

export function updateVariant(data) {
  return {
    type: UPDATE_VARIANT,
    payload: data,
  };
}

export function deleteVariant(uid, productId) {
  return {
    type: DELETE_VARIANT,
    payload: { uid, productId },
  };
}

export function deleteVariantSuccess(uid, productId) {
  return {
    type: DELETE_VARIANT_SUCCESS,
    payload: { uid, productId },
  };
}

export function updateVariantSuccess(sku) {
  return {
    type: UPDATE_VARIANT_SUCCESS,
    payload: sku,
  };
}

export function clearData() {
  return {
    type: CLEAR,
  };
}

export function fetchFlagDown(entity) {
  return {
    type: FETCH_FLAG_DOWN,
    payload: entity,
  };
}

export function setQr(qr, shortId) {
  return {
    type: QR_CODE_FETCHED,
    payload: { qr, shortId },
  };
}

export function importSellers(data) {
  return {
    type: IMPORT_SELLERS,
    payload: data,
  };
}
