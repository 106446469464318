export const mapRoleToString = (isOwner, isManager) => {
  if (isOwner) return 'Owner';
  if (isManager) return 'Manager';
  return 'Artist';
};

export const mapInitialValues = (initialValues, regionId, defaultProductGroup) => {
  const mappedInitialValues = {
    ...initialValues,
    region: initialValues?.region || regionId,
    product_groups:
      initialValues?.product_groups.length > 0
        ? initialValues?.product_groups
        : [defaultProductGroup?.id],
    role: mapRoleToString(initialValues?.owner, initialValues?.manager),
  };

  return mappedInitialValues;
};

export const getLocationUidById = (locations, ids) =>
  ids.map(id => locations.find(location => location.id === Number(id)));

export const getLocationsByOwner = (locations, ownerId) =>
  locations.filter(({ owners }) => owners.includes(ownerId)).map(location => location.id);
