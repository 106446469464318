import config from 'config';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isAvedaSelector } from 'redux/auth/selectors';
import { isJiraIssueCollector } from 'utils/featureFlags.ts';

const elementId = 'jira-issue-collector';

export const useJIRAIssueCollector = (enable = false) => {
  const isAveda = useSelector(isAvedaSelector);
  const setCollector = () => {
    const appElement = document.querySelector('body');
    const scriptElement = document.querySelector(`#${elementId}`);
    if (scriptElement) {
      scriptElement.remove();
    }
    if (appElement) {
      const snippet = document.createElement('script');
      snippet.id = elementId;
      snippet.async = true;
      snippet.type = 'text/javascript';
      snippet.src = config.jiraIssueCollectorUrl.mac;
      appElement.appendChild(snippet);
    }
  };

  useEffect(() => {
    // Only run if feature flag is enabled
    if (!isJiraIssueCollector()) {
      return;
    }
    if (enable && !isAveda) {
      setCollector();
    }
  }, [isAveda, enable]);
};
