import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'utils';
import useVisibility from 'react-use-visibility';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EditorState } from 'draft-js';
import { Form, Wrapper, Page } from 'components';
import history from 'utils/history';
import { LOOK_TEMPLATE } from 'constants/routes';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';

import {
  getSingleLook,
  deleteLook,
  updateLook,
  updateLookIsPrivate,
  clearLook,
  getAvailableProducts,
} from 'redux/looks/actions';
import {
  availableProductsSelector,
  lookProductsSelector,
  singleLookSelector,
} from 'redux/looks/selectors';
import * as hints from 'constants/lookFeatureHints';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { SCHEDULE_LOOK_MODAL } from 'redux/modal/constants';
import { closeModalAction, openModalAction } from 'redux/modal/actions';
import { getInitialDate, getRawDate, getRawTime } from 'utils/date';

import { isLookExpirationDate, isBrandLookEditable } from 'utils/featureFlags.ts';
import ProductCard from '../../components/ProductCard/ProductCard';
import { DescriptionEditor } from '../../components/DescriptionEditor/DescriptionEditor';
import { CoverPhoto } from '../../components/CoverPhoto/CoverPhoto';
import EditLookFooter from '../../components/EditLookFooter/EditLookFooter';
import * as Ui from './EditTemplatePage.styles';
import ProductPage from '../AddProductsPage/AddProductsPage';
import DatePickerModal from './components/DatePickerModal';
import { useTimeZones } from './hooks/useTimeZones';

const EditTemplatePage = () => {
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'Looks' });
  const { t } = useTranslation('toastr');
  const { id } = useParams();
  const { data: look, fetched } = useSelector(singleLookSelector);
  const looksProducts = useSelector(lookProductsSelector);

  const availableProducts = useSelector(availableProductsSelector);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const dispatch = useDispatch();
  const formRef = useRef();
  const lookHeader = useRef();

  const islookHeaderVisible = useVisibility(lookHeader.current);
  const timeZones = useTimeZones(id);
  const initialValues = {
    name: look?.feature_hints[[hints.NAME_HINT]] || !look?.is_editable ? look?.name : '',
    images: look?.pictures[0] ? [look.pictures[0]] : [],
    is_private: !look?.is_private || false,
    is_editable: look?.is_editable || false,

    enabled: look?.enabled || false,
    is_primary: look?.is_primary || false,
  };

  useEffect(() => {
    dispatch(getAvailableProducts()); // dispatch it in this place so there is no need to fetch products each time user moves to looks

    if (!look || look._id !== id) {
      dispatch(getSingleLook(id));
    }
    return () => {
      dispatch(clearLook(id));
    };
  }, [id]);

  const onLookNameChange = async e => {
    const updatedValue = e.target.value;
    const isNameValid = !(look.is_draft && !updatedValue);

    if (updatedValue !== look.name) {
      dispatch(
        updateLook(
          {
            name: updatedValue,
            feature_hints: {
              [hints.NAME_HINT]: isNameValid,
            },
          },
          'name',
        ),
      );
    }
  };

  const onVisibilityChange = async e => {
    const isPrivate = !e.target.checked;

    dispatch(updateLookIsPrivate(isPrivate));
  };

  const onEditFlagChange = async e => {
    const isEditable = e.target.checked;

    dispatch(
      updateLook(
        {
          is_editable: isEditable,
        },
        'is_editable',
      ),
    );
  };

  const handleLookDelete = () => {
    toastr.confirm(t('deleteLook'), {
      onOk: () => {
        dispatch(deleteLook(id, true));
      },
      cancelText: t('cancel'),
      okText: t('delete'),
      dispatch,
    });
  };

  const handleExpirationDateSave = (startDate, endDate, timezone) => {
    if (!timezone) {
      toastr.error(t('error'), t('timezoneError'));
      return;
    }

    if (!startDate) {
      toastr.error(t('error'), t('dateError'));
      return;
    }

    dispatch(
      updateLook(
        {
          start_date: startDate,
          expiration_date: endDate,
          timezone,
        },
        'expiration dates',
      ),
    );

    dispatch(closeModalAction());
  };

  return (
    <Page
      style={{ background: 'white' }}
      loading={!fetched}
      withBackButton={false}
      avedaPage
      footer={{
        component: (
          <EditLookFooter
            look={look}
            onDelete={handleLookDelete}
            onSave={() => history.push(LOOK_TEMPLATE)}
            isEditable
          />
        ),
        visible: !islookHeaderVisible,
      }}
    >
      <Ui.PageHeader>
        <h1>{prefixT(`${look?.feature_hints[[hints.SAVED_HINT]] ? 'edit' : 'create'}Look`)}</h1>
      </Ui.PageHeader>
      <Ui.Look>
        <Form
          style={{ width: '100%' }}
          initialValues={initialValues}
          enableReinitialize
          innerRef={formRef}
        >
          <Wrapper row w-100>
            <Col sm={12} lg={12}>
              <Ui.SectionTitle>
                {prefixT('changeCoverPhoto')}
                <span style={{ color: 'red' }}>*</span>
              </Ui.SectionTitle>
              <CoverPhoto
                aspect_ratio={
                  look?.feature_hints.is_cover_photo_hint_dismissed ? look?.aspect_ratio : null
                }
                photo={look?.pictures[0]}
                isEditable
              />
              {!!availableProducts.length && (
                <>
                  <Ui.SectionTitle>
                    {prefixT('products')}
                    <span style={{ color: 'red' }}>*</span>
                  </Ui.SectionTitle>
                  <div
                    className="d-flex flex-nowrap justify-content-start"
                    style={{
                      gap: 8,
                      margin: 0,
                      overflowX: 'auto',
                      display: 'flex',
                    }}
                  >
                    {looksProducts.map(prod => (
                      <Col style={{ minWidth: 250, maxWidth: 250, height: '100%' }}>
                        <ProductCard
                          lookId={id}
                          parentProduct={availableProducts.find(
                            parentProd => parentProd.uid === prod.parent_product,
                          )}
                          lookProduct={prod}
                          isEditionScreen
                          isEditable
                        />
                      </Col>
                    ))}
                    <ProductPage />
                  </div>
                </>
              )}
            </Col>
            <Col sm={12} lg={12} className="px-3 d-flex flex-column">
              <Form.GroupHorizontal label={prefixT('name')} required>
                <Form.Input name="name" onBlur={onLookNameChange} />
              </Form.GroupHorizontal>
              <Form.GroupHorizontal
                className="d-flex flex-column m-0"
                label={prefixT('description')}
                style={{ flex: 1, minHeight: '200px' }}
                required
              >
                <DescriptionEditor
                  isEditable
                  editorState={editorState}
                  setEditorState={setEditorState}
                  message={look?.message}
                />

                <Ui.RowSectionWrapper>
                  <Ui.Row>
                    <div className="d-flex align-items-center">
                      <AiOutlineEyeInvisible />
                      <p className="m-0">{prefixT('publicView')}</p>
                    </div>
                    <Form.Toggle name="is_private" onChange={onVisibilityChange} />
                  </Ui.Row>
                </Ui.RowSectionWrapper>

                {isBrandLookEditable() && (
                  <Ui.RowSectionWrapper>
                    <Ui.Row>
                      <div className="d-flex align-items-center">
                        <AiOutlineEyeInvisible />
                        <p className="m-0">Editable</p>
                      </div>
                      <Form.Toggle name="is_editable" onChange={onEditFlagChange} />
                    </Ui.Row>
                  </Ui.RowSectionWrapper>
                )}

                {isLookExpirationDate() && (
                  <Ui.RowSectionWrapper>
                    <Ui.Row>
                      <div className="d-flex align-items-center">
                        <BiCalendar />
                        <p className="m-0">Schedule</p>
                      </div>
                      <DateRangePicker
                        onClick={() => dispatch(openModalAction(SCHEDULE_LOOK_MODAL))}
                        start={
                          look?.start_date
                            ? {
                                date: getRawDate(getInitialDate(look?.start_date, look?.timezone)),
                                time: getRawTime(getInitialDate(look?.start_date, look?.timezone)),
                              }
                            : null
                        }
                        end={
                          look?.expiration_date
                            ? {
                                date: getRawDate(
                                  getInitialDate(look?.expiration_date, look?.timezone),
                                ),
                                time: getRawTime(
                                  getInitialDate(look?.expiration_date, look?.timezone),
                                ),
                              }
                            : null
                        }
                      />
                    </Ui.Row>
                  </Ui.RowSectionWrapper>
                )}
              </Form.GroupHorizontal>
            </Col>
          </Wrapper>
        </Form>
      </Ui.Look>

      {isLookExpirationDate && (
        <DatePickerModal
          onSave={handleExpirationDateSave}
          startDate={getInitialDate(look?.start_date, look?.timezone)}
          endDate={getInitialDate(look?.expiration_date, look?.timezone)}
          timeZones={timeZones}
          timeZone={look?.timezone}
        />
      )}
    </Page>
  );
};

export default EditTemplatePage;
