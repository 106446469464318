import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TourProvider } from '@reactour/tour';
import { useDispatch, useSelector } from 'react-redux';
import { locationAmountSelector, tourCompletionStatusSelector } from 'redux/auth/selectors';
import {
  deleteAddedPageModule,
  finishTourAction,
  resetRenderedState,
  setTourTypeActionBackend,
} from 'redux/tour/actions';
import { MULTI_LOCATION_USER, NEW_USER, RETURNING_USER } from 'redux/tour/constants';
import { WAIT_FOR_STATE_TO_MATCH } from 'redux/utilsSagas/constants';
import { isTour } from 'utils/featureFlags.ts';
import { selectDidComponentRender } from 'redux/tour/selectors';
import * as Ui from './TourOverview.styles';
import TourSteps from './TourSteps';
import { deleteTourPageModule } from '../../../../redux/locations/actions';

const TourAppWrapper = ({ children }) => {
  const locationAmount = useSelector(locationAmountSelector);
  const isFromHowToHub = useSelector(state => state.tourType.isFromHowToHub);
  const tourCompletionStatus = useSelector(tourCompletionStatusSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFromHowToHub) return; // do not overwrite user selected tour

    let fetchedTourType;
    switch (tourCompletionStatus) {
      case 'Not Started':
        fetchedTourType = locationAmount > 1 ? MULTI_LOCATION_USER : NEW_USER;
        break;
      case 'Completed':
        fetchedTourType = '';
        break;
      case 'Needs Redo':
        fetchedTourType = RETURNING_USER;
        break;
      default:
        return; // we expect only one of the above 3 values to occur
    }

    dispatch(setTourTypeActionBackend(fetchedTourType));
  }, []);

  if (!isTour()) return children;

  const isPrevButtonDisabled = (currentStep, stepsData) =>
    !!stepsData[currentStep - 1]?.actionBeforeNextStep;

  const handlePrevStep = (currentStep, setCurrentStep, setIsOpen) => {
    if (currentStep === 0) return setIsOpen(false);
    setCurrentStep(curr => curr - 1);
  };

  const prevButton = ({ currentStep, setCurrentStep, steps: stepsData, setIsOpen }) => (
    <Ui.StyledButton
      $isFilled={false}
      onClick={() => handlePrevStep(currentStep, setCurrentStep, setIsOpen)}
      disabled={isPrevButtonDisabled(currentStep, stepsData)}
    >
      Previous
    </Ui.StyledButton>
  );

  prevButton.propTypes = buttonPropTypes;

  const handleNextStep = (currentStep, setCurrentStep, stepsData, setIsOpen) => {
    const handle = () => {
      if (currentStep === stepsData.length - 1) {
        setIsOpen(false);
        dispatch(finishTourAction());
        return;
      }
      setCurrentStep(curr => curr + 1);
    };

    const step = stepsData[currentStep];
    if (step.actionBeforeNextStep) step.actionBeforeNextStep();

    if (step.componentStateToWaitFor) {
      dispatch({
        type: WAIT_FOR_STATE_TO_MATCH,
        selector: state => selectDidComponentRender(state, step.componentStateToWaitFor),
        regExp: /^true$/, // as the saga is more generic we have to pass regExp that will catch a boolean true
        action: handle,
      });
    } else handle();
  };

  const nextButton = ({ currentStep, setCurrentStep, steps: stepsData, setIsOpen }) => (
    <Ui.StyledButton
      $isFilled
      onClick={() => handleNextStep(currentStep, setCurrentStep, stepsData, setIsOpen)}
    >
      {currentStep === stepsData.length - 1 ? 'Done' : 'Next'}
    </Ui.StyledButton>
  );

  nextButton.propTypes = buttonPropTypes;

  const handleKeyboard = (e, clickProps) => {
    const { currentStep, setCurrentStep, steps: stepsData, setIsOpen } = clickProps;

    if (e.key === 'ArrowLeft' && !isPrevButtonDisabled(currentStep, stepsData)) {
      handlePrevStep(currentStep, setCurrentStep, setIsOpen);
    } else if (e.key === 'ArrowRight') {
      handleNextStep(currentStep, setCurrentStep, stepsData, setIsOpen);
    } else if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  return (
    <TourProvider
      steps={[]}
      showDots={false}
      scrollSmooth
      badgeContent={({ totalSteps, currentStep }) => `${currentStep + 1}/${totalSteps}`}
      prevButton={prevButton}
      nextButton={nextButton}
      keyboardHandler={handleKeyboard}
      onTransition={(_, prev) => [prev.x, prev.y]}
      afterOpen={() => dispatch(resetRenderedState())}
      beforeClose={() => {
        dispatch(resetRenderedState());
        // Remove the added page module from both backend and frontend
        dispatch(deleteAddedPageModule());
        dispatch(deleteTourPageModule());
      }}
    >
      <TourSteps>{children}</TourSteps>
    </TourProvider>
  );
};

const buttonPropTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  setIsOpen: PropTypes.func.isRequired,
};

TourAppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TourAppWrapper;
