import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaInfo } from 'react-icons/fa';

import { ReactComponent as SuccessIcon } from 'assets/svg/okInputIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/svg/errorInputIcon.svg';
import { validateOnlyNumbersAndLetters } from 'utils/validators';
import * as UrlPathNameUi from './UrlPathname.styles';

export const UrlPathname = ({
  domain,
  pathname,
  pathnameProps,
  buttonLabel,
  onButtonClick,
  notification,
  onChange,
  setNotification,
}) => {
  const [path, setPath] = useState(pathname);
  const [isChangedValue, setIsChangedValue] = useState(!onChange);
  const buttonRef = useRef();

  const validateInput = value => {
    if (!validateOnlyNumbersAndLetters(value)) {
      setNotification({ type: 'error', text: 'The path can only contain letters and numbers' });
    }
  };

  return (
    <>
      <UrlPathNameUi.Wrapper>
        <UrlPathNameUi.Domain>{domain}</UrlPathNameUi.Domain>

        <UrlPathNameUi.PathNameWrapper>
          <UrlPathNameUi.PathName
            value={path}
            onChange={e => {
              setPath(e.target.value);
              setIsChangedValue(true);
              if (onChange) {
                onChange(e.target.value);
              }
              validateInput(e.target.value);
            }}
            {...pathnameProps}
          />
          {isChangedValue && (
            <UrlPathNameUi.Button
              type="button"
              ref={buttonRef}
              onClick={() => {
                onButtonClick(domain, path, buttonRef);
              }}
            >
              {buttonLabel}
            </UrlPathNameUi.Button>
          )}
        </UrlPathNameUi.PathNameWrapper>
      </UrlPathNameUi.Wrapper>
      {notification && (
        <UrlPathNameUi.MessageWrapper>
          {notification.type === 'success' ? (
            <SuccessIcon />
          ) : notification.type === 'info' ? (
            <FaInfo size={14} color="#0040c1" />
          ) : (
            <ErrorIcon />
          )}

          <UrlPathNameUi.Message type={notification.type}>
            {notification.text}
          </UrlPathNameUi.Message>
        </UrlPathNameUi.MessageWrapper>
      )}
    </>
  );
};

UrlPathname.propTypes = {
  domain: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  pathnameProps: PropTypes.object,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  notification: PropTypes.shape({
    type: PropTypes.oneOf(['success', 'error', 'info']),
    text: PropTypes.string,
  }),
  onChange: PropTypes.func,
  setNotification: PropTypes.func,
};
