import entities from 'constants/entities';

export default {
  admin: {
    read: Object.values(entities).filter(
      entity => ![entities.admin, entities.region, entities.brand].includes(entity),
    ),
    update: Object.values(entities).filter(
      entity => ![entities.admin, entities.brand].includes(entity),
    ),
    create: Object.values(entities).filter(
      entity => ![entities.admin, entities.region, entities.brand].includes(entity),
    ),
    delete: Object.values(entities).filter(
      entity => ![entities.admin, entities.region, entities.brand].includes(entity),
    ),
  },
  superAdmin: {
    read: Object.values(entities),
    update: Object.values(entities).filter(entity =>
      [entities.admin, entities.region, entities.brand].includes(entity),
    ),
    create: Object.values(entities).filter(entity =>
      [entities.admin, entities.region, entities.brand, entities.theme].includes(entity),
    ),
    delete: Object.values(entities).filter(entity =>
      [entities.admin, entities.region, entities.brand].includes(entity),
    ),
  },
};
