import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'components';
import { entityHOC } from 'components/hoc/entityHOC';
import { useTranslation } from 'react-i18next';
import { OwnerGroupForm } from 'pages/Locations/PageComponents/OwnerGroupForm/OwnerGroupForm';

const OwnerGroupPage = ({ locations }) => {
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'ownerGroupPage' });

  return (
    <Page
      title={prefixT('editOwnerGroupPage')}
      description={prefixT('description')}
      withBackButton={false}
      avedaPage
    >
      <div className="main-card card h-100 w-100" style={{ maxWidth: 800 }}>
        <div className="card-header">{prefixT('ownerGroupPage')}</div>
        <div className="card-body">
          <OwnerGroupForm locations={locations} />
        </div>
      </div>
    </Page>
  );
};

OwnerGroupPage.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default entityHOC(OwnerGroupPage, 'location');
