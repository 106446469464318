import React, { useEffect, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';

import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import EntityService from 'api/services/EntityService';

import * as Ui from './LooksCollection.styles';
import { LookSlide } from './components/LookSlide';
import LocationPageModulePlaceholder from '../../EmptyModulePlaceholder/EmptyModulePaceholder';

const LooksCollection = ({ component }) => {
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [primaryBundle, setPrimaryBundle] = useState();

  useEffect(() => {
    async function fetchBundleData() {
      if (component.primary_store) {
        const { store } = await EntityService.getBundle(component.primary_store);
        setPrimaryBundle(store);
      }

      if (component.non_primary_stores) {
        const selectedBundlesData = await Promise.all(
          component.non_primary_stores.map(async short_id => {
            const { store } = await EntityService.getBundle(short_id);
            return store;
          }),
        );
        setSelectedBundles(selectedBundlesData);
      }
    }

    fetchBundleData();
  }, [component]);

  if (!primaryBundle) {
    return (
      <Row>
        <LocationPageModulePlaceholder />
      </Row>
    );
  }

  return (
    <Ui.Wrapper>
      <Ui.Container>
        <Ui.CarouselWrapper>
          <ScrollContainer className="d-flex w-100 position-relative" vertical>
            <LooksCollectionPureComponent looks={[primaryBundle, ...selectedBundles]} />
          </ScrollContainer>
        </Ui.CarouselWrapper>
      </Ui.Container>
    </Ui.Wrapper>
  );
};

const LooksCollectionPureComponent = ({ looks, onClick, widget = false }) => (
  <Ui.Wrapper widget={widget}>
    <Ui.Container>
      <Ui.SectionTitle widget={widget}>Shoppable looks</Ui.SectionTitle>
      <Ui.CarouselWrapper>
        <ScrollContainer className="d-flex w-100 position-relative" vertical>
          {looks.map(look => (
            <LookSlide key={look.short_id} look={look} onClick={onClick} widget={widget} />
          ))}
        </ScrollContainer>
      </Ui.CarouselWrapper>
    </Ui.Container>
  </Ui.Wrapper>
);

LooksCollection.propTypes = {
  component: PropTypes.object.isRequired,
};
LooksCollectionPureComponent.propTypes = {
  looks: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  widget: PropTypes.bool,
};

export default LooksCollection;
export { LooksCollectionPureComponent };
