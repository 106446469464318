import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button, CreateEntityModal } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import useEntityForm from 'hooks/useEntityForm';
import { pick } from 'lodash';

const SkuForm = ({ type = 'create', initialValues, FormGroup, isEditable = true, ...rest }) => {
  const { t } = useTranslation(['pages', 'app']);
  const { onSubmit, validationSchema, dependencies } = useEntityForm(type, 'sku', {
    dependencies: ['color', 'size'],
  });
  const { productId, retailPrice } = rest;
  const mappedInitialValues = { ...initialValues, size: initialValues.size.id };
  return (
    <Form
      initialValues={
        mappedInitialValues
          ? pick(mappedInitialValues, [
              'sku',
              'description',
              'uid',
              'base_product',
              'color',
              'inventory',
              'retail_price',
              'product_code',
              'upc_code',
              'is_primary',
              'size',
            ])
          : {
              sku: '',
              description: '',
              base_product: productId,
              color: null,
              inventory: null,
              retail_price: retailPrice,
              product_code: '',
              upc_code: '',
              is_primary: false,
              size: null,
            }
      }
      onSubmit={values =>
        onSubmit({
          ...values,
          inventory: values.inventory === '' ? null : values.inventory,
        })
      }
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={t('skus.sku')}>
          <Form.Input disabled={!isEditable} type="text" name="sku" />
        </FormGroup>
        <FormGroup label={t('app:description')}>
          <Form.Input disabled={!isEditable} as="textarea" name="description" />
        </FormGroup>
        {initialValues.color !== undefined && (
          <FormGroup label={t('createProduct.color')}>
            <Form.MultiSelect
              disabled={!isEditable}
              options={dependencies.color.map(color => ({
                value: color.id,
                label: color.name,
              }))}
              name="color"
              isMulti={false}
            />
            {isEditable && (
              <CreateEntityModal
                title={t('colors.createColor')}
                subTitle={t('colors.colors')}
                entity="color"
                trigger="link"
              />
            )}
          </FormGroup>
        )}

        {initialValues.size !== undefined && (
          <FormGroup label={t('createProduct.size')}>
            <Form.MultiSelect
              disabled={!isEditable}
              options={dependencies.size.map(size => ({
                value: size.id,
                label: size.name,
              }))}
              name="size"
              isMulti={false}
            />
            {isEditable && (
              <CreateEntityModal
                title={t('colors.createColor')}
                subTitle={t('colors.colors')}
                entity="size"
                trigger="link"
              />
            )}
          </FormGroup>
        )}

        <FormGroup label={t('createProduct.price')}>
          <Form.Input disabled={!isEditable} type="number" name="retail_price" />
        </FormGroup>
        <FormGroup label={t('createProduct.upc_code')}>
          <Form.Input disabled={!isEditable} type="text" name="upc_code" />
        </FormGroup>
        <FormGroup label={t('createProduct.productCode')}>
          <Form.Input disabled={!isEditable} type="text" name="product_code" />
        </FormGroup>
        <FormGroup label={t('createProduct.isPrimarySku')}>
          <Form.MultiSelect
            isMulti={false}
            options={[{ value: true, label: 'true' }, { value: false, label: 'false' }]}
            name="is_primary"
          />
        </FormGroup>
      </FormWrapper>
      {isEditable && (
        <Modal.Footer>
          {({ closePopup }) => (
            <Wrapper d-flex justify-content-end>
              <Button onClick={closePopup} variant="white" className="mr-3">
                {t('app:cancel')}
              </Button>

              <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
            </Wrapper>
          )}
        </Modal.Footer>
      )}
    </Form>
  );
};

SkuForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  isEditable: PropTypes.bool,
};

export default SkuForm;
