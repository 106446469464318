import produce from 'immer';
import * as types from './constants';

export const initialState = {
  availableProducts: [],
  userLooks: {
    fetched: false,
    data: [],
  },
  look: {
    fetched: false,
    data: null,
  },
  filteredProducts: [],
  favouriteProducts: {
    fetched: false,
    data: [],
  },
};

const looksReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case types.GET_USER_LOOKS:
        draft.userLooks.fetched = false;
        draft.favouriteProducts.fetched = false;
        break;
      case types.GET_AVAILABLE_PRODUCTS_SUCCESS:
        draft.availableProducts = payload;
        break;
      case types.GET_LOOK:
        draft.look.data = null;
        draft.look.fetched = false;
        break;
      case types.GET_LOOK_SUCCESS:
        draft.look.data = payload;
        draft.look.fetched = true;
        draft.look.data.is_draft =
          !payload.feature_hints.is_saved_hint_dismissed && !payload.from_store_template;

        break;
      case types.GET_USER_LOOKS_SUCCESS:
      // fallthrough
      case types.GET_TEMPLATE_LOOKS_SUCCESS:
        draft.userLooks.data = payload.filter(look => !look.is_single_product);
        draft.userLooks.fetched = true;

        draft.favouriteProducts.data = payload.filter(
          look => look.is_single_product && !look.is_draft,
        );
        draft.favouriteProducts.fetched = true;
        break;
      case types.DELETE_LOOK_SUCCESS:
        draft.userLooks.data = draft.userLooks.data.filter(look => look._id !== payload);
        break;
      case types.DELETE_TEMPLATE_LOOK_SUCCESS:
        draft.userLooks.data = draft.userLooks.data.filter(look => look.uid !== payload);
        break;
      case types.DELETE_FAVOURITE_PRODUCT_SUCCESS:
        draft.favouriteProducts.data = draft.favouriteProducts.data.filter(
          look => look._id !== payload,
        );
        break;
      case types.SEARCH_PRODUCTS_SUCCESS:
        draft.filteredProducts = payload;
        break;

      case types.UPDATE_LOOK_COVER_PHOTO_SUCCESS:
        draft.look.data.cover_photo = payload;
        break;

      case types.UPDATE_LOOK_IS_PRIVATE_SUCCESS:
        draft.look.data.is_private = payload;
        break;
      case types.UPDATE_LOOK_SUCCESS:
        draft.look.data = { ...draft.look.data, ...payload };
        draft.look.data.is_draft =
          !payload.feature_hints.is_saved_hint_dismissed && !payload.from_store_template;
        break;
      case types.ADD_PRODUCTS_TO_LOOK_SUCCESS:
        draft.look.data.products = [...draft.look.data.products, ...payload];
        break;
      case types.REMOVE_PRODUCT_FROM_LOOK_SUCCESS: {
        const index = draft.look.data.products.findIndex(
          product => product._id === payload.productId,
        );
        if (index !== -1) draft.look.data.products.splice(index, 1);
        break;
      }
      case types.GET_COVER_PHOTOS_SUCCESS:
        draft.look.data.cover_photos = payload;
        break;
      case types.ADD_COVER_PHOTO:
        draft.look.data.cover_photos.push(payload);
        break;
      case types.REMOVE_COVER_PHOTO: {
        // TODO: remove cover photo from state
        const index = draft.look.data.cover_photos.findIndex(
          coverPhoto => coverPhoto.uid === payload,
        );
        if (index !== -1) draft.look.data.cover_photos.splice(index, 1);
        break;
      }
      case types.CLEAR_LOOK:
        draft.look.data = null;
        draft.look.fetched = false;
        break;
      default:
        return state;
    }
  });

export default looksReducer;
