import styled from 'styled-components';

export const Wrapper = styled.span.attrs({
  className: 'btn-square btn active ml-2',
})`
  font-size: 14px;
  text-align: left;
`;

export const DateButton = styled.span``;

export const HourButton = styled.span.attrs({
  className: 'text-danger pl-2',
})``;
