import { all } from 'redux-saga/effects';
import { authSagas } from './auth/sagas';
import { entitySagas } from './entities/sagas';
import { locationSagas } from './locations/sagas';
import { looksSagas } from './looks/sagas';
import { featuredArtistsSagas } from './featuredArtists/sagas';
import { utilsSagas } from './utilsSagas';

export default function* root() {
  yield all([
    ...authSagas,
    ...entitySagas,
    ...locationSagas,
    ...looksSagas,
    ...featuredArtistsSagas,
    ...utilsSagas,
  ]);
}
