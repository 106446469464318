// import { Container as Ctr } from 'react-bootstrap';

import styled from 'styled-components';
import { media } from 'utils/rwd';

export const Slide = styled.div`
  ${s => s.widget && 'flex: 0 0 auto;'}
  width: ${s => (s.widget ? '70vh' : '190%')};
  padding-right: 10px;
  ${media.lg`
    padding-right: 20px;
  `}
  cursor: pointer;

  ${({ noSlide, widget }) =>
    noSlide &&
    `
    ${!widget && 'width: calc(16.6666% - 9px);'}
    padding-right: 0 !important;
  `}
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  background-color: white;
`;

export const Name = styled.p`
  margin: 4px 0 0 0;
  color: white;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

export const CoverPhotoWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow-y: auto;
  position: relative;
  :before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;
