import React from 'react';
import PropTypes from 'prop-types';

import SliderFilter from './sliderFilter';
import TextFilter from './textFilter';
import NumberRangeFilter from './numberRangeFilter';
import SelectFilter from './selectFilter';
import SelectSalonFilter from './selectSalonFilter';
import SelectStatusFilter from './selectStatusFilter';

const classNames = 'mb-0';
const wrapperStyle = {
  minWidth: '250px',
};

const Filter = props => {
  switch (props.type) {
    case 'slider':
      return <SliderFilter {...props} wrapperStyle={wrapperStyle} className={classNames} />;
    case 'text':
      return <TextFilter {...props} wrapperStyle={wrapperStyle} className={classNames} />;
    case 'numberRange':
      return <NumberRangeFilter {...props} wrapperStyle={wrapperStyle} className={classNames} />;
    case 'select':
      return <SelectFilter {...props} wrapperStyle={wrapperStyle} className={classNames} />;
    case 'selectSalon':
      return <SelectSalonFilter {...props} wrapperStyle={wrapperStyle} className={classNames} />;
    case 'userStatus':
      return <SelectStatusFilter {...props} wrapperStyle={wrapperStyle} className={classNames} />;
    default:
      break;
  }
};

export const sortQuery = (columnId, desc) => {
  let sortKey = 'sort';
  let sortValue = desc ? `-${columnId}` : columnId;
  switch (columnId) {
    case 'tier':
      sortValue = desc ? '-tier__id' : 'tier__id';
      break;
    case 'profile_page_status':
      sortValue = desc ? '-user__approval_status' : 'user__approval_status';
      break;
    case 'product_groups':
      sortKey = 'product_groups_count';
      sortValue = desc ? 'dsc' : 'asc';
      break;
    case 'role':
      sortKey = 'roles';
      sortValue = desc ? 'dsc' : 'asc';
      break;
    case 'participants.length':
      sortKey = 'artists_count';
      sortValue = desc ? 'dsc' : 'asc';
      break;
    case 'views':
      sortKey = 'views_count';
      sortValue = desc ? 'dsc' : 'asc';
      break;
    case 'connected_user.last_login':
      sortValue = desc ? '-user__last_login' : 'user__last_login';
      break;
    default:
      break;
  }

  return { sortKey, sortValue };
};

Filter.propTypes = {
  column: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['slider', 'text', 'numberRange', 'select']),
};

export default Filter;
