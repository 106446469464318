import React, { useState, useEffect } from 'react';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import moment from 'moment';
import commonQSDashboardOptions from 'constants/commonQSDashboardOptions';
import { relativeTimeRanges } from 'constants/relativeTimeRanges';
import { Page } from 'components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDashboardData } from '../../hooks/useDashboardData';
import { SheetHeader } from '../SheetHeader/SheetHeader';
import { useAutomaticRefresh } from '../../hooks/useAutomaticRefresh';

export const EmbeddedDashboard = () => {
  const { loading, dashboardData } = useDashboardData();
  const [activeSheet, setActiveSheet] = useState('Overview');
  const { t } = useTranslation('ownerPortal');

  useAutomaticRefresh();

  const selectedSheetChangeCallback = sheet => {
    setActiveSheet(sheet.selectedSheet.name);
    localStorage.setItem('sheetId', sheet.selectedSheet.sheetId);
  };

  useEffect(() => {
    if (loading) return;
    const containerDiv = document.getElementById('embeddingContainer');
    const sheetId = localStorage.getItem('sheetId');
    const options = {
      url: dashboardData.data,
      container: containerDiv,
      parameters: {
        fromDate: moment()
          .subtract(relativeTimeRanges[0].durationInDays, 'days')
          .format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
      },
      ...(sheetId ? { sheetId } : {}),
      selectedSheetChangeCallback,
      ...commonQSDashboardOptions,
    };

    QuickSightEmbedding.embedDashboard(options);
  }, [loading, dashboardData]);

  if (!dashboardData && !loading) {
    return <div>Unexpected errors occurred</div>;
  }

  return (
    <Page loading={loading} avedaPage withBackButton={false} style={{ height: '100vh' }}>
      <Helmet>
        <title>{t('pageTitles.showDashboard')}</title>
      </Helmet>
      <div className="d-flex flex-column w-100 h-100">
        <SheetHeader activeSheet={activeSheet} />
        <div id="embeddingContainer" className="w-100 h-100" />
      </div>
    </Page>
  );
};
