import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Logo, Wrapper } from 'components';
import { useTranslation } from 'react-i18next';

const PageNotFound = ({ location }) => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'pageNotFound' });
  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Wrapper text-center>
          <Logo style={{ padding: '50px 0', maxWidth: 200 }} />
        </Wrapper>
        <Col xs="12" md="8" lg="6">
          <div style={{ padding: '16px 36px' }}>
            <h1 className="display-1 text-center text-uppercase my-5">404</h1>
            <h1 className="display-4 text-center text-uppercase mb-3">{prefixT('title')}</h1>
            {location?.state ? (
              <p className="text-muted text-center mb-5">
                {prefixT('page')}
                <span>
                  <b className="text-secondary">{` ${location.state.from.pathname} `}</b>
                </span>
                {prefixT('subtitle')}
                <a href="/"> {prefixT('title')}</a>
              </p>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
PageNotFound.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PageNotFound;
