/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { addProductsToLook, getUserLooks } from 'redux/looks/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddedProduct from 'pages/Looks/components/AddedProduct/AddedProduct';
import { lookProductsSelector, singleLookSelector } from 'redux/looks/selectors';
import { productPrice } from 'utils/products';
import LooksService from 'api/services/LooksService';
import history from 'utils/history';

import AddProductCard from '../../components/AddProductCard/AddProductCard';
import ProductSearch from '../../components/ProductSearch/ProductSearch';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';

import * as Ui from './AddProductsPage.styles';
import useHistoryRedirect from '../../../../hooks/useHistoryRedirect';
import { entityActions } from '../../../../redux/entities/actions';
import { locationSelector } from '../../../../redux/locations/selectors';

const AddProductsModal = ({ isSingle = false, trigger = null }) => {
  const dispatch = useDispatch();
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'Looks' });
  const { t } = useTranslation('app');
  const { data: look } = useSelector(singleLookSelector);
  const location = useSelector(locationSelector);

  const lookProducts = useSelector(lookProductsSelector);
  const [productsToAdd, setProductsToAdd] = useState([]);

  const [searchedProducts, setSearchedProducts] = useState([]);
  const { visibleItems, loadNextItems } = useInfiniteScroll(searchedProducts);
  const redirectUrl = useHistoryRedirect();

  const addProducts = async onSuccess => {
    const payload = productsToAdd.map(p => ({
      store_id: look._id,
      parent_product: p.parent_product,
      sub_skus: p.selected_variants,
      primary_sku: p.primary_sku,
    }));
    dispatch(addProductsToLook(payload));
    setProductsToAdd([]);
    if (onSuccess) {
      onSuccess();
    }
  };

  const batchCreateLooks = async onSuccess => {
    const promises = productsToAdd.map(product => {
      const payload = {
        parent_product: product.parent_product,
        sub_skus: product.selected_variants,
        primary_sku: product.primary_sku,
      };
      return LooksService.createSingleLook(payload);
    });

    await Promise.allSettled(promises);
    await dispatch(getUserLooks());
    onSuccess?.();

    if (redirectUrl) {
      // Correctly display added product in Location Module
      dispatch(
        entityActions.fetchEntity('available_single_product_stores', {
          uid: location.uid,
        }),
      );
    }
  };

  const handleAddProduct = productToBeAdded => {
    setProductsToAdd([...productsToAdd, productToBeAdded]);
  };

  const isEditableOrSingleProduct = () => {
    if (isSingle) {
      return false;
    }
    return !(look?.is_editable || look?.is_template);
  };

  return (
    <Ui.Modal
      header={{ title: isSingle ? 'Add to favorites' : prefixT('addProductsTitle') }}
      closeOnDocumentClick={false}
      trigger={trigger || <Ui.AddProductsButton>{prefixT('addProducts')}</Ui.AddProductsButton>}
      footer={closePopup => (
        <Ui.Footer>
          {!!productsToAdd.length && (
            <Ui.SelectedProducts>
              <p className="text-muted m-0 text-uppercase title">{prefixT('selectedProducts')}</p>
              <Ui.SelectedProductsRow>
                {productsToAdd.map(product => (
                  <AddedProduct
                    name={product.name}
                    variants={product.selected_variants.length}
                    image={product.image}
                    price={productPrice(product.sub_skus)}
                    productId={product.parent_product}
                    onClick={productId =>
                      setProductsToAdd(productsToAdd.filter(p => p.parent_product !== productId))
                    }
                  />
                ))}
              </Ui.SelectedProductsRow>
            </Ui.SelectedProducts>
          )}

          <Ui.ButtonsWrapper>
            <Button variant="outline-dark" onClick={closePopup}>
              {t('back')}
            </Button>
            <Button
              variant="dark"
              disabled={!productsToAdd.length || isEditableOrSingleProduct()}
              onClick={() => {
                if (isSingle) {
                  batchCreateLooks(closePopup);
                  if (redirectUrl) {
                    history.push(redirectUrl, {
                      successMessage: 'Product added successfully!',
                    });
                  }
                  return;
                }
                addProducts(closePopup);
              }}
            >{`Save ${productsToAdd.length ? `(${productsToAdd.length})` : ''} `}</Button>
          </Ui.ButtonsWrapper>
        </Ui.Footer>
      )}
    >
      <ProductSearch setSearchedProducts={setSearchedProducts} />
      {!!visibleItems.length && (
        <Ui.ProductsWrapper
          scrollableTarget="modal-content"
          dataLength={visibleItems.length}
          next={loadNextItems}
          hasMore
        >
          {visibleItems.map(p => (
            <AddProductCard
              key={p.uid}
              lookId={look?._id}
              parentProduct={p}
              lookProduct={lookProducts.find(product => product.parent === p.uid)}
              onProductAdd={productToBeAdded => handleAddProduct(productToBeAdded)}
              addedProducts={productsToAdd}
            />
          ))}
        </Ui.ProductsWrapper>
      )}
    </Ui.Modal>
  );
};

AddProductsModal.propTypes = {
  isSingle: PropTypes.bool,
  trigger: PropTypes.node,
};

export default AddProductsModal;
