import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    padding: 0;
    margin: 0;
  }
`;

export const ArrowWrapper = styled.div`
  width: 14px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: rgba(82, 82, 82, 1);
  margin-bottom: 24px;
`;

export const SalonLink = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: center;
  margin-bottom: 24px;
`;

export const Button = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: #120e02;
  color: #fff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  order: 0;
  align-self: stretch;
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

export const DownloadButtonsWrapper = styled.a`
  display: flex;
  gap: 16px;
  margin: 0 56px 24px 56px;
`;

export const EditSalonLink = styled.button`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: center;
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 24px 0;
`;

export const VanityLinkAbout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  background: var(--neutrals-100, #f5f5f5);
  margin: 16px 0;

  p {
    color: var(--neutrals-800, #262626);
    /* Paragraph/Assistive/Semibold */
    font-family: 'MetaOTBold';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 0;
  }

  ul {
    padding-left: 1rem;
    margin-bottom: 0;
  }

  li {
    color: var(--neutrals-800, #262626);
    /* Paragraph/Assistive/Regular */
    font-size: 12px;
    line-height: 18px;
  }
`;
