import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash-es';
import { LOOKS } from 'constants/routes';
import { createLookFromDraft } from 'redux/looks/actions';
import { Button } from 'components';

import { useHistory } from 'react-router-dom';
import * as Ui from './EditLookFooter.styled';
import { DraftModal } from '../DraftModal/DraftModal';
import { PreviewButton } from '../PreviewButton/PreviewButton';
import useHistoryRedirect from '../../../../hooks/useHistoryRedirect';

const EditLookFooter = ({ onDelete, look, onSave, isEditable }) => {
  const dispatch = useDispatch();
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'Looks' });
  const history = useHistory();
  const redirectUrl = useHistoryRedirect();

  const renderCreateButton = button => {
    if (look.feature_hints.is_saved_hint_dismissed) {
      return null;
    }

    const isLookToSave = Object.keys(omit(look.feature_hints, 'is_saved_hint_dismissed')).reduce(
      (acc, curr) => acc && look.feature_hints[curr],
      true,
    );

    if (isLookToSave) {
      return React.cloneElement(button, {
        onClick: () => dispatch(createLookFromDraft(redirectUrl)),
      });
    }

    return (
      <DraftModal trigger={button} features={omit(look.feature_hints, 'is_saved_hint_dismissed')} />
    );
  };

  return (
    <Ui.Wrapper>
      {isEditable && (
        <Button
          size="lg"
          variant="link"
          onClick={onDelete}
          className="text-danger text-decoration-underline"
        >
          {prefixT('deleteLook')}
        </Button>
      )}
      <div>
        <PreviewButton />

        {isEditable && (
          <>
            <Button
              size="lg"
              variant={look.is_draft ? 'outline-dark' : 'dark'}
              onClick={() => (onSave ? onSave() : history.push(LOOKS))}
            >
              {look.is_draft ? prefixT('saveDraft') : 'Save'}
            </Button>

            {renderCreateButton(
              <Button size="lg" variant="dark">
                {prefixT('publish')}
              </Button>,
            )}
          </>
        )}
      </div>
    </Ui.Wrapper>
  );
};

EditLookFooter.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,

  look: PropTypes.shape({
    is_editable: PropTypes.bool,
    is_template: PropTypes.bool,
    from_store_template: PropTypes.bool,
    is_draft: PropTypes.bool,
    feature_hints: PropTypes.shape({
      is_name_hint_dismissed: PropTypes.bool,
      is_cover_photo_hint_dismissed: PropTypes.bool,
      is_message_hint_dismissed: PropTypes.bool,
      is_add_product_hint_dismissed: PropTypes.bool,
      is_saved_hint_dismissed: PropTypes.bool,
    }),
  }),
  isEditable: PropTypes.bool,
};
export default EditLookFooter;
