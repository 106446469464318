import entities from 'constants/entities';

const prefixes = ['FETCH_', 'CREATE_', 'UPDATE_', 'DELETE_'];
const postfixes = ['', '_SUCCESS', '_FAILURE'];

const mappedActions = name =>
  prefixes
    .flatMap(prefix => postfixes.map(postfix => `${prefix}${name}${postfix}`))
    .reduce((acc, actionName) => ({ ...acc, [actionName]: actionName }), {});

const entityActionTypes = Object.keys(entities).reduce((o, key) => ({
  ...o,
  ...mappedActions(key.toUpperCase()),
}));

export { entityActionTypes };

export const ASSIGN_TO_ENTITY = 'ASSIGN_TO_ENTITY';
export const ASSIGN_TO_ENTITY_SUCCESS = 'ASSIGN_TO_ENTITY_SUCCESS';
export const ASSIGN_TO_ENTITY_FAILURE = 'ASSIGN_TO_ENTITY_FAILURE';
export const DELETE_VARIANT = 'DELETE_VARIANT';
export const DELETE_VARIANT_SUCCESS = 'DELETE_VARIANT_SUCCESS';
export const DELETE_VARIANT_FAILURE = 'DELETE_VARIANT_FAILURE';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const UPDATE_VARIANT_SUCCESS = 'UPDATE_VARIANT_SUCCESS';
export const UPDATE_VARIANT_FAILURE = 'UPDATE_VARIANT_FAILURE';
export const CLEAR = 'CLEAR';
export const FETCH_FLAG_DOWN = 'FETCH_FLAG_DOWN';
export const QR_CODE_FETCHED = 'QR_CODE_FETCHED';
export const IMPORT_SELLERS = 'IMPORT_SELLERS';
