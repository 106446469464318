import { useCallback, useState, useRef, useEffect } from 'react';

import EntityService from 'api/services/EntityService';

export const useVanityUrlCheck = (entity = 'location') => {
  const [vanityUrl, setVanityUrl] = useState(0);
  const [triggerIndex, setTriggerIndex] = useState(0);
  const [notification, setNotification] = useState();
  const [timeoutIndex, setTimeoutIndex] = useState();
  const [isCurrentVanityUrlValid, setIsCurrentVanityUrlValid] = useState(false);

  const indexRef = useRef();

  useEffect(() => {
    const fetch = async () => {
      const response = await EntityService.checkVanityUrlAvailability(entity, vanityUrl);
      const { valid, message: messageText } = response;
      setNotification({ type: valid ? 'success' : 'error', text: messageText });
      setIsCurrentVanityUrlValid(valid);
      const index = setTimeout(() => {
        setNotification();
      }, 4000);
      setTimeoutIndex(index);
    };

    if (triggerIndex && indexRef.current !== triggerIndex) {
      fetch();
      indexRef.current = triggerIndex;
    }
  }, [triggerIndex, vanityUrl]);

  const triggerCheck = useCallback(
    vanity => {
      setVanityUrl(vanity);
      setTriggerIndex(triggerIndex + 1);
    },
    [triggerIndex],
  );

  return {
    triggerCheck,
    notification,
    clearNotification: () => {
      clearTimeout(timeoutIndex);
      setNotification();
    },
    setNotification,
    isCurrentVanityUrlValid,
  };
};
