import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  .BioEditor-editorContainer {
    height: 100%;
  }

  .rdw-editor-wrapper {
    border-radius: 0 !important;
    border: 1px solid var(--borders-border-input-default, #525252) !important;
  }
`;
