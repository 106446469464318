import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Button, Card, Form, Wrapper } from 'components';
import * as Yup from 'yup';
import { loginAveda } from 'redux/auth/actions';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import config from 'config';
import { Link } from 'react-router-dom';
import { FORGET_PASSWORD, MY_LOCATIONS } from 'constants/routes';
import aveda from '../../assets/img/owner_portal_logo.png';
import { isSsoEnabled } from '../../utils/featureFlags.ts';

const AvedaLogin = () => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'login' });
  const { t } = useTranslation(['ownerPortal', 'validation']);
  const dispatch = useDispatch();
  const query = useQuery();
  const redirectAddress = query.get('redirectUrl') || MY_LOCATIONS;
  const formInitialValues = {
    email: '',
    password: '',
    countryCode: '',
    phoneNumber: '',
  };
  const formValidationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required(t('validation:required')),
    password: Yup.string()
      .min(6, t('validation:atLeastChars', { number: 6 }))
      .required(t('validation:required')),
  });

  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '72px' }}>
            <Image className="mx-auto d-block w-75" alt="AVEDA" src={aveda} />
            <div style={{ padding: '16px 36px' }}>
              <h1 className="display-4 text-center text-uppercase mb-3">{t('avedaLogin.title')}</h1>
              <p className="text-muted text-center mb-5">{t('avedaLogin.subtitle')}</p>
              <Form
                initialValues={formInitialValues}
                onSubmit={async ({ email, password }) => {
                  dispatch(loginAveda(email, password, redirectAddress));
                }}
                validationSchema={formValidationSchema}
              >
                <Form.Group>
                  <Form.Label>{prefixT('email')}</Form.Label>
                  <Form.Input name="email" type="email" placeholder="jane@formik.com" />
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>{prefixT('password')}</Form.Label>
                    </Col>
                  </Row>
                  <Form.Password name="password" placeholder={prefixT('passwordPlaceholder')} />
                </Form.Group>
                <Wrapper mb-3>
                  <Form.Submit size="lg" variant="dark" block>
                    {prefixT('login')}
                  </Form.Submit>
                </Wrapper>
                {isSsoEnabled() && (
                  <Wrapper mb-3>
                    <Button size="lg" variant="light" block href={config.ssoUrl}>
                      {prefixT('sso')}
                    </Button>
                  </Wrapper>
                )}
                <Wrapper d-flex justify-content-center align-items-center mt-5>
                  <Link to={FORGET_PASSWORD} className="h3 text-muted">
                    {prefixT('forgetPassword')}
                  </Link>
                </Wrapper>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AvedaLogin;
