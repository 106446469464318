import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Form, Wrapper, CreateEntityModal } from 'components';
import useEntityForm from 'hooks/useEntityForm';
import { compact, omit } from 'lodash';
import { regionsSelector, userSelector } from 'redux/auth/selectors';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import LocalStorageService from 'api/services/LocalStorageService';
import { sanitizeHtml } from 'utils/htmlString';
import { uploadImages } from 'utils/mediaResourceUtils';
import { ImageUpload } from 'components/Form/inputs/ImageUpload/ImageUpload';
import ProductVariant from 'containers/ProductVariant/ProductVariant';
import { productPrice } from 'utils/products';

const ProductForm = ({ type = 'create', initialValues }) => {
  const { t } = useTranslation(['pages', 'app']);
  const formSubmited = useRef(false);
  const { email } = useSelector(userSelector);
  const [region] = useSelector(regionsSelector);
  const { onSubmit, validationSchema, dependencies } = useEntityForm(type, 'product', {
    dependencies: ['color', 'category', 'size'],
  });

  const mappedInitialValues = { ...initialValues };
  mappedInitialValues.size = initialValues.sub_skus.map(sub => sub.size?.id);
  mappedInitialValues.color = initialValues?.sub_skus?.map(sub => sub.color?.id);
  mappedInitialValues.name = sanitizeHtml(initialValues.name);
  mappedInitialValues.details = sanitizeHtml(initialValues.details);
  mappedInitialValues.description = sanitizeHtml(initialValues.description);
  mappedInitialValues.short_description = sanitizeHtml(initialValues.short_description);
  mappedInitialValues.retail_price = productPrice(initialValues.sub_skus, region.base_currency);
  const isEditable = initialValues.is_editable;

  return (
    <>
      <Form
        initialValues={mappedInitialValues}
        notifyOnFormErrors
        onSubmit={async values => {
          const payload = { ...values };

          const image_ids = await uploadImages(values.image_ids);
          formSubmited.current = true;

          payload.keywords = compact(payload.keywords).map(i => ({ name: i }));
          payload.sku = values.product_will_have_variants
            ? {
                retail_price: values.retail_price,
                inventory: values.inventory || 0,
                product_code: values.product_code,
                upc_code: values.upc_code,
              }
            : {
                sku: values.sku,
                retail_price: values.retail_price,
                inventory: values.inventory || 0,
                product_code: values.product_code,
                upc_code: values.upc_code,
              };

          payload.vat_percentage = values.vat_percentage / 100;
          payload.commission = values.commission / 100;

          onSubmit({
            ...omit(payload, [
              'images',
              'inventory',
              'retail_price',
              'product_code',
              'upc_code',
              type === 'update' ? 'sku' : '',
            ]),
            image_ids,
          });
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, getFieldProps }) => {
          const productWithVariants = values.sub_skus.length > 1;

          const autoselectNewlyCreatedEntity = (id, field, isMulti) => {
            let updatedValue;
            if (isMulti) {
              const { value } = getFieldProps(field);
              updatedValue = value ? [...value, id] : [id];
            } else {
              updatedValue = id;
            }

            setFieldValue(field, updatedValue);
          };

          return (
            <>
              <Card
                className="mt-5"
                // title={t('createProduct.productInformation')}
                // subtitle={t('createProduct.productInformationDescription')}
              >
                {/* *****  PRODUCT INFORMATION ***** */}
                <Form.GroupVertical label={t('createProduct.name')}>
                  <Form.Input disabled={!isEditable} name="name" type="text" />
                </Form.GroupVertical>
                <Form.GroupVertical label={t('createProduct.description')}>
                  <Form.Input disabled={!isEditable} as="textarea" name="description" />
                </Form.GroupVertical>
                <Form.GroupVertical label={t('createProduct.keywords')}>
                  <Form.DictionaryField disabled={!isEditable} name="keywords" type="text" />
                </Form.GroupVertical>
                {/* <Form.GroupVertical label={t('createProduct.productGroup')}>
                  <Form.MultiSelect
                    disabled={!isEditable}
                    options={dependencies.product_group.map(item => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    name="product_groups"
                  />
                  {isEditable && (
                    <CreateEntityModal
                      title={t('productGroups.createNewProductGroup')}
                      subTitle={t('productGroups.productGroup')}
                      entity="product_group"
                      trigger="link"
                      onActionSuccess={({ id }) =>
                        autoselectNewlyCreatedEntity(id, 'product_groups', true)
                      }
                    />
                  )}
                </Form.GroupVertical> */}
                <Form.GroupVertical label={t('createProduct.details')}>
                  <Form.Input disabled={!isEditable} as="textarea" name="details" />
                </Form.GroupVertical>
                <Form.GroupVertical label={t('createProduct.images')}>
                  <ImageUpload disabled={!isEditable} name="images" />
                </Form.GroupVertical>
                {!!initialValues.color?.length && (
                  <Form.GroupVertical label={t('createProduct.color')}>
                    <Form.MultiSelect
                      disabled={!isEditable}
                      options={dependencies.color.map(item => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      name="color"
                      isMulti
                    />
                    {isEditable && (
                      <CreateEntityModal
                        title={t('colors.createColor')}
                        subTitle={t('colors.color')}
                        entity="color"
                        trigger="link"
                        onActionSuccess={({ id }) =>
                          autoselectNewlyCreatedEntity(id, 'color', false)
                        }
                      />
                    )}
                  </Form.GroupVertical>
                )}
                {!!initialValues.size?.length && (
                  <Form.GroupVertical label={t('createProduct.size')}>
                    <Form.MultiSelect
                      disabled={!isEditable}
                      options={dependencies.size.map(size => ({
                        value: size.id,
                        label: size.name,
                      }))}
                      name="size"
                      isMulti
                    />
                    {isEditable && (
                      <CreateEntityModal
                        title={t('sizes.createSize')}
                        subTitle={t('sizes.size')}
                        entity="size"
                        trigger="link"
                        onActionSuccess={({ id }) =>
                          autoselectNewlyCreatedEntity(id, 'size', false)
                        }
                      />
                    )}
                  </Form.GroupVertical>
                )}
                <Form.GroupVertical label={t('createProduct.category')}>
                  <Form.MultiSelect
                    disabled={!isEditable}
                    options={dependencies.category.map(item => ({
                      value: item.id,
                      label: sanitizeHtml(item.name),
                    }))}
                    name="category"
                    isMulti={false}
                  />
                  {isEditable && (
                    <CreateEntityModal
                      title={t('categories.createCategory')}
                      subTitle={t('categories.categories')}
                      entity="category"
                      trigger="link"
                      onActionSuccess={({ id }) =>
                        autoselectNewlyCreatedEntity(id, 'category', false)
                      }
                    />
                  )}
                </Form.GroupVertical>
              </Card>
              {/* <Card
                className="mt-5"
                title={t('createProduct.productCategory')}
                // subtitle={t('createProduct.productCategoryDescription')}
              >
              </Card> */}
              <Card
                className="mt-5"
                title={t('createProduct.productInformation')}
                subtitle={t('createProduct.productIdentifiersDescription')}
              >
                {/* <Form.GroupVertical label={t('createProduct.brand')}>
                  <Form.MultiSelect
                    disabled={!isEditable}
                    options={dependencies.brand.map(sBrand => ({
                      value: sBrand.id,
                      label: sBrand.preview,
                    }))}
                    isMulti={false}
                    name="brand"
                  />
                </Form.GroupVertical> */}
                {!productWithVariants && (
                  <Form.GroupVertical label={t('createProduct.upc_code')}>
                    <Form.Input disabled={!isEditable} name="sku.upc_code" type="text" />
                  </Form.GroupVertical>
                )}
                <Form.GroupVertical label={t('createProduct.retail_price')}>
                  <Form.Input disabled={!isEditable} name="retail_price" type="text" />
                </Form.GroupVertical>
                {productWithVariants && (
                  <ProductVariant
                    editable={mappedInitialValues.is_editable}
                    variants={mappedInitialValues.sub_skus || []}
                    productId={mappedInitialValues.id}
                    retailPrice={0}
                  />
                )}
              </Card>
              <Card className="mt-5" title={t('createProduct.otherInfo')}>
                {!productWithVariants && (
                  <Form.GroupVertical label={t('createProduct.sku')}>
                    <Form.Input disabled={!isEditable} name="sku.sku" type="text" />
                  </Form.GroupVertical>
                )}

                <Form.GroupVertical label={t('createProduct.baseId')}>
                  <Form.Input disabled={!isEditable} name="base_id" type="number" />
                </Form.GroupVertical>
                {!productWithVariants && (
                  <Form.GroupVertical label={t('createProduct.productCode')}>
                    <Form.Input disabled={!isEditable} name="sku.product_code" type="text" />
                  </Form.GroupVertical>
                )}
              </Card>
              <Wrapper d-flex justify-content-end mb-5>
                {type === 'create' && (
                  <Button
                    onClick={() => {
                      if (values.draftNr) {
                        LocalStorageService.updateDraft(values.draftNr, email, values);
                      } else {
                        LocalStorageService.saveDraft(values, email);
                      }
                    }}
                    variant="light"
                  >
                    {t('createProduct.saveDraft')}
                  </Button>
                )}

                {isEditable && (
                  <Form.Submit className="ml-3">{t('createProduct.submit')}</Form.Submit>
                )}
              </Wrapper>
            </>
          );
        }}
      </Form>
    </>
  );
};

ProductForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
};

export default ProductForm;
