import { Form } from 'react-bootstrap';
import styled from 'styled-components/macro';

export const ErrorFeedback = styled(Form.Control.Feedback)`
  display: block;
  margin-top: 0.25rem;
  color: var(--bs-red);
`;

export const Dropzone = styled.div.attrs({ className: 'form-control' })`
  position: relative;
  p {
    font-size: 13px;
    position: absolute;
    top: calc(50% - 8px);
    left: 34px;
    width: 100%;
  }
`;

export const PreviewThumbnailWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`;

export const PreviewThumbnail = styled.div`
  width: 100px;
  height: 100px;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  button {
    position: absolute;
    top: -10px;
    right: -10px;
    border: 1px solid #cccccc;
  }
`;

export const BrowseButton = styled.button`
  position: absolute;
  right: 16px;

  color: var(--fonts-icons-dark-text-strong, #000);
  /* Paragraph/Body 2/Underline */
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  top: calc(50% - 10px);
`;
