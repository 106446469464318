import React from 'react';

import { call, put, takeLatest } from 'redux-saga/effects';
import { flatten, omit, without } from 'lodash';
import { toastr } from 'utils';
import history from 'utils/history';
import errorMessages from 'helpers/errorMessages';
import i18n from 'i18n';
import { logoutUser } from 'redux/auth/actions';
import { updateLocationGroupSuccess } from 'redux/locations/actions';
import EntityService from '../../api/services/EntityService';
import {
  assignToSuccess,
  deleteVariantSuccess,
  entityActions,
  updateVariantSuccess,
} from './actions';
import entities from '../../constants/entities';
import { ASSIGN_TO_ENTITY, DELETE_VARIANT, IMPORT_SELLERS, UPDATE_VARIANT } from './constants';

function* fetchEnity(action) {
  const {
    type,
    payload: { data },
  } = action;
  const entity = type.slice(type.indexOf('_') + 1).toLowerCase();

  try {
    let fetchData;

    if (data?.uid) {
      fetchData = yield call([EntityService, 'fetch'], entity, data.uid);
    } else {
      fetchData = yield call([EntityService, 'fetch'], entity, undefined, data);
    }
    yield put(entityActions.fetchEntitySuccess(entity, fetchData));
  } catch (error) {
    if (error.response?.status === 403) {
      toastr.error('Session has expired');
      yield put(logoutUser(false));
      return;
    }
    if (entity === entities.learning_center) {
      yield put(entityActions.fetchEntitySuccess(entity, []));
      return;
    }
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:fetchError'));
  }
}

function* createEntity(action) {
  const {
    type,
    payload: {
      data: { values, relations },
      onActionSuccess,
    },
  } = action;

  const entity = type.slice(type.indexOf('_') + 1).toLowerCase();
  const omittedValues = ['product', 'admin', 'sku'].includes(entity) ? [] : relations;
  if (entity === 'retailer' && values.owner) {
    omittedValues.push('location');
  }
  try {
    const createdEntity = yield call(
      [EntityService, 'create'],
      entity,
      omit(values, omittedValues),
    );

    const createdRelations = {};
    if (relations && entity !== 'product' && entity !== entities.sku && entity !== entities.admin) {
      const promisesArray = without(
        relations.map(relation => {
          createdRelations[relation] = values[relation];
          if (!values[relation] || relation === 'location') return undefined;
          return EntityService.update(entity, createdEntity.uid, { [relation]: values[relation] });
        }),
        undefined,
      );
      yield Promise.all(promisesArray);
    }

    yield put(entityActions.createEntitySuccess(entity, { ...createdRelations, ...createdEntity }));

    onActionSuccess && onActionSuccess(createdEntity);

    if (entity === 'product') {
      history.push(`/product/${createdEntity.id}`);
    }
    if (entity === 'retailer') {
      toastr.success(i18n.t('toastr:inviteSent'));
    } else {
      toastr.success(i18n.t('toastr:updateSuccess'));
    }
  } catch (error) {
    if (error.response?.status === 403) {
      toastr.error('Session has expired');
      yield put(logoutUser(false));
      return;
    }
    if (error.response.data.length) {
      toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
    } else {
      const messages = errorMessages(error.response.data);

      toastr.error(i18n.t('toastr:updateError'), {
        component: (
          <>
            {messages.map(item => (
              <p className="text-small m-0">{item}</p>
            ))}
          </>
        ),
      });
    }
  }
}

function* updateEntity(action) {
  const {
    type,
    payload: {
      data: { values, relations, showNotification = true },
      onActionSuccess,
      onFailureAction,
    },
  } = action;

  const entity = type.slice(type.indexOf('_') + 1).toLowerCase();
  const omitValues = ['id', 'uid'];
  if (entity === 'retailer' && values.owner) {
    omitValues.push('location');
  }

  try {
    const updatedEntity = yield call(
      [EntityService, 'update'],
      entity,
      values.uid,
      omit(values, omitValues),
    );

    const createdRelations = {};

    if (relations) {
      const promisesArray = without(
        relations.map(relation => {
          createdRelations[relation] = values[relation];

          if (!values[relation]) return undefined;
          return EntityService.update(entity, updatedEntity.uid, { [relation]: values[relation] });
        }),
        undefined,
      );
      yield Promise.all(promisesArray);
    }

    if (entity !== entities.owner_group_page) {
      yield put(
        entityActions.updateEntitySuccess(entity, { ...updatedEntity, ...createdRelations }),
      );
    } else {
      yield put(updateLocationGroupSuccess(updatedEntity));
    }

    onActionSuccess && onActionSuccess(updatedEntity);
    if (showNotification) {
      toastr.success(i18n.t('toastr:updateSuccess'));
    }
  } catch (error) {
    if (error.response?.status === 403) {
      toastr.error('Session has expired');
      yield put(logoutUser(false));
      return;
    }
    if (error.response.data.length) {
      const message = `
        ${i18n.t('toastr:failedUpdate')} ${entity}:
        ${error.response.data.join('\n')}
      `;

      toastr.error(i18n.t('toastr:error'), message);
    } else {
      // eslint-disable-next-line
      if (onFailureAction) {
        onFailureAction(error.response.data);
      } else {
        const messages = errorMessages(error.response.data);

        toastr.error(
          `${i18n.t('toastr:failedUpdate')} ${entity === 'retailer' ? 'seller' : entity}`,
          {
            component: (
              <>
                {messages.map(item => (
                  <p className="text-small m-0">{item}</p>
                ))}
              </>
            ),
          },
        );
      }
    }
  }
}

function* deleteEntity(action) {
  const { type, payload } = action;
  const uid = payload.data;
  try {
    const entity = type.slice(type.indexOf('_') + 1).toLowerCase();

    yield call([EntityService, 'delete'], entity, uid);

    yield put(entityActions.deleteEntitySuccess(entity, { uid }));

    yield payload.onActionSuccess && payload.onActionSuccess();
  } catch (error) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* updateVariant(action) {
  const { uid, ...rest } = action.payload;

  const sku = yield call([EntityService, 'update'], entities.sku, uid, rest);

  yield put(updateVariantSuccess(sku));
}
function* deleteVariant(action) {
  const { uid, productId } = action.payload;

  yield call([EntityService, 'delete'], entities.sku, uid);

  yield put(deleteVariantSuccess(uid, productId));
}

function* assignToEntity(action) {
  const { entityId, entityType, values } = action.payload;
  const updatedEntity = yield call([EntityService, 'assignTo'], entityId, entityType, values);

  yield put(assignToSuccess(entityType, updatedEntity));
}

function* importSellers(action) {
  const { payload } = action;
  try {
    const result = yield call([EntityService, 'create'], 'batch_retailer', payload);
    const sellers = yield call([EntityService, 'fetch'], 'retailer');
    yield put(entityActions.fetchEntitySuccess('retailer', sellers));
    if (result['sellers invited'])
      toastr.success(
        `${i18n.t('toastr:importSuccess')} ${result['sellers invited']} ${i18n.t(
          'pages:retailers.retailers',
        )}`,
      );
    if (result['sellers skipped'])
      toastr.error(
        `${i18n.t('toastr:importError')} ${result['sellers skipped']} ${i18n.t(
          'pages:retailers.retailers',
        )}`,
      );
  } catch (err) {
    if (err.response.data) {
      Object.values(err.response.data).forEach(message => toastr.error(message));
    }
  }
}

export const entitySagas = [
  ...flatten(
    Object.keys(entities).map(entity => [
      takeLatest([`FETCH_${entity.toUpperCase()}`], fetchEnity),
      takeLatest([`CREATE_${entity.toUpperCase()}`], createEntity),
      takeLatest([`UPDATE_${entity.toUpperCase()}`], updateEntity),
      takeLatest([`DELETE_${entity.toUpperCase()}`], deleteEntity),
    ]),
  ),
  takeLatest(ASSIGN_TO_ENTITY, assignToEntity),
  takeLatest(DELETE_VARIANT, deleteVariant),
  takeLatest(UPDATE_VARIANT, updateVariant),
  takeLatest(IMPORT_SELLERS, importSellers),
];
