import LooksService from 'api/services/LooksService';
import { push } from 'connected-react-router';
import * as hints from 'constants/lookFeatureHints';
import { LOOKS, LOOK_TEMPLATE, FAVOURITE_PRODUCTS } from 'constants/routes';
import i18n from 'i18n';
import { toastr } from 'utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { regionsSelector, userPrimaryLocationIdSelector, userSelector } from 'redux/auth/selectors';
import { uploadMedia } from 'utils/mediaResourceUtils';
import * as actions from './actions';
import * as types from './constants';
import { lookProductsSelector, singleLookSelector } from './selectors';

function* getAvailableProducts() {
  try {
    const regions = yield select(regionsSelector);

    const products = yield call([LooksService, 'fetchAvailableProducts'], regions[0].uid);
    yield put(actions.getAvailableProductsSuccess(products));
  } catch (err) {
    toastr.error(i18n.t('toastr:getProductsError'));
  }
}

function* getUserLooks() {
  const looks = yield call([LooksService, 'fetchUserLooks']);

  yield put(actions.getUserLooksSuccess(looks));
}

function* getTemplateLooks() {
  const looks = yield call([LooksService, 'fetchTemplateLooks']);
  yield put(actions.getTemplateLooksSuccess(looks));
}

function* getSingleLook(action) {
  const { payload: lookId } = action;

  const look = yield call([LooksService, 'fetchSingleLook'], lookId);
  yield put(actions.getSingleLookSuccess(look));
}

function* createNewLook(action) {
  const userPrimaryLocationId = yield select(userPrimaryLocationIdSelector);
  const { regions } = yield select(userSelector);

  const { databaseId } = yield call([LooksService, 'createLook'], {
    name: 'Untitled Look',
    user_location_id: userPrimaryLocationId,
    region_id: regions[0].id,
  });
  const redirectUrl = action.payload;

  let newHistoryStore;
  if (redirectUrl) {
    newHistoryStore = { redirectUrl: action.payload };
  }

  yield put(push(`look/${databaseId}/edit`, newHistoryStore));
}

function* createTemplateLook() {
  const { regions } = yield select(userSelector);

  const { databaseId } = yield call([LooksService, 'createLook'], {
    name: 'Untitled Look',
    region_id: regions[0].id,
    create_template: true,
  });
  yield put(push(`template-look/${databaseId}/edit`));
}

function* searchProducts(action) {
  const regions = yield select(regionsSelector);

  const { payload } = action;
  const products = yield call([LooksService, 'searchProducts'], {
    regionUid: regions[0].uid,
    productName: payload,
  });
  yield put(actions.searchProductsSuccess(products));
}

function* deleteLook(action) {
  const {
    payload: { lookId, isTemplateLook, isFavouriteProduct },
  } = action;

  yield call([LooksService, 'deleteLook'], lookId);

  if (isTemplateLook) {
    yield put(actions.deleteTemplateLookSuccess(lookId));
    yield put(push(LOOK_TEMPLATE));
  } else if (isFavouriteProduct) {
    yield put(actions.deleteFavouriteProductSuccess(lookId));
    yield put(push(FAVOURITE_PRODUCTS));
  } else {
    yield put(actions.deleteLookSuccess(lookId));
    yield put(push(LOOKS));
  }
}

function* updateCoverPhoto(action) {
  const {
    payload: { image, aspectRatio },
    isRetry,
  } = action;

  const { data: look } = yield select(singleLookSelector);

  try {
    const { public_id } = yield call(uploadMedia, [image]);

    const newPhoto = yield call([LooksService, 'addCoverPhotos'], look._id, public_id);

    const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
      aspect_ratio: aspectRatio,
      feature_hints: {
        [hints.COVER_PHOTO_HINT]: true,
      },
    });

    yield put(actions.updateLookSuccess(updatedlook));
    yield put(actions.addCoverPhoto(newPhoto));

    toastr.success(i18n.t('toastr:updateSuccess'));
  } catch (e) {
    if (!isRetry) {
      yield put(actions.updateLookCoverPhoto({ image, aspectRatio }, true));
      return;
    }
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* getCoverPhotos() {
  const { data: look } = yield select(singleLookSelector);

  const coverPhotos = yield call([LooksService, 'fetchCoverPhotos'], look.short_id);

  yield put(actions.getCoverPhotosSuccess(coverPhotos));
}

function* removeCoverPhoto(action) {
  const { payload: photoId } = action;

  try {
    yield call([LooksService, 'removeCoverPhoto'], photoId);
    yield put(actions.removeCoverPhotoSuccess(photoId));
  } catch {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* updateLook(action) {
  const {
    payload: { values },
  } = action;
  const { data: look } = yield select(singleLookSelector);

  try {
    const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, values);

    yield put(actions.updateLookSuccess(updatedlook));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* addProductsToLook(action) {
  try {
    const { data: look } = yield select(singleLookSelector);

    const newProducts = yield call([LooksService, 'addProductsToLook'], action.payload);

    if (!look.feature_hints[hints.PRODUCTS_HINT]) {
      const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
        feature_hints: {
          [hints.PRODUCTS_HINT]: true,
        },
      });
      yield put(actions.updateLookSuccess(updatedlook));
    } else {
      yield put(actions.addProductsToLookSuccess(newProducts));
    }

    toastr.success(i18n.t('toastr:updateSuccess'));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* createLookFromDraft(action) {
  const { data: look } = yield select(singleLookSelector);
  const customRedirectUrl = action.payload;

  try {
    const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
      feature_hints: {
        [hints.SAVED_HINT]: true,
      },
    });

    yield put(actions.updateLookSuccess(updatedlook));

    if (customRedirectUrl) {
      yield put(
        push(customRedirectUrl, {
          successMessage: 'Look added successfully!',
        }),
      );
    } else {
      toastr.success('Look added. New looks are on the top of the list');
      if (updatedlook.is_template) {
        yield put(push(LOOK_TEMPLATE));
      } else {
        yield put(push(LOOKS));
      }
    }
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* updateIsPrivate(action) {
  const { payload: isPrivate } = action;
  const { data: look } = yield select(singleLookSelector);

  try {
    yield call([LooksService, 'updateIsPrivate'], look._id, isPrivate);

    yield put(actions.updateIsPrivateSuccess(isPrivate));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* removeProductFromLook(action) {
  const { data: look } = yield select(singleLookSelector);
  const lookProducts = yield select(lookProductsSelector);
  const {
    payload: { productId },
  } = action;

  try {
    yield call([LooksService, 'deleteProduct'], look._id, productId);

    // deleting last product from draft store
    if (lookProducts.length === 1 && look.is_draft) {
      const { store: updatedlook } = yield call([LooksService, 'patchLook'], look._id, {
        feature_hints: {
          [hints.PRODUCTS_HINT]: false,
        },
      });
      yield put(actions.updateLookSuccess(updatedlook));
    }

    yield put(actions.removeProductFromLookSuccess(productId));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

export const looksSagas = [
  takeLatest(types.GET_AVAILABLE_PRODUCTS, getAvailableProducts),
  takeLatest(types.GET_USER_LOOKS, getUserLooks),
  takeLatest(types.GET_TEMPLATE_LOOKS, getTemplateLooks),

  takeLatest(types.GET_LOOK, getSingleLook),
  takeLatest(types.LOOK_CREATE_NEW, createNewLook),
  takeLatest(types.TEMPLATE_LOOK_CREATE_NEW, createTemplateLook),

  takeLatest(types.SEARCH_PRODUCTS, searchProducts),
  takeLatest(types.DELETE_LOOK, deleteLook),
  takeLatest(types.UPDATE_LOOK_COVER_PHOTO, updateCoverPhoto),
  takeLatest(types.REMOVE_COVER_PHOTO, removeCoverPhoto),
  takeLatest(types.UPDATE_LOOK, updateLook),
  takeLatest(types.ADD_PRODUCTS_TO_LOOK, addProductsToLook),
  takeLatest(types.CREATE_LOOK_FROM_DRAFT, createLookFromDraft),
  takeLatest(types.UPDATE_LOOK_IS_PRIVATE, updateIsPrivate),
  takeLatest(types.REMOVE_PRODUCT_FROM_LOOK, removeProductFromLook),
  takeLatest(types.GET_COVER_PHOTOS, getCoverPhotos),
];
