import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Pagination = ({ activePage, numberOfPages, goToPage }) => {
  const { t } = useTranslation('app');
  return (
    <ul className="pagination flex-wrap justify-content-center">
      <li className={`page-item  ${activePage === 0 && 'disabled'}`}>
        <button type="button" className="page-link" onClick={() => goToPage(activePage - 1)}>
          {t('previous')}
        </button>
      </li>
      {[...Array(numberOfPages).keys()]
        .filter(
          pageIndex =>
            (activePage < pageIndex ? pageIndex - activePage < 5 : activePage - pageIndex <= 5) ||
            (pageIndex < 10 && activePage <= 5),
        )
        .map(pageIndex => (
          <li key={pageIndex} className={`page-item  ${pageIndex === activePage && 'active'}`}>
            <button type="button" className="page-link" onClick={() => goToPage(pageIndex)}>
              {pageIndex + 1}
            </button>
          </li>
        ))}
      <li className={`page-item  ${activePage + 1 === numberOfPages && 'disabled'}`}>
        <button type="button" className="page-link" onClick={() => goToPage(activePage + 1)}>
          {t('next')}
        </button>
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number,
  numberOfPages: PropTypes.number,
  goToPage: PropTypes.func.isRequired,
};

export default Pagination;
