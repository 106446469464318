export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const EDIT_PRODUCTS = '/product/:id';
export const CREATE_PRODUCT = '/products/create';
export const PRODUCTS_GROUPS = '/products-groups';
export const RETAILERS = '/sellers';
export const REGIONS = '/regions';
export const TIERS = '/tiers';
export const BRAND = '/brands';
export const BRAND_CONTENT = '/brand_content';
export const CREATE_BRAND_CONTENT = '/brand_content/create';
export const EDIT_BRAND_CONTENT = '/brand_content/:id';
export const FEATURED_ARTIST = '/featured-artist';
export const ADMIN_TOOL_LOGIN = '/login';
export const AVEDA_LOGIN = '/aveda';
export const REGISTER = '/register';
export const AVEDA_REGISTER = '/aveda-registration';
export const EDIT_THEME = '/cover-photo';
export const ADMINS = '/admins';
export const AUTH = '/authentication';
export const ADD_NUMBER = '/add-number';
export const MY_LOCATIONS = '/location';
export const MY_LOCATIONS_PREVIEW = '/location/:id';
export const MY_LOCATIONS_EDIT = '/location/:id/page-edit';
export const MANAGE_STYLISTS = '/stylists';
export const APPROVE_STYLISTS = '/stylists/approve';
export const CREATE_PAGE_TEMPLATE = '/page-builder/create';
export const WELCOME_SCREENS = '/welcome_screen';
export const MANAGE_WELCOME_SCREEN = '/welcome_screen/:id';
export const LANDING_PAGE = '/landing-page';
export const LEARNING_CENTERS = '/learning_center';
export const LEARNING_CENTER = '/learning_center/:id';
export const TERMS = '/terms';
export const SELLER = '/retailer/:id';
export const FORGET_PASSWORD = '/forgot-password';
export const PROFILE = '/profile';
export const QRCODE = '/qrcode';
export const LOOKS = '/looks';
export const LOOK_EDIT = '/look/:id/edit';
export const LOOK_ADD_PRODUCTS = '/look/:id/add-products';
export const OWNER_GROUP_PAGE = '/owner-group-page';
export const LOOK_TEMPLATE = '/template-looks';
export const EDIT_LOOK_TEMPLATE = '/template-look/:id/edit';
export const PRODUCT_CATALOG = '/product-catalog';
export const WIDGET_TRENDING_LOOKS = '/widgets/trending-looks';
export const WIDGET_TEST = '/widgets/test';
export const WIDGET_NEW_LOOKS = '/widgets/new-looks';
export const WIDGET_FEATURED_ARTISTS = '/widgets/featured-artists';
export const WIDGETS = '/widgets';
export const FAVOURITE_PRODUCTS = '/favorite-products';
export const SHAREABLE_LINKS = '/shareable-links';
export const GENERATE_REPORTS = '/reports';
