// globalStyles.js
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  ${({ theme }) =>
    theme.isOwnerPortal &&
    `
  
  		.form-control {
			border: 1px solid var(--borders-border-input-default, #525252);
			border-radius: 0;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			letter-spacing: 0.07px;
		}

		div[class*="-control"] {
			border: 1px solid var(--borders-border-input-default, #525252);
			border-radius: 0 !important;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			letter-spacing: 0.07px;
		}

		.form-label {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px; /* 150% */
		}

		.btn {
			border-radius: 0;
			text-transform: uppercase;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
			letter-spacing: 1.92px;

		}

		.btn-dark {
			padding: 12px 24px;
			background: var(--buttons-button-default, #212121);
			border: 1px solid var(--buttons-button-default, #212121);w
		}

		.btn-primary {
			padding: 12px 24px;
			background: #0040C1;
		}

		.btn-light {
			padding: 12px 24px;
			border: 1px solid var(--neutrals-500, #737373);
			background: var(--background-weak, #FFF);
		}

		.btn-danger {
			padding: 12px 24px;
		}
 
 	`}

`;
