import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Form } from 'components';
import { formatDate, getRawDate, getRawTime } from 'utils/date';
import Select from 'react-select';

import { NewModal } from 'components/NewModal/NewModal';
import { SCHEDULE_LOOK_MODAL } from 'redux/modal/constants';
import * as Ui from './DatePickerModal.styles';

const mapTimeZones = tz => ({ value: tz.tzCode, label: tz.label, utcOffset: tz.utcOffset });

const DatePickerModal = ({
  onSave,
  startDate: startInit,
  endDate: endInit,
  timeZones,
  timeZone,
  title = 'Schedule my look',
}) => {
  const [startDate, setStartDate] = useState(startInit);
  const [endDate, setEndDate] = useState(endInit);
  const [init, setInit] = useState(false);

  const [selectedTimezone, setSelectedTimezone] = useState(
    timeZone ? timeZones.filter(tz => tz.tzCode === timeZone).map(mapTimeZones)[0] : {},
  );
  const timeZonesOptions = useMemo(() => timeZones.map(mapTimeZones), [timeZones]);

  const getUtcTimezoneDiff = (a, b) => {
    const diff = Math.abs(a - b);
    return diff < 12 ? diff : 24 - diff;
  };

  const getUserClosestTimezone = () => {
    if (!timeZones.length) return {};

    const now = new Date();
    const userOffset = parseFloat(-now.getTimezoneOffset() / 60);
    const closestTimezone = timeZonesOptions.reduce((prev, curr) =>
      getUtcTimezoneDiff(curr.utcOffset, userOffset) <
      getUtcTimezoneDiff(prev.utcOffset, userOffset)
        ? curr
        : prev,
    );

    return closestTimezone;
  };

  useEffect(() => {
    setSelectedTimezone(curr =>
      curr && Object.keys(curr).length ? curr : getUserClosestTimezone(),
    );
  }, [timeZones]);

  const ref = useRef();

  const onDateChange = dates => {
    const [start, end] = dates;

    setStartDate(formatDate(start));

    if (end) {
      setEndDate(formatDate(end));
    } else {
      setEndDate(null);
    }
  };

  useEffect(() => {
    const handleTimeChange = () => {
      if (ref.current.flatpickr.selectedDates[0]) {
        setEndDate(formatDate(ref.current.flatpickr.selectedDates[0]));
      } else if (ref.current.flatpickr.selectedDates[1]) {
        setStartDate(formatDate(ref.current.flatpickr.selectedDates[1]));
      }
    };

    if (init) {
      const arrowUps = document.querySelectorAll('.flatpickr-time .arrowUp');
      const arrowDowns = document.querySelectorAll('.flatpickr-time .arrowDown');

      [...arrowUps, ...arrowDowns].forEach(arrow => {
        arrow.addEventListener('click', handleTimeChange);
      });
    }

    return () => {
      const arrowUps = document.querySelectorAll('.flatpickr-time .arrowUp');
      const arrowDowns = document.querySelectorAll('.flatpickr-time .arrowDown');

      [...arrowUps, ...arrowDowns].forEach(arrow => {
        arrow.removeEventListener('click', handleTimeChange);
      });
    };
  }, [init]);

  return (
    <NewModal
      modalId={SCHEDULE_LOOK_MODAL}
      heading={title}
      buttonsProps={[
        {
          label: 'Save',
          onClick: () => onSave(startDate, endDate, selectedTimezone.value),
        },
      ]}
    >
      <Ui.ModalContentWrapper>
        {startDate && (
          <div className="mb-2">
            <DateRangePicker
              start={{
                date: getRawDate(startDate),
                time: getRawTime(startDate),
              }}
              end={
                endDate
                  ? {
                      date: getRawDate(endDate),
                      time: getRawTime(endDate),
                    }
                  : null
              }
            />
          </div>
        )}

        <div>
          <Form.GroupHorizontal className="mb-4" label="Timezone">
            <Select
              options={timeZonesOptions}
              isMulti={false}
              onChange={setSelectedTimezone}
              value={selectedTimezone}
            />
          </Form.GroupHorizontal>

          <Flatpickr
            id="flatPicker"
            ref={ref}
            placeholder="YYYY-MM-DD"
            className="form-control"
            defaultDate={[startDate, endDate]}
            options={{
              // dateFormat: 'Y-m-d',
              inline: true,
              mode: 'range',
              enableTime: true,
              minDate: 'today',
            }}
            onValueUpdate={onDateChange}
            onReady={() => setInit(true)}
          />
        </div>
      </Ui.ModalContentWrapper>
    </NewModal>
  );
};

DatePickerModal.propTypes = {
  onSave: PropTypes.func,

  startDate: PropTypes.string,
  endDate: PropTypes.string,
  timeZones: PropTypes.arrayOf(
    PropTypes.shape({
      tzCode: PropTypes.string,
      label: PropTypes.string,
      utcOffset: PropTypes.number,
    }),
  ),
  timeZone: PropTypes.string,
  title: PropTypes.string,
};

export default DatePickerModal;
