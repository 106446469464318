import React from 'react';
import { ADMIN_TOOL_LOGIN } from 'constants/routes';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addMissingAuthy } from 'redux/auth/actions';
import { Card, Form, Wrapper } from 'components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { tokenSelector } from 'redux/auth/selectors';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';

const RefillAccount = () => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'authyPhone' });
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [countryCode, setCountryCode] = React.useState('');
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  if (!token) {
    return <Redirect to={ADMIN_TOOL_LOGIN} />;
  }

  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '72px' }}>
            <div style={{ padding: '16px 36px' }}>
              <h1 className="display-4 text-center text-uppercase mb-3">{prefixT('title')}</h1>
              <p className="text-muted text-center mb-5">{prefixT('subtitle')}</p>
              <Form
                onSubmit={async () => {
                  dispatch(addMissingAuthy(countryCode, phoneNumber, token));
                }}
              >
                <Form.GroupHorizontal
                  label={prefixT('adminRegistration.phoneNumber', { keyPrefix: '' })}
                >
                  <PhoneInput
                    country="us"
                    inputStyle={{ width: '100%', paddingBlock: '8px' }}
                    onChange={(value, country) => {
                      setCountryCode(country.dialCode);
                      setPhoneNumber(value.slice(countryCode.length));
                    }}
                  />
                </Form.GroupHorizontal>
                <Wrapper mb-3>
                  <Form.Submit size="lg" variant="dark" block>
                    {prefixT('submit')}
                  </Form.Submit>
                </Wrapper>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RefillAccount;
