export default {
  apiBaseUrl: 'https://mac-api.hausmart.com',
  getAppUrl: 'https://social-shoppe.app.link/',
  avedaWebApp: 'https://shop.aveda.com',
  appStoreLink: 'https://apps.apple.com/us/app/elc-social-shoppe/id1586592506',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.hausmart.socialshoppe',
  ssoUrl: 'https://avedapurepro.com/ACommerce/Enrolled',
  avedaCheckoutPrepopulatedUrl:
    'https://www.aveda.com/shared/add_to_bag.tmpl?SKU_BASE_ID=122899:1,110558:1&socialshoppe=1&applied_offer_confirm=1&promocode=',
  macCheckoutPrepopulatedUrl:
    'https://maccosmetics.de/checkout/viewcart.tmpl?socialshoppe=1&applied_offer_confirm=1&promocode=',
  jiraIssueCollectorUrl: {
    aveda:
      'https://hmelc.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=3e5a06eb',
    mac:
      'https://hmelc.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=c83e968d',
  },
  landingPageDefaultVideo: 'https://www.youtube.com/embed/yIrDk05ULew',
  featureFlags: {
    quicksight: true,
    sso: true,
    ownerGroup: true,
    ownerGroupPage: false,
    offerCodes: false,
    templateLooks: false,
    landingPage: false,
    embeddableWidgets: false,
    jiraIssueCollector: false,
    shareableLinks: false,
    artistsTab: false,
    reinviteUser: false,
    tour: false,
    adminCanEditLooks: false,
    lookExpirationDate: false,
    brandLookEditable: false,
    salonBio: false,
    phoneSecondary: false,
  },
};
