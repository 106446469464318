import React from 'react';
import PropTypes from 'prop-types';
import avatarProfile from '../../../../../../assets/img/aveda-logo-black.png';
import * as Ui from './Stylist.styles';

export const Stylist = ({ stylist, noSlide = false, widget = false }) => (
  <Ui.Slide widget={widget} noSlide={noSlide} role="button">
    <Ui.CoverPhotoWrapper>
      <Ui.Image src={stylist.connected_user?.picture || stylist?.picture || avatarProfile} />
    </Ui.CoverPhotoWrapper>
    <Ui.Name>{stylist.connected_user?.name || stylist.name}</Ui.Name>
    <Ui.Name>{stylist.connected_user?.about || stylist.about}</Ui.Name>
  </Ui.Slide>
);

Stylist.propTypes = {
  stylist: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string,
    about: PropTypes.string,
    connected_user: PropTypes.arrayOf(
      PropTypes.shape({
        connected_user: PropTypes.shape({
          picture: PropTypes.string,
          name: PropTypes.string,
          about: PropTypes.string,
        }),
      }),
    ),
  }),
  noSlide: PropTypes.bool,
  widget: PropTypes.bool,
};
