import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';

import useEntityForm from 'hooks/useEntityForm';
import { useSelector } from 'react-redux';
import { regionsSelector, userSelector } from 'redux/auth/selectors';
import { userRole } from 'utils/userRole';
import * as Roles from 'constants/roles';
import { pickOrDefault } from 'utils/pickOrDefault';

const ProductGroupForm = ({ type = 'create', initialValues, FormGroup, onActionSuccess }) => {
  const { t } = useTranslation(['pages', 'app']);
  const { onSubmit, validationSchema } = useEntityForm(type, 'product_group');
  const regions = useSelector(regionsSelector);
  const user = useSelector(userSelector);
  const adminRole = userRole(user);

  return (
    <Form
      initialValues={pickOrDefault(initialValues, ['name', 'uid', 'region'], {
        name: '',
        region: regions[0].id,
      })}
      onSubmit={values => {
        onSubmit(values, onActionSuccess);
      }}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={t('productGroups.productGroupName')}>
          <Form.Input placeholder={t('productGroups.enterName')} type="text" name="name" />
        </FormGroup>

        <FormGroup label={t('retailers.region')}>
          <Form.MultiSelect
            disabled={adminRole === Roles.ADMIN || type !== 'create'}
            options={user.regions.map(r => ({ value: r.id, label: r.name }))}
            name="region"
            isMulti={false}
          />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('app:cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(`app:${type}`)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

ProductGroupForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  onActionSuccess: PropTypes.func,
};

export default ProductGroupForm;
