export const relativeTimeRanges = [
  {
    durationInDays: 30,
    label: 'Last Month',
  },
  {
    durationInDays: 90,
    label: 'Last Three Months',
  },
  {
    durationInDays: 180,
    label: 'Last Half of Year',
  },
  {
    durationInDays: 365,
    label: 'Last Year',
  },
];
