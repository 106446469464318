import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { authenticate } from 'redux/auth/actions';
import { Redirect } from 'react-router';
import { Card, Form, Wrapper } from 'components';
import * as Yup from 'yup';
import { tokenSelector } from 'redux/auth/selectors';
import { ADMIN_TOOL_LOGIN, DASHBOARD } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';

const AuthyAuthentication = () => {
  const dispatch = useDispatch();
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'tfa' });
  const token = useSelector(tokenSelector);
  const query = useQuery();
  const redirectAddress = query.get('redirectUrl') || DASHBOARD;

  if (!token) {
    return <Redirect to={ADMIN_TOOL_LOGIN} />;
  }

  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '72px' }}>
            <div style={{ padding: '16px 36px' }}>
              <h1 className="display-4 text-center text-uppercase mb-3">{prefixT('title')}</h1>
              <p className="text-muted text-center mb-5">{prefixT('subtitle')}</p>
              <Form
                initialValues={{
                  code: '',
                }}
                onSubmit={async ({ code }) => {
                  dispatch(authenticate(code, token, redirectAddress));
                }}
                validationSchema={Yup.object({
                  code: Yup.string()
                    .length(7)
                    .required('Required'),
                })}
              >
                <Form.Group>
                  <Form.Label>{prefixT('code')}</Form.Label>
                  <Form.Input name="code" type="text" />
                </Form.Group>
                <Wrapper mb-3>
                  <Form.Submit size="lg" variant="dark" block>
                    {prefixT('submit')}
                  </Form.Submit>
                </Wrapper>
                <Wrapper text-center text-weight-bold>
                  You need{' '}
                  <a href="https://authy.com/download/" target="_blank">
                    Authy
                  </a>{' '}
                  in order to get verification code
                </Wrapper>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthyAuthentication;
