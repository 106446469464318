/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ownerSelector,
  managerSelector,
  regionsSelector,
  brandSelector,
} from 'redux/auth/selectors';
import { useSelector } from 'react-redux';
import { Wrapper } from './SheetHeader.styles';
import { isArtistsTab } from '../../../../utils/featureFlags.ts';

export const SheetHeader = ({ activeSheet }) => {
  const isOwner = useSelector(ownerSelector);
  const isManager = useSelector(managerSelector);
  const isAvedaRelated = isOwner || isManager;
  const { t: prefixT } = useTranslation(isAvedaRelated ? 'ownerPortal' : 'pages', {
    keyPrefix: 'dashboard',
  });
  const translationKey = activeSheet[0].toLowerCase() + activeSheet.slice(1).replace(/\s/g, '');
  const brand = useSelector(brandSelector);

  const [region] = useSelector(regionsSelector); // only one region for admin accessing this dashboard
  const {
    configuration: { short_url },
  } = region;

  const getText = () =>
    prefixT(`${translationKey}Description`, {
      link: `${short_url}/how-to-hub`,
      artistsShopName: brand.preview === 'MAC' ? 'store' : 'Web Shoppe',
      locations: brand.preview === 'MAC' ? '' : 'locations',
      artists: isArtistsTab() ? ', Artists' : '',
    });

  return (
    <Wrapper>
      <h2>{prefixT(`${translationKey}Title`)}</h2>
      <p dangerouslySetInnerHTML={{ __html: getText() }} />
    </Wrapper>
  );
};

SheetHeader.propTypes = {
  activeSheet: PropTypes.string.isRequired,
};
