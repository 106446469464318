import React from 'react';
import PropTypes from 'prop-types';
import withDefaultProps from 'helpers/withDefaultProps';
import whiteLogo from 'assets/svg/mac-now-logo-white.svg';
import blackLogo from 'assets/svg/mac-now-logo-black.svg';

import { LogoWrapper } from './styles';

const Logo = ({ color = 'white', children, ...restProps }) => (
  <LogoWrapper {...restProps}>
    <img className="w-100" alt="MAC NOW logo" src={color === 'white' ? whiteLogo : blackLogo} />
  </LogoWrapper>
);

Logo.propTypes = { color: PropTypes.oneOf(['white', 'black']), ...withDefaultProps() };

export default Logo;
