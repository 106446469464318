import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { userLoggingSelector, userSelector } from 'redux/auth/selectors';
import { MY_LOCATIONS } from 'constants/routes';
import { Helmet } from 'react-helmet';
import useQuery from 'hooks/useQuery';
import { loginSSO } from 'redux/auth/actions';
import { isSsoEnabled } from '../../utils/featureFlags.ts';

export function LoginRoute({ component: Component, ...rest }) {
  const query = useQuery();
  const redirect = query.get('redirectUrl');
  const to = redirect || MY_LOCATIONS;
  const user = useSelector(userSelector);
  const isUserLogging = useSelector(userLoggingSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isSsoEnabled()) {
      const token = query.get('token');
      if (token) {
        dispatch(loginSSO(token, to));
      }
    }
  }, [query]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isUserLogging) {
          return null;
        }
        if (user.email) {
          return (
            <Redirect
              to={{
                pathname: to,
                // eslint-disable-next-line
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <>
            <Helmet>
              <title>NOW - Admin Panel</title>
            </Helmet>
            <Component {...props} />
          </>
        );
      }}
    />
  );
}

LoginRoute.propTypes = {
  component: PropTypes.func,
  rest: PropTypes.any,
};
