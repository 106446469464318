import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Form, Wrapper } from 'components';
import * as Yup from 'yup';
import { loginUser } from 'redux/auth/actions';
import { Link } from 'react-router-dom';
import { DASHBOARD, REGISTER, FORGET_PASSWORD } from 'constants/routes';
import useQuery from 'hooks/useQuery';

const AdminToolLogin = () => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'login' });
  const { t } = useTranslation('validation');
  const dispatch = useDispatch();
  const query = useQuery();
  const redirectAddress = query.get('redirectUrl') || DASHBOARD;
  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '72px' }}>
            <div style={{ padding: '16px 36px' }}>
              <h1 className="display-4 text-center text-uppercase mb-3">{prefixT('title')}</h1>
              <p className="text-muted text-center mb-5">{prefixT('subtitle')}</p>
              <Form
                initialValues={{
                  email: '',
                  password: '',
                  countryCode: '',
                  phoneNumber: '',
                }}
                onSubmit={async ({ email, password }) => {
                  dispatch(loginUser(email, password, redirectAddress));
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email('Invalid email address')
                    .required(t('required')),
                  password: Yup.string()
                    .min(6, t('atLeastChars', { number: 6 }))
                    .required(t('required')),
                })}
              >
                <Form.Group>
                  <Form.Label>{prefixT('email')}</Form.Label>
                  <Form.Input name="email" type="email" placeholder="jane@formik.com" />
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>{prefixT('password')}</Form.Label>
                    </Col>
                  </Row>
                  <Form.Password name="password" placeholder={prefixT('passwordPlaceholder')} />
                </Form.Group>
                <Wrapper mb-3>
                  <Form.Submit size="lg" variant="dark" block>
                    {prefixT('login')}
                  </Form.Submit>
                </Wrapper>
                <Wrapper d-flex flex-column justify-content-center align-items-center>
                  <Wrapper d-flex justify-content-center align-items-center mt-5 mb-3>
                    <Link to={REGISTER} className="h3 text-muted">
                      {`${prefixT('dontHaveAcc')} ${prefixT('signUp')}`}
                    </Link>
                  </Wrapper>
                  <Link to={`${FORGET_PASSWORD}?admin=true`} className="h3 text-muted">
                    {prefixT('forgetPassword')}
                  </Link>
                </Wrapper>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminToolLogin;
