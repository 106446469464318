import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'components';
import { toastr } from 'utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import EntityService from 'api/services/EntityService';
import { locationSelector } from 'redux/locations/selectors';
import { ReactComponent as SaveIcon } from '../../assets/svg/download-regular.svg';

const SocialShare = ({ url, qr }) => (
  <>
    {qr && (
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex justify-content-end align-items-end">
          <img width={200} src={`data:image/jpeg;base64,${qr}`} alt="qr code" />
          <a href={`data:image/jpeg;base64,${qr}`} className="pb-5" download="qrCode.png">
            <SaveIcon style={{ width: '1.5em' }} />
          </a>
        </div>
      </div>
    )}
    <div style={{ display: 'grid', gap: 5, gridAutoFlow: 'column', alignContent: 'center' }}>
      <FacebookShareButton url={url}>
        <FacebookIcon size={40} />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon size={40} />
      </TwitterShareButton>
      <EmailShareButton url={url}>
        <EmailIcon size={40} />
      </EmailShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={40} />
      </WhatsappShareButton>

      <Button
        className="py-2 mx-3 px-3"
        variant="light"
        onClick={() => {
          navigator.clipboard.writeText(url);
          toastr.success('Link copied!');
        }}
      >
        Copy my link
      </Button>
    </div>
  </>
);

const FirstStepsModal = ({ open }) => {
  const [shareInfo, setShareInfo] = useState(null);
  const location = useSelector(locationSelector);
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'locations' });

  useEffect(() => {
    const fetch = async () => {
      const response = await EntityService.generateLocationLinks(location.vanity_url);
      setShareInfo(response?.url);
    };

    if (location?.vanity_url) {
      fetch();
    }
  }, [location?.vanity_url]);

  if (shareInfo === null) {
    return null;
  }

  return (
    <Modal
      open={open}
      className="w-100"
      header={{
        title: prefixT('infoModalTitle'),
      }}
      closeOnDocumentClick
      closeOnEscape
      closeButton
      trigger={<> </>}
    >
      <div>
        <Modal.ClosableBody>
          {({ closePopup }) => (
            <div className="d-flex flex-column">
              <p>{prefixT('infoModalContent')}</p>
              <p>
                {prefixT('infoModalContentVanityUrl')} {shareInfo}
              </p>
              <Modal.Footer>
                {() => (
                  <Button style={{ float: 'right' }} variant="dark" onClick={closePopup}>
                    Close
                  </Button>
                )}
              </Modal.Footer>
            </div>
          )}
        </Modal.ClosableBody>
      </div>
    </Modal>
  );
};

// const CopyLinkModal = ({ location }) => {
//   const { t } = useTranslation(['app', 'ownerPortal']);
//   const [shareInfo, setShareInfo] = React.useState(null);

//   useEffect(() => {
//     const fetch = async () => {
//       const response = await EntityService.generateLocationLinks(location.vanity_url);
//       setShareInfo(response);
//     };
//     if (location) {
//       fetch();
//     }
//   }, [location.vanity_url]);

//   if (!shareInfo) return null;

//   return (
//     <Modal
//       header={{
//         title: t('shareModalTitle'),
//       }}
//       trigger={() => (
//         <Button className="py-2 px-3" $type="avedaSecondary">
//           {t('app:share')}
//         </Button>
//       )}
//     >
//       <SocialShare url={shareInfo.url} qr={shareInfo.qr} />
//     </Modal>
//   );
// };

// CopyLinkModal.propTypes = {
//   location: PropTypes.object,
// };

SocialShare.propTypes = {
  url: PropTypes.string.isRequired,
  qr: PropTypes.string.isRequired,
};

FirstStepsModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export { SocialShare, FirstStepsModal };
