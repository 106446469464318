import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Modal, Button } from 'components';
import EntityForm from 'containers/EntityForm/entityForm';
import entities from 'constants/entities';
import { PermissionContext } from 'components/common/ProtectedRoute';

const CreateEntityModal = ({
  title,
  subTitle,
  entity,
  trigger = 'button',
  triggerCopy,
  wide = false,
  ...rest
}) => {
  const { t } = useTranslation(['pages', 'app']);
  const { permission } = React.useContext(PermissionContext);

  const generateTrigger = () => {
    if (typeof trigger === 'object') {
      return trigger;
    }
    if (trigger === 'button') {
      return (
        <Button variant="dark" style={{ minWidth: 130 }}>
          {triggerCopy || t('app:createNew')}
        </Button>
      );
    }
    return (
      <button type="button" className="btn btn-link p-0 btn-sm">
        {t('createProduct.addNew')}
      </button>
    );
  };

  if (!permission.create) {
    return null;
  }

  return (
    <Modal
      className="modal-lg"
      wide={wide}
      trigger={generateTrigger()}
      header={{
        title,
        subTitle,
      }}
    >
      <EntityForm entity={entity} type="create" {...rest} />
    </Modal>
  );
};

CreateEntityModal.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  entity: PropTypes.oneOf(Object.keys(entities)),
  trigger: PropTypes.oneOf(['button', 'link']),
  wide: PropTypes.bool,
  triggerCopy: PropTypes.string,
};

export default CreateEntityModal;
