import axios from 'axios';
import { toastr } from 'utils';
import * as _ from 'lodash';
import AxiosProvider from './AxiosProvider';
import env from '../config';
import { getTokenPayload, payloadDuration } from '../utils/auth';
import { getToken, setToken } from '../utils/tokenService';

class CoreApi {
  constructor(baseUrl = env.apiBaseUrl) {
    this.api = AxiosProvider(baseUrl);
    this.baseUrl = baseUrl;

    this.afterResponse = this.afterResponse.bind(this);
    this.beforeRequest = this.beforeRequest.bind(this);
    this.setInterceptors(
      this.beforeRequest,
      this.requestError,
      this.afterResponse,
      // this.responseError,
    );
  }

  setInterceptors(beforeRequest, requestError, afterResponse, responseError) {
    this.api.interceptors.request.use(beforeRequest, requestError);
    this.api.interceptors.response.use(afterResponse, responseError);
  }

  setEndpointUrl(url) {
    this.api.defaults.baseURL = `${this.baseUrl}/${url}`;
  }

  beforeRequest(config) {
    if (config.url.indexOf('https://api.cloudinary.com') === -1) {
      config.headers['X-App-Source'] = 'MAC';
    }
    if ('requireAuth' in config && config.requireAuth === false) {
      return config;
    }

    const token = getToken();
    if (token) {
      const originalRequest = config;
      const payload = getTokenPayload(token);

      const shouldRefreshToken =
        payloadDuration(payload).isValid() && payloadDuration(payload).asDays() < 3;

      if (shouldRefreshToken) {
        return axios
          .post(
            `${this.baseUrl}/v2/authentication/refresh`,
            {
              token: token.substring(7),
            },
            {
              headers: {
                Authorization: token,
              },
            },
          )
          .then(res => {
            setToken(res.data.token);
            originalRequest.headers.Authorization = `${res.data.token}`;
            return originalRequest;
          })
          .catch(() => {
            if (window.location.href.split('/') > 4 || window.location.href.split('/')[3] !== '') {
              window.location.href = '/';
            }
            return new Error('Could not refresh token');
          });
      }
      originalRequest.headers.Authorization = token;

      return originalRequest;
    }
    return new Error('User not logged in');
  }

  requestError(error) {
    throw error;
  }

  afterResponse(resp) {
    return resp.data;
  }

  responseError(error) {
    // pass notifyErrors: true in request config object to disable errors notification for particular request
    const notifyErrorsFlag = error.response && error.response.config.notifyErrors;
    const showNotifyErrors = notifyErrorsFlag === undefined || notifyErrorsFlag === true;
    if (error.response) {
      if (showNotifyErrors) {
        if (_.isArray(error.response)) {
          toastr.error('Error occured', error.response.join('\n'));
        } else {
          return error.response.data && _.isObject(error.response.data)
            ? toastr.error('Error occurred', parseErrorResponse(error.response.data))
            : toastr.error('Error occurred', 'Unexpected error, please try again.');
        }
      }
    } else {
      throw error;
    }

    function parseErrorResponse(err) {
      let errorPlain = '';
      Object.keys(err).forEach(key => {
        const errResponse = err[key].reduce((acc, val) => `${acc} ${val} `, '');
        errorPlain += `${key.toUpperCase()}: ${errResponse}}`;
      });
      return errorPlain;
    }
  }
}

export default CoreApi;
