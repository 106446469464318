import React from 'react';
import PropTypes from 'prop-types';

import * as UI from './ButtonWithIconStyled';

const ButtonWithIcon = ({
  icon,
  text,
  children,
  className = '',
  disabled = false,
  variant,

  ...rest
}) => (
  <UI.ButtonWithIcon
    variant={variant}
    className={`button ${className}`}
    disabled={disabled}
    {...rest}
  >
    <span>{text}</span>
    <div className="icon">
      {icon && <i className={`fa fa-${icon}`} />}
      {children}
    </div>
  </UI.ButtonWithIcon>
);

ButtonWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node,
};

export { ButtonWithIcon };
