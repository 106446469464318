import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  AUTO_LOGIN,
  AUTHENTICATE,
  STORE_TOKEN,
  REQUIRE_AUTHY,
  LOGIN_AVEDA,
  ACCEPT_TERMS,
  ACCEPT_TERMS_SUCCESS,
  LOGIN_SSO,
  SESSION_EXPIRED,
} from './constants';

export function loginUser(email, password, redirectAddress) {
  return {
    type: LOGIN_USER,
    payload: { email, password, redirectAddress },
  };
}

export function loginAveda(email, password, redirectAddress) {
  return {
    type: LOGIN_AVEDA,
    payload: { email, password, redirectAddress },
  };
}

export function authenticate(authCode, token, redirectAddress) {
  return {
    type: AUTHENTICATE,
    payload: { authCode, token, redirectAddress },
  };
}

export function addMissingAuthy(countryCode, phoneNumber, token) {
  return {
    type: REQUIRE_AUTHY,
    payload: { countryCode, phoneNumber, token },
  };
}

export function userLoginSuccess(user) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: { user },
  };
}

export function userLoginError() {
  return {
    type: LOGIN_USER_FAILURE,
    payload: {},
  };
}

export function registerUser(registerPayload) {
  return {
    type: REGISTER_USER,
    payload: { ...registerPayload },
  };
}

export function registerUserSuccess(user) {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: { user },
  };
}

export function userRegisterError() {
  return {
    type: REGISTER_USER_FAILURE,
    payload: {},
  };
}

export function logoutUser(redirectToAveda) {
  return {
    type: LOGOUT_USER,
    payload: { redirectToAveda },
  };
}

export function logoutUserSuccess() {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
}

export function autoLogin() {
  return {
    type: AUTO_LOGIN,
    payload: {},
  };
}

export function storeToken(token) {
  return {
    type: STORE_TOKEN,
    payload: { token },
  };
}

export function acceptTerms(version, firstLogin) {
  return {
    type: ACCEPT_TERMS,
    payload: { version, firstLogin },
  };
}

export function acceptTermsSuccess() {
  return {
    type: ACCEPT_TERMS_SUCCESS,
  };
}

export function loginSSO(token, redirect) {
  return {
    type: LOGIN_SSO,
    payload: { token, redirect },
  };
}

export function sessionExpired() {
  return {
    type: SESSION_EXPIRED,
    payload: {},
  };
}
