import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useField } from 'formik';
import { toastr } from 'utils';

import { Wrapper, Modal, Form, Button, PopupContext } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import useEntityForm from 'hooks/useEntityForm';
import history from 'utils/history';
import entities from 'constants/entities';
import retailerStatus from 'constants/retailerStatus';
import { pick, omit } from 'lodash';
import { userSelector } from 'redux/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { userRole } from 'utils/userRole';
import * as Roles from 'constants/roles';
import { entityActions } from 'redux/entities/actions';
import { mapInitialValues } from '../../utils/retailerFormUtils';

const SalonInput = ({ FormGroup }) => {
  const [field] = useField('role');

  const { t } = useTranslation('pages');
  const isRequiredInput = ['Manager', 'Owner'].includes(field.value);
  return (
    <FormGroup required={isRequiredInput} label={t('retailers.salonId')}>
      <Form.MultiSelectSalon />
    </FormGroup>
  );
};

SalonInput.propTypes = {
  FormGroup: PropTypes.elementType,
};

const RetailerForm = ({ type = 'create', initialValues, FormGroup, wrapperType }) => {
  const { closePopup } = useContext(PopupContext);
  const { t: prefixT } = useTranslation('pages', {
    keyPrefix: 'retailers',
  });
  const { t } = useTranslation(['app', 'toastr', 'components']);
  const user = useSelector(userSelector);
  const isAveda = user.brand.preview === 'AVEDA';
  const dispatch = useDispatch();

  const { onSubmit, dependencies, validationSchema } = useEntityForm(type, 'retailer', {
    dependencies: ['product_group'],
  });

  const adminRole = userRole(user);

  const mappedInitialValues = useMemo(
    () => mapInitialValues(initialValues, user.regions[0].id, dependencies.product_group?.[0]),
    [initialValues, dependencies.product_group],
  );

  const isTestUser = useMemo(() => initialValues?.test_user_flag || false, [initialValues]);

  const onSubmitHandler = values => {
    const payload = prepareRequestPayload(values);

    onSubmit(payload);
    closePopup();
  };
  const prepareRequestPayload = values => {
    if (type === 'create') {
      values = {
        ...values,
        status: retailerStatus.INVITATION_PENDING,
      };
    }

    const isOwner = values.role === 'Owner';
    const isManager = values.role === 'Manager';

    const payload = {
      ...values,
      cover_photo_limit: true,
      commission: null,
      owner: isOwner,
      manager: isManager,
    };

    if (isAveda) {
      const selectedIds = values.location?.map(({ id }) => id) || [];
      const locationForArtist = selectedIds[0] ? [selectedIds[0]] : [];

      payload.locations = isOwner || isManager ? selectedIds : locationForArtist;
    }

    return payload;
  };

  const deleteAction = () => {
    toastr.confirm(t('toastr:retailerDelete'), {
      onOk: async () => {
        await dispatch(
          entityActions.deleteEntity(entities.retailer, initialValues.uid, history.goBack),
        );
      },
      dispatch,
    });
  };

  const getValidationSchema = schema => {
    if (!isAveda) {
      schema.fields = omit(schema.fields, ['location']);
    }

    return schema;
  };

  return (
    <Form
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
      }}
      enableReinitialize
      initialValues={pick(mappedInitialValues, [
        'email',
        'name',
        'surename',
        'tier',
        'region',
        'product_groups',
        'cover_photo_limit',
        'id',
        'uid',
        'status',
        'commission',
        'role',
        'location',
        'test_user_flag',
      ])}
      onSubmit={onSubmitHandler}
      validationSchema={getValidationSchema(validationSchema)}
    >
      <FormWrapper style={{ width: '100%' }} type={wrapperType === 'modal' ? 'create' : type}>
        <Row>
          <Col md={type === 'update' ? 12 : 6}>
            <FormGroup required label={prefixT('email')}>
              <Form.Input type="text" name="email" disabled={type === 'update'} />
            </FormGroup>
            <FormGroup required label={prefixT('name')}>
              <Form.Input
                type="text"
                name="name"
                disabled={type !== 'create' && isAveda && !isTestUser}
              />
            </FormGroup>
            <FormGroup required label={prefixT('lastName')}>
              <Form.Input
                type="text"
                name="surename"
                disabled={type !== 'create' && isAveda && !isTestUser}
              />
            </FormGroup>
            {isAveda && (
              <FormGroup tooltipText={prefixT('roleTooltip')} required label={prefixT('role')}>
                <Form.MultiSelect
                  options={[
                    { label: 'Owner', value: 'Owner' },
                    { label: 'Manager', value: 'Manager' },
                    { label: 'Artist', value: 'Artist' },
                  ]}
                  disabled={
                    type !== 'create' &&
                    mappedInitialValues.status !== retailerStatus.INVITATION_PENDING
                  }
                  name="role"
                  isMulti={false}
                />
              </FormGroup>
            )}
            <FormGroup label={prefixT('testUser')}>
              <Form.Checkbox name="test_user_flag" />
            </FormGroup>
          </Col>
          <Col md={type === 'update' ? 12 : 6}>
            {isAveda && <SalonInput FormGroup={FormGroup} />}
            {/* <FormGroup required label={prefixT('selectProductGroup')}>
              <Form.MultiSelect
                options={dependencies.product_group.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
                name="product_groups"
              />
            </FormGroup> */}
            <FormGroup label={prefixT('region')}>
              <Form.MultiSelect
                disabled={adminRole === Roles.ADMIN || type !== 'create'}
                options={user.regions.map(r => ({ value: r.id, label: r.name }))}
                name="region"
                isMulti={false}
              />
            </FormGroup>
            {/* <FormGroup label={prefixT('tier')}>
              <Form.MultiSelect
                options={dependencies.tier.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
                name="tier"
                isMulti={false}
              />
            </FormGroup> */}
          </Col>
        </Row>
      </FormWrapper>
      <Modal.Footer>
        {() => (
          <Wrapper d-flex justify-content-end>
            {type !== 'create' && (
              <Button onClick={deleteAction} variant="danger" className="mr-3">
                {t('delete')}
              </Button>
            )}
            <div>
              <Button onClick={closePopup} variant="white" className="mr-3">
                {t('cancel')}
              </Button>
              <Form.Submit>
                {type === 'create' ? t('components:inviteRetailer.inviteNow') : t('save')}
              </Form.Submit>
            </div>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

RetailerForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
  wrapperType: PropTypes.string,
};

export default RetailerForm;
