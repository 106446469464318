import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'components';
import columns from 'constants/columns';
import { deleteVariant } from 'redux/entities/actions';
import { useDispatch } from 'react-redux';

const ProductVariant = ({ productId, variants, editable }) => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'product' });
  const dispatch = useDispatch();

  return (
    <Table data={variants.map(i => ({ ...i, isEditable: editable }))} columns={columns.variant}>
      <Table.Header title={prefixT('variantsList')}>
        {/* <Table.GlobalFilter />
        {editable && (
          <CreateEntityModal
            title={t('createVariant')}
            subTitle={t('variant')}
            entity="sku"
            productId={productId}
            retailPrice={retailPrice}
          />
        )} */}
      </Table.Header>
      <Table.Content
        entity="sku"
        onDelete={values => dispatch(deleteVariant(values.uid, productId))}
        deletable={editable}
        editable={editable}
      />
    </Table>
  );
};

ProductVariant.propTypes = {
  productId: PropTypes.number.isRequired,
  variants: PropTypes.array,
  // retailPrice: PropTypes.number,
  editable: PropTypes.bool,
};

export default ProductVariant;
