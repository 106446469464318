import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { tourTypeSelector } from 'redux/tour/selectors';
import { useTour } from '@reactour/tour';
import { locationBodySelector } from '../../../../redux/locations/selectors';
import { setLastPageModuleUid } from '../../../../redux/tour/actions';

const mapToContent = stepName => (
  <>
    <h2>{i18n.t(`ownerPortal:locations.tour.steps.${stepName}Title`)}</h2>
    <h4>{i18n.t(`ownerPortal:locations.tour.steps.${stepName}Description`)}</h4>
  </>
);

const TourSteps = ({ children }) => {
  const tourType = useSelector(tourTypeSelector);
  const { setSteps, setCurrentStep } = useTour();
  const dispatch = useDispatch();
  const pageModules = useSelector(locationBodySelector);

  const editStep = {
    content: mapToContent('edit'),
    selector: '.tour-edit',
    position: 'left',
  };

  const shareOrEditStep = {
    content: mapToContent('shareOrEdit'),
    selector: '.tour-share-or-edit',
    position: 'bottom',
  };

  const addModuleStep = {
    content: mapToContent('addModule'),
    selector: '.tour-add-module',
    position: 'top',
    componentStateToWaitFor: 'bundlesCollectionRow',
    actionBeforeNextStep: () => document.querySelector('.tour-add-module')?.click(),
  };

  const manyEditsStep = {
    content: mapToContent('manyEdits'),
    selector: '[data-rbd-droppable-id="droppable"] .tour-edit',
    position: 'top',
  };

  const createLooksStep = {
    content: mapToContent('createLooks'),
    selector: '.tour-looks',
    position: 'right',
  };

  const favoriteProductsStep = {
    content: mapToContent('favoriteProducts'),
    selector: '.tour-favorite-products',
    position: 'right',
  };

  const webShoppeActivityStep = {
    content: mapToContent('webShoppeActivity'),
    selector: '.tour-activity-tracker',
    position: 'right',
  };

  const moduleTypeStep = {
    content: mapToContent('moduleType'),
    selector: '.bundlesCollection',
    position: 'bottom',
    componentStateToWaitFor: 'shoppableLooksTab',
    actionBeforeNextStep: () => {
      dispatch(setLastPageModuleUid(''));
      document.querySelector('.bundlesCollection')?.click();
    },
  };

  const shoppableLooksStep = {
    content: mapToContent('shoppableLooks'),
    selector: '.tour-shoppable-looks',
    position: 'bottom',
  };

  const customLookStep = {
    content: mapToContent('customLook'),
    selector: '.tour-custom-look',
    position: 'bottom',
    actionBeforeNextStep: () => {
      const close = document.querySelector('.close-modal');
      close?.parentElement.click(); // click() not working on svg?
    },
  };

  const multiLocationStep = {
    content: mapToContent('multiLocation'),
    selector: '.tour-multi-location',
    position: 'bottom',
  };

  const editPreviewShareStep = {
    content: mapToContent('editPreviewShare'),
    selector: '.tour-edit-preview-share',
    position: 'top',
    componentStateToWaitFor: 'shareOrEditButton',
    actionBeforeNextStep: () => document.querySelector('.tour-edit-preview-share .edit')?.click(),
  };

  const steps = {
    newUser: [
      editStep,
      shareOrEditStep,
      addModuleStep,
      moduleTypeStep,
      shoppableLooksStep,
      customLookStep,
      manyEditsStep,
      createLooksStep,
      favoriteProductsStep,
      webShoppeActivityStep,
    ],
    returningUser: [
      shareOrEditStep,
      addModuleStep,
      moduleTypeStep,
      shoppableLooksStep,
      customLookStep,
      manyEditsStep,
      createLooksStep,
      favoriteProductsStep,
    ],
    multiLocationUser: [
      multiLocationStep,
      editPreviewShareStep,
      shareOrEditStep,
      addModuleStep,
      moduleTypeStep,
      shoppableLooksStep,
      customLookStep,
      manyEditsStep,
      createLooksStep,
      favoriteProductsStep,
    ],
  };

  useEffect(() => {
    setSteps(tourType.length ? steps[tourType] : []);
    setCurrentStep(0); // reset the tour
  }, [tourType]);

  useEffect(() => {
    setSteps(
      tourType.length
        ? steps[tourType].map(item => {
            if (item.selector === '.tour-shoppable-looks') {
              return {
                ...item,
                actionBeforeNextStep: () => {
                  const lastPageModule = pageModules[pageModules.length - 1];
                  dispatch(setLastPageModuleUid(lastPageModule.uid));
                },
              };
            }
            return item;
          })
        : [],
    );
  }, [pageModules]);

  return children;
};

TourSteps.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TourSteps;
