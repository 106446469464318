import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { Card, Wrapper } from 'components';
import config from 'config';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import appStoreBadge from 'assets/img/app-store-badge.png';
import googlePlayBadge from 'assets/img/google-play-badge.png';

const QUERY_PARAM = '_branch_match_id';

const QRCodePage = () => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'QRCode' });
  const query = useQuery();
  const baseUrl = config.getAppUrl;
  const history = useHistory();

  useEffect(() => {
    if (!query.get(QUERY_PARAM)) {
      history.push('not_found');
    }
  }, []);

  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '72px' }}>
            <Wrapper p-3 d-flex flex-column align-items-center justify-content-center>
              <h1 className="display-4 text-center text-uppercase mb-3">
                {prefixT('yourQRCodeTitle')}
              </h1>
              <h1>{prefixT('yourQRCodeSubtitle')}</h1>
              <p className="text-muted text-center mb-5">{prefixT('description')}</p>
              <QRCode value={`${baseUrl}?${query.toString()}`} size={200} />
            </Wrapper>
            <div
              className="image-wrapper"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '45px',
              }}
            >
              <Image src={appStoreBadge} style={{ maxHeight: '45px' }} />
              <Image src={googlePlayBadge} style={{ maxHeight: '65px' }} />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default QRCodePage;
