import produce from 'immer';
import { pick } from 'lodash';
import User from 'api/models/User';
import { userRole } from 'utils/userRole';

import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  STORE_TOKEN,
  ACCEPT_TERMS_SUCCESS,
} from './constants';

export const initialState = {
  autoLoginProcessing: true, // Flag used to show preloader
  user: new User(),
  role: null,
};

const authReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case LOGIN_USER_SUCCESS:
        draft.autoLoginProcessing = false;
        draft.user = pick(payload.user, [
          'email',
          'name',
          'is_mac_admin',
          'is_brand_admin',
          'is_mac_super_admin',
          'is_brand_owner',
          'is_brand_manager',
          'brand',
          'acceptedTerms',
          'firstLogin',
          '_id',
          'regions',
          'locations',
          'connected_location',
          'tutorial_completion_status',
        ]);
        draft.role = userRole(payload.user);
        break;
      case LOGIN_USER_FAILURE:
        draft.autoLoginProcessing = false;
        draft.user = new User();
        break;
      case LOGOUT_USER_SUCCESS:
        draft.user = new User();
        break;
      case STORE_TOKEN:
        draft.token = payload.token;
        break;
      case ACCEPT_TERMS_SUCCESS:
        draft.user.acceptedTerms = true;
        break;
      default:
        return state;
    }
  });

export default authReducer;
