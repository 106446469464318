import produce from 'immer';
import {
  LOCATION_SELECT,
  UPDATE_SELECTED_LOCATION,
  UPDATE_HEADER,
  SET_SAVING,
  GET_LOCATION_GROUP_SUCCESS,
  ADD_LOCATION_PAGE_ELEMENT,
  ADD_LOCATION_PAGE_ELEMENT_SUCCESS,
  UPDATE_LOCATION_PAGE_ELEMENT,
  UPDATE_LOCATION_PAGE_ELEMENT_SUCCESS,
  DELETE_LOCATION_PAGE_ELEMENT,
  DELETE_LOCATION_PAGE_ELEMENT_SUCCESS,
  CREATE_LOCATION_GROUP_SUCCESS,
  UPDATE_LOCATION_GROUP_SUCCESS,
  DELETE_LOCATION_GROUP_SUCCESS,
  PUBLISH_LOCATION_PAGE_ELEMENTS,
  PUBLISH_LOCATION_PAGE_ELEMENTS_SUCCESS,
  UPDATE_LOCATION_PAGE_ELEMENT_POSITION,
  UPDATE_LOCATION_PAGE_ELEMENT_POSITION_SUCCESS,
  DELETE_TOUR_PAGE_MODULE,
} from './constants';

export const initialState = {
  selectedLocation: null,
  body: [],
  isSaving: false,
  isComponentEditing: false,
  locationsGroup: null,
  classification: [],
};

const locationReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case LOCATION_SELECT:
        draft.isSaving = false;
        draft.selectedLocation = payload.location;
        draft.body = payload.location.page_modules || [];
        break;
      case 'UPDATE_LOCATION_SUCCESS':
        draft.isSaving = false;
        draft.selectedLocation = payload;
        break;
      case UPDATE_HEADER:
        draft.selectedLocation = { ...draft.selectedLocation, ...payload.location };
        break;
      case UPDATE_SELECTED_LOCATION:
        draft.isSaving = true;
        break;
      case 'UPDATE_LOCATION_FAILURE':
        draft.isSaving = false;
        break;
      case SET_SAVING:
        draft.isSaving = payload;
        break;
      case GET_LOCATION_GROUP_SUCCESS: {
        draft.locationsGroup = payload;
        draft.locationsGroup.fetched = true;
        break;
      }
      case ADD_LOCATION_PAGE_ELEMENT: {
        draft.isSaving = true;
        break;
      }
      case ADD_LOCATION_PAGE_ELEMENT_SUCCESS: {
        const componentPosition = payload.position;
        const positionInArray = draft.body.findIndex(
          component => component.position === componentPosition - 1,
        );

        draft.isSaving = false;
        draft.selectedLocation.is_published = false;
        draft.body.splice(positionInArray + 1, 0, payload);
        break;
      }
      case UPDATE_LOCATION_PAGE_ELEMENT: {
        draft.isSaving = true;
        break;
      }
      case UPDATE_LOCATION_PAGE_ELEMENT_SUCCESS: {
        draft.isSaving = false;
        draft.selectedLocation.is_published = false;
        draft.body = payload;
        break;
      }
      case UPDATE_LOCATION_PAGE_ELEMENT_POSITION: {
        draft.isSaving = true;
        break;
      }
      case UPDATE_LOCATION_PAGE_ELEMENT_POSITION_SUCCESS: {
        draft.isSaving = false;
        draft.selectedLocation.is_published = false;
        draft.body = payload;
        break;
      }
      case DELETE_LOCATION_PAGE_ELEMENT: {
        draft.isSaving = true;
        break;
      }
      case DELETE_LOCATION_PAGE_ELEMENT_SUCCESS: {
        draft.isSaving = false;
        draft.body = payload;
        if (payload.every(obj => obj.is_published === true)) {
          draft.selectedLocation.is_published = true;
        }
        break;
      }

      case CREATE_LOCATION_GROUP_SUCCESS: {
        draft.locationsGroup = payload.locationGroup;
        draft.locationsGroup.fetched = true;
        break;
      }
      case UPDATE_LOCATION_GROUP_SUCCESS: {
        draft.locationsGroup = payload.locationGroup;

        draft.locationsGroup.fetched = true;
        break;
      }
      case DELETE_LOCATION_GROUP_SUCCESS: {
        draft.locationsGroup = {};
        draft.locationsGroup.fetched = true;
        break;
      }
      case PUBLISH_LOCATION_PAGE_ELEMENTS: {
        draft.isSaving = true;
        break;
      }
      case PUBLISH_LOCATION_PAGE_ELEMENTS_SUCCESS: {
        draft.isSaving = false;
        draft.selectedLocation.is_published = true;
        break;
      }

      case DELETE_TOUR_PAGE_MODULE: {
        const pageModulesIndexed = state.body.map((module, index) => ({ ...module, index }));
        const lastPageModules = pageModulesIndexed.slice(-5);
        const emptyBundles = lastPageModules.filter(
          module => module.name === 'bundlesCollection' && module.non_primary_stores.length === 0,
        );
        if (emptyBundles.length === 0) return;
        draft.body.splice(emptyBundles.pop().index, 1);
        break;
      }

      default:
        return state;
    }
  });
export default locationReducer;
