import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';

import { ListGroup, Spinner } from 'react-bootstrap';
import useLocations from 'hooks/useLocations';
import { MY_LOCATIONS_EDIT } from 'constants/routes';
import * as ShareModalUI from './SelectLocationAndAddModuleModal.styles';

const SelectLocationAndAddModuleModal = ({ trigger, id }) => {
  const {
    locations: { results },
    fetched,
    fetch,
  } = useLocations();

  return (
    <Modal onOpen={fetch} header={{ title: 'Select salon' }} trigger={trigger}>
      {!fetched && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      )}
      {fetched && (
        <>
          <ShareModalUI.IconsWrapper>
            <ListGroup>
              {results.map(({ name, uid }) => (
                <ListGroup.Item
                  href={`${MY_LOCATIONS_EDIT.replace(
                    ':id',
                    uid,
                  )}?insert_module=LocationPageBrandContentModule&insert_module_name=shearableLink&insert_module_uid=${id}`}
                  action
                >
                  {name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </ShareModalUI.IconsWrapper>
        </>
      )}
    </Modal>
  );
};

SelectLocationAndAddModuleModal.propTypes = {
  trigger: PropTypes.node,
  id: PropTypes.string.isRequired,
};

export default SelectLocationAndAddModuleModal;
