import styled from 'styled-components';
import { Card, Button } from 'react-bootstrap';

export const AddProductCard = styled(Card)`
  min-width: 220px;
  margin-bottom: 0;
  padding: 0 12px 12px 12px;
  border-radius: 0;
  font-size: 15px;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  max-width: 250px;
  img {
    max-height: 150px;
    object-fit: contain;
    cursor: pointer;
    width: 194px;
  }
  .name {
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  .description {
    line-height: 1.2;
    font-size: 0.8rem;
    margin-bottom: 6px;
  }
  price {
    margin: 0;
  }
`;

export const SelectAll = styled.div.attrs({
  className: 'form-check',
})`
  label {
    font-size: 12px;
  }

  input {
    border-radius: 0;
  }
`;

export const DeleteButton = styled(Button).attrs({
  variant: 'link',
  size: 'sm',
})`
  text-decoration: underline;
`;

export const EditButton = styled(Button).attrs({
  variant: 'outline-dark',
  size: 'sm',
})`
  border-radius: 0;
  padding: 0.125rem 1.5rem;
`;
