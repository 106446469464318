import React from 'react';

import { Container } from 'react-bootstrap';
import { Card } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/auth/selectors';

const UserProfile = () => {
  const { t } = useTranslation('app');
  const user = useSelector(userSelector);
  const isOwnerPortalUser = user.is_brand_owner || user.is_brand_manager;

  const getData = () => {
    const data = [
      {
        label: t('name'),
        value: user.name,
      },
      {
        label: t('email'),
        value: user.email,
      },
    ];

    if (!user.is_mac_super_admin && !isOwnerPortalUser) {
      data.push(
        ...[
          {
            label: t('entities.region'),
            value: user.regions.map(r => r.name).join(', '),
          },

          {
            label: t('entities.brand'),
            value: user.brand.name,
          },
        ],
      );
    }

    return data;
  };

  return (
    <Container className="px-5 mt-5 mx-auto">
      <Card title={t('profileTitle')} subtitle={t('profileSubtitle')}>
        <ul className="list-group">
          {getData().map(item => (
            <li className="list-group-item">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0 w-50">{item.label}</h4>
                <p className="m-0 w-50 text-right">{item.value}</p>
              </div>
            </li>
          ))}
        </ul>
        {!user.is_mac_super_admin && (
          <p
            className="font-weight-bold text-center mt-5 "
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: isOwnerPortalUser
                ? t('userInformationUpdate')
                : t('userInformationUpdateAdmin'),
            }}
          />
        )}
      </Card>
    </Container>
  );
};

export default UserProfile;
