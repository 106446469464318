import React, { useRef, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { wrapperStyleObject, toolbarSettings } from 'constants/WYSWIGEditorStyle';
import * as Ui from './BioEditor.styles';

const BioEditor = ({ editorState, setEditorState, message, setMessage }) => {
  const { t } = useTranslation('ownerPortal');

  const editorRef = useRef();

  useEffect(() => {
    if (message) {
      const blocksFromHTML = htmlToDraft(message);

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
        ),
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const onBioChange = useCallback(async () => {
    const updatedValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const isBioEmpty = !updatedValue
      .replace('<br>', '')
      .replace('<p>', '')
      .replace('</p>', '').length;

    const isBioValid = !isBioEmpty;
    if (isBioValid) setMessage(updatedValue);
  }, [editorState, setMessage]);

  return (
    <Ui.Wrapper onBlur={onBioChange}>
      <Editor
        editorRef={ref => {
          editorRef.current = ref;
        }}
        editorState={editorState}
        wrapperStyle={wrapperStyleObject}
        editorStyle={{
          padding: '0 20px',
          height: 'calc(100% - 50px)',
        }}
        placeholder={t('locations.bioPlaceholder')}
        toolbar={{
          ...toolbarSettings,
          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        onEditorStateChange={state => setEditorState(state)}
        style={{ flex: 1 }}
      />
    </Ui.Wrapper>
  );
};

BioEditor.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  message: PropTypes.string,
  setMessage: PropTypes.func,
};
export { BioEditor };
