import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { ConnectedRouter } from 'connected-react-router';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';

import history from 'utils/history';
import App from './containers/App/app';
import store from './configureStore';

import './i18n';
import 'assets/scss/theme.scss';

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <ConfirmModal />
    <ReduxToastr timeOut={3000} position="top-right" />
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE,
);
