import React from 'react';

function LocationPageModulePlaceholder() {
  return (
    <i
      className="fe fe-edit d-flex justify-content-center align-items-center"
      style={{ minHeight: '70px', fontSize: '25px' }}
    />
  );
}

export default LocationPageModulePlaceholder;
