import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import entities from 'constants/entities';
import PropTypes from 'prop-types';
import { Form as BootstrapForm, Image } from 'react-bootstrap';
import { Form, Modal, Button } from 'components';
import errorMessages from 'helpers/errorMessages';
import { toastr } from 'react-redux-toastr';
import { uploadMedia } from 'utils/mediaResourceUtils';
import { useTranslation } from 'react-i18next';
import useEntityForm from 'hooks/useEntityForm';
import { locationsGroupSelector } from 'redux/locations/selectors';
import { ownerSelector } from 'redux/auth/selectors';
import {
  createLocationGroup,
  deleteLocationGroup,
  updateLocationGroup,
} from 'redux/locations/actions';
import { SocialShare } from 'pages/Locations/LocationsModals';
import { ImageUpload } from 'components/Form/inputs/ImageUpload/ImageUpload';
import { useOwnerGroupPageQrCode } from 'pages/Locations/hooks/useOwnerGroupPageQrCode';
import { UrlPathname } from 'components/UrlPathname/UrlPathname';
import { useVanityUrlCheck } from 'hooks/useVanityUrlCheck';
import { validateOnlyNumbersAndLetters } from 'utils/validators';
import * as ShareModalUI from '../../modals/ShareModal/ShareModal.styles';

import { useLocationGroupRetailers } from '../../hooks/useLocationGroupRetailers';

export const OwnerGroupForm = React.memo(({ locations }) => {
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'locations' });
  const { t } = useTranslation(['app', 'toastr']);
  const dispatch = useDispatch();

  const locationGroup = useSelector(locationsGroupSelector);
  const isOwnerUser = useSelector(ownerSelector);
  const qrCode = useOwnerGroupPageQrCode(locationGroup?.vanity_url);
  const [vanityUrl, setVanityUrl] = useState(locationGroup?.vanity_url);

  // eslint-disable-next-line
  const [image, setImage] = useState('');

  const { triggerCheck, notification, clearNotification, setNotification } = useVanityUrlCheck(
    'owner_group_page',
  );

  const onCheckButtonClick = useCallback(
    (__, path) => {
      triggerCheck(path);
    },
    [triggerCheck],
  );

  const { validationSchema, onSubmit } = useEntityForm('update', entities.owner_group_page);

  const retailersInputOptions = useLocationGroupRetailers();

  useEffect(() => {
    if (locationGroup) {
      setImage(locationGroup.logo_id);
    }
  }, [locationGroup]);

  const isOwenerGroupPageExist = useMemo(() => !!locationGroup?.vanity_url, [locationGroup]);

  const deleteAction = useCallback(() => {
    toastr.confirm(t('toastr:ownerGroupPageDelete'), {
      onOk: async () => {
        await dispatch(deleteLocationGroup(locationGroup.uid));
      },
      dispatch,
    });
  }, [dispatch, locationGroup]);

  const initialValues = useMemo(() => {
    if (locationGroup?.vanity_url) {
      return {
        name: locationGroup.name,
        logo_id: [],
        invite_retailers: locationGroup.assigned_retailers.map(({ uid }) => uid),
        uid: locationGroup.uid,
        assign_locations: locationGroup.locations.map(({ uid }) => uid),
      };
    }

    return {
      name: '',
      logo_id: [],
      invite_retailers: [],
      assign_locations: [],
    };
  }, [locationGroup]);

  const locationOptions = useMemo(() => {
    const userLocation = locations.map(item => ({
      value: item.uid,
      label: item.name,
    }));

    const pageGroupLocations = locationGroup?.locations
      ? locationGroup.locations.map(item => ({
          value: item.uid,
          label: item.name,
        }))
      : [];

    return [...userLocation, ...pageGroupLocations].reduce((unique, o) => {
      if (!unique.some(obj => obj.value === o.value)) {
        unique.push(o);
      }
      return unique;
    }, []);
  }, [locations, locationGroup]);

  const onSubmitError = error => {
    if (error.vanity_url) {
      setNotification({ type: 'error', text: error.vanity_url });
    } else {
      const messages = errorMessages(error);

      toastr.error(`Failed to update owner group page`, {
        component: (
          <>
            {messages.map(item => (
              <p className="text-small m-0">{item}</p>
            ))}
          </>
        ),
      });
    }
  };

  const handleSubmit = useCallback(
    async values => {
      let payload = values;
      if (!vanityUrl) {
        setNotification({ type: 'error', text: 'Required' });
        return;
      }

      if (!validateOnlyNumbersAndLetters(vanityUrl)) {
        setNotification({
          type: 'error',
          text: 'The path can only contain letters and numbers',
        });
        return;
      }

      if (vanityUrl !== locationGroup?.vanity_url) {
        payload.vanity_url = vanityUrl;
      }

      if (values.logo_id.length) {
        const response = await uploadMedia(values.logo_id);
        payload = {
          ...payload,
          logo_id: response.public_id,
        };
      } else {
        payload = omit(payload, ['logo_id']);
      }

      if (isOwenerGroupPageExist) {
        return dispatch(updateLocationGroup(locationGroup.uid, payload, onSubmitError));
      }

      dispatch(createLocationGroup(payload, onSubmitError));
    },
    [vanityUrl, locationGroup, onSubmit, isOwenerGroupPageExist],
  );

  return (
    <Form
      initialValues={initialValues}
      // enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {props => (
        <>
          <Form.GroupHorizontal label={prefixT('locationGroupPageName')}>
            <Form.Input type="text" name="name" />
          </Form.GroupHorizontal>
          <BootstrapForm.Label>{prefixT('locationGroupPageVanityUrl')}</BootstrapForm.Label>
          <UrlPathname
            domain="https://shopaveda.me/g/"
            pathname={locationGroup.vanity_url}
            onButtonClick={(domain, path, buttonRef) => {
              if (!validateOnlyNumbersAndLetters(path)) {
                setNotification({
                  type: 'error',
                  text: 'The path can only contain letters and numbers',
                });
                return;
              }
              if (path === locationGroup.vanity_url) {
                setNotification({
                  type: 'info',
                  text: 'This is your current URL.',
                });
                return;
              }

              onCheckButtonClick(domain, path, buttonRef);
            }}
            onChange={vanity => {
              setVanityUrl(vanity);
              clearNotification();
            }}
            buttonLabel="Check"
            notification={notification}
            setNotification={setNotification}
          />
          <ShareModalUI.VanityLinkAbout>
            <p>Customize your own Multi-location landing page URL</p>
            <ul>
              <li>
                If the name is not available you can try adding a dash: <br />
                https://shopaveda.me/g/name-name
              </li>
              <li>No need to include the www.shopaveda.me/g/ portion of the URL</li>

              <li>Custom links are activated 30 minutes after you publish on the next page</li>
            </ul>
          </ShareModalUI.VanityLinkAbout>
          <Form.GroupHorizontal label={prefixT('locationGroupPageLogo')}>
            <ImageUpload name="logo_id" maxFiles={1} />
            {/* eslint-disable-next-line */}
            {locationGroup?.logo && !props?.values?.logo_id?.length && (
              <Image src={locationGroup.logo} className="rounded" style={{ maxWidth: '80px' }} />
            )}
          </Form.GroupHorizontal>

          <Form.GroupHorizontal label={prefixT('locationGroupPageLocations')}>
            <Form.MultiSelect
              options={locationOptions}
              name="assign_locations"
              isMulti
              withSelectAll
            />
          </Form.GroupHorizontal>
          {isOwnerUser && isOwenerGroupPageExist && (
            <Form.GroupHorizontal label={prefixT('locationGroupPageAssign')}>
              <Form.MultiSelect
                options={retailersInputOptions}
                name="invite_retailers"
                isMulti
                withSelectAll
              />
            </Form.GroupHorizontal>
          )}
          <div className="position-relative">
            <Form.Submit className="mt-3" disabled={false} withOutSubmitAction>
              {isOwenerGroupPageExist
                ? prefixT('locationGroupPageUpdate')
                : prefixT('locationGroupPageCreate')}
            </Form.Submit>
            {isOwenerGroupPageExist && (
              <>
                <Button
                  className="mt-3 ml-3"
                  variant="light"
                  onClick={() => window.open(locationGroup.preview_url, '_blank').focus()}
                >
                  {prefixT('locationGroupPagePreview')}
                </Button>
                <Button onClick={deleteAction} variant="danger" className="mt-3 ml-3">
                  {t('delete')}
                </Button>
                <Modal
                  header={{
                    title: prefixT('locationGroupPageShare'),
                  }}
                  trigger={() => (
                    <Button
                      className="position-absolute mt-3 ml-3"
                      variant="primary"
                      style={{ right: 0 }}
                    >
                      {' '}
                      {prefixT('locationGroupPageShare')}
                    </Button>
                  )}
                >
                  <SocialShare url={locationGroup.preview_url} qr={qrCode} />
                </Modal>
              </>
            )}
          </div>
        </>
      )}
    </Form>
  );
});

OwnerGroupForm.propTypes = {
  locations: PropTypes.array.isRequired,
};
