import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'redux/auth/actions';
import { toastr } from 'utils';
import { roleSelector, userSelector } from 'redux/auth/selectors';

import { Navigation } from 'components';
import { PROFILE } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import * as userRoles from 'constants/roles';
import { menuItems } from './menu';

const SideNavigation = ({ compact, toggleNavigation }) => {
  const { t } = useTranslation(['menu', 'toastr']);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const role = useSelector(roleSelector);
  const brand = {
    mac: user.brand?.preview === 'MAC',
    aveda: user.brand?.preview === 'AVEDA',
  };

  const buildMenu = (items, subItem = false) =>
    items.map(item => {
      if (
        item &&
        item?.items &&
        (!item.roles || item?.roles?.includes(role)) &&
        (!item.preview || item?.preview?.some(preview => brand[preview]))
      ) {
        return (
          <Navigation.Link
            key={`navlink-${item.id}`}
            id={`navlink-${item.id}`}
            to={role === userRoles.SUPER_ADMIN ? item.superAdminLink : item.link}
            name={
              [userRoles.SUPER_ADMIN, userRoles.BRAND_ADMIN, userRoles.ADMIN].includes(role)
                ? item.adminToolLabel || item.label
                : item.label
            }
            icon={item.icon}
            renderIcon={item.renderIcon}
            subItem={subItem}
            guard={item?.guard}
            tourClassName={item?.tourClassName}
          >
            {buildMenu(item.items, true)}
          </Navigation.Link>
        );
      }
      if (
        item &&
        (!item.roles || item?.roles?.includes(role)) &&
        (!item.preview || item?.preview?.some(preview => brand[preview]))
      ) {
        return (
          <Navigation.Link
            key={`navlink-${item.id}`}
            to={item.link}
            name={
              [userRoles.SUPER_ADMIN, userRoles.BRAND_ADMIN, userRoles.ADMIN].includes(role)
                ? item.adminToolLabel || item.label
                : item.label
            }
            icon={item.icon}
            renderIcon={item.renderIcon}
            subItem={subItem}
            tourClassName={item?.tourClassName}
          />
        );
      }
      return null;
    });

  return (
    <Navigation toggleNavigation={toggleNavigation} compact={compact}>
      <div className="d-flex flex-column justify-content-between" style={{ flex: 1 }}>
        <div>{buildMenu(menuItems)}</div>
        <div>
          <div>
            <Navigation.Link active={false} icon="fe-user" name={t('profile')} to={PROFILE} />
          </div>

          <Navigation.Link
            name={t('logout')}
            icon="fe-log-out"
            active={false}
            to="#"
            onClick={() =>
              toastr.confirm(t('toastr:logoutQuestion'), {
                onOk: () => {
                  dispatch(logoutUser([userRoles.OWNER, userRoles.MANAGER].includes(role)));
                },
                dispatch,
              })
            }
          />
        </div>
      </div>
    </Navigation>
  );
};

SideNavigation.propTypes = {
  compact: PropTypes.bool,
  toggleNavigation: PropTypes.func,
};

export default SideNavigation;
