import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { removeToken, setToken } from 'utils/tokenService';
import { toastr } from 'utils';
import { removeBrand, setBrand, setBrandUid } from 'utils/brandService';
import { clearData, entityActions } from 'redux/entities/actions';
import entities from 'constants/entities';
import i18n from 'i18n';
import AuthService from '../../api/services/AuthService';
import {
  userLoginError,
  userLoginSuccess,
  logoutUserSuccess,
  storeToken,
  acceptTermsSuccess,
} from './actions';
import {
  AUTHENTICATE,
  AUTO_LOGIN,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  REQUIRE_AUTHY,
  LOGIN_AVEDA,
  ACCEPT_TERMS,
  LOGIN_SSO,
  SESSION_EXPIRED,
} from './constants';
import {
  ADMIN_TOOL_LOGIN,
  AUTH,
  ADD_NUMBER,
  LANDING_PAGE,
  AVEDA_LOGIN,
  MY_LOCATIONS,
  DASHBOARD,
} from '../../constants/routes';
import { isSsoEnabled } from '../../utils/featureFlags.ts';
import { userSelector } from './selectors';

function* sessionExpired(redirection) {
  yield call(removeToken);
  yield call(removeBrand);
  yield call(() => localStorage.removeItem('sheetId'));
  yield put(clearData());
  yield put(push(redirection));
}

function* loginUser(action) {
  const { email, password, redirectAddress } = action.payload;

  try {
    const { token } = yield call([AuthService, 'login'], email, password);
    yield put(storeToken(token));
    setToken(token);
    const { enabled } = yield call([AuthService, 'checkAuthy']);
    removeToken();
    if (enabled) {
      const page = redirectAddress ? `${AUTH}?redirectUrl=${redirectAddress}` : AUTH;
      yield put(push(page));
    } else {
      yield put(push(ADD_NUMBER));
    }
  } catch (err) {
    if (err.response.data) {
      Object.values(err.response.data).forEach(message =>
        toastr.error(i18n.t('toastr:error'), message),
      );
    }
    yield put(userLoginError());
  }
}

function* loginSso(action) {
  const { token, redirect } = action.payload;

  try {
    yield put(storeToken(token));
    setToken(token);
    const user = yield call([AuthService, 'fetchUser']); // also it checks if token is viable
    setBrand(user.brand.preview);
    setBrandUid(user.brand.uid);
    yield put(userLoginSuccess(user));
    yield put(push(redirect || DASHBOARD));
  } catch (err) {
    yield sessionExpired(AVEDA_LOGIN);
    yield put(userLoginError());
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:ssoError'));
  }
}

function* loginError(userType) {
  removeToken();
  switch (userType) {
    case 'artist':
      toastr.error(i18n.t('toastr:wrongCredentials'), i18n.t('toastr:wrongLoginPage'));
      yield put(push(AVEDA_LOGIN));
      break;
    case 'owner': // fallthrough
      toastr.error(i18n.t('toastr:wrongCredentials'), i18n.t('toastr:wrongLoginPage'));
      yield put(push(AVEDA_LOGIN));
      break;
    case 'admin': // fallthrough
    default:
      toastr.error(i18n.t('toastr:wrongCredentials'), i18n.t('toastr:wrongLoginPage'));
      yield put(push(ADMIN_TOOL_LOGIN));
  }
}

function getUserType(user) {
  if (user.is_mac_admin || user.is_brand_admin || user.is_mac_super_admin) {
    return 'admin';
  }
  if (user.is_brand_manager || user.is_brand_owner) {
    return 'owner';
  }
  return 'artist';
}

function* loginAveda(action) {
  const { email, password, redirectAddress } = action.payload;

  try {
    // to do: check if post login return user, if yes, delete fetchUser call below
    const { token, first_login } = yield call([AuthService, 'login'], email, password);
    setToken(token);
    const user = yield call([AuthService, 'fetchUser']);
    const userType = getUserType(user);
    if (userType !== 'owner') {
      yield loginError(userType);
      return;
    }
    setBrand(user.brand.preview);
    setBrandUid(user.brand.uid);
    yield put(userLoginSuccess({ ...user, firstLogin: first_login }));
    const nextPage = first_login ? LANDING_PAGE : redirectAddress;
    yield put(push(nextPage));
  } catch (err) {
    if (err.response.status === 403) {
      yield loginError();
      return;
    }
    if (err.response.data) {
      Object.values(err.response.data).forEach(message =>
        toastr.error(i18n.t('toastr:error'), message),
      );
    }
    yield put(userLoginError());
  }
}

function* authenticate(action) {
  const { authCode, token, redirectAddress } = action.payload;
  try {
    setToken(token);
    yield call([AuthService, 'authy'], authCode);
    const user = yield call([AuthService, 'fetchUser']);
    const userType = getUserType(user);
    if (userType !== 'admin') {
      yield loginError(userType);
      return;
    }
    if (!user.is_mac_super_admin) setBrand(user.brand.preview);
    yield put(userLoginSuccess(user));

    yield put(entityActions.fetchEntity(entities.region));
    yield put(push(redirectAddress));
  } catch (err) {
    if (err?.response.data) {
      Object.values(err.response.data).forEach(message =>
        toastr.error(i18n.t('toastr:error'), message),
      );
    }
    removeToken();
    yield put(userLoginError());
  }
}

function* addMissingAuthy(action) {
  const { countryCode, phoneNumber, token } = action.payload;
  try {
    setToken(token);
    yield call([AuthService, 'addMissingAuthy'], countryCode, phoneNumber);
    yield put(push(AUTH));
  } catch (err) {
    removeToken();
    if (err.response.data) {
      Object.values(err.response.data).forEach(message =>
        toastr.error(i18n.t('toastr:error'), message),
      );
    }
  }
}

function* registerUser(action) {
  const {
    email,
    password,
    name,
    countryCode,
    phoneNumber,
    verificationCode,
    isAveda,
  } = action.payload;
  try {
    if (isAveda) {
      yield call([AuthService, 'registerOwnerPortal'], email, password, name, verificationCode);
      return yield call(loginAveda, { payload: { email, password } });
    }
    yield call([AuthService, 'register'], email, password, name, countryCode, phoneNumber);
    yield call(loginUser, { payload: { email, password } });
  } catch (err) {
    if (err.response.data) {
      if ('authy_id' in err.response.data) {
        toastr.error(i18n.t('toastr:error'), 'Invalid phone number');
      } else {
        toastr.error(i18n.t('toastr:error'), Object.values(err.response.data).toString());
      }
    }
    removeToken();

    yield put(userLoginError());
  }
}

function* autoLogin() {
  try {
    const user = yield call([AuthService, 'fetchUser']);
    yield put(userLoginSuccess({ ...user, firstLogin: false }));
    yield put(entityActions.fetchEntity(entities.region));
  } catch (err) {
    removeToken();
    removeBrand();
    yield put(userLoginError());
  }
}

function* logoutUser(action) {
  const { redirectToAveda } = action.payload;
  yield sessionExpired(redirectToAveda ? AVEDA_LOGIN : ADMIN_TOOL_LOGIN);
  yield put(logoutUserSuccess());
  toastr.success(i18n.t('toastr:logoutSuccessful'));
}

function* acceptTerms(action) {
  const { version, firstLogin } = action.payload;
  const user = yield select(userSelector);

  try {
    yield call([AuthService, 'acceptTerms'], version);

    setBrand(user.brand.preview);

    yield put(acceptTermsSuccess());

    yield put(push(firstLogin ? LANDING_PAGE : MY_LOCATIONS));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:acceptTermsError'));
  }
}

const authSagas = [
  takeLatest(LOGIN_USER, loginUser),
  takeLatest(LOGIN_AVEDA, loginAveda),
  takeLatest(AUTHENTICATE, authenticate),
  takeLatest(REQUIRE_AUTHY, addMissingAuthy),
  takeLatest(REGISTER_USER, registerUser),
  takeLatest(AUTO_LOGIN, autoLogin),
  takeLatest(LOGOUT_USER, logoutUser),
  takeLatest(ACCEPT_TERMS, acceptTerms),
  takeLatest(SESSION_EXPIRED, sessionExpired),
];

if (isSsoEnabled()) {
  authSagas.push(takeLatest(LOGIN_SSO, loginSso));
}

export { authSagas };
