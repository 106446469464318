import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Card, Form, Wrapper } from 'components';
import * as Yup from 'yup';
import { registerUser } from 'redux/auth/actions';
import { Link } from 'react-router-dom';
import { ADMIN_TOOL_LOGIN } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import useQuery from 'hooks/useQuery';

const RegisterPage = () => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'adminRegistration' });
  const { t } = useTranslation('validation');
  const dispatch = useDispatch();
  const query = useQuery();

  const [phoneNumber, setPhoneNumber] = React.useState();
  const [countryCode, setCountryCode] = React.useState('');

  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '18px' }}>
            <div style={{ padding: '16px 36px' }}>
              <h1 className="display-4 text-center text-uppercase mb-3">{prefixT('register')}</h1>
              <p className="text-muted text-center mb-5">{prefixT('subtitle')}</p>
              <Form
                initialValues={{
                  name: query.get('name') || '',
                  email: query.get('email') || '',
                  password: '',
                  passwordRepeat: '',
                  countryCode: '',
                }}
                onSubmit={async values => {
                  dispatch(
                    registerUser({
                      ...values,
                      phoneNumber,
                      countryCode,
                      isAveda: false,
                    }),
                  );
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .min(3, t('atLeastChars', { number: 3 }))
                    .required(t('required')),
                  email: Yup.string()
                    .email('Invalid email address')
                    .required(t('required')),
                  passwordRepeat: Yup.string().oneOf(
                    [Yup.ref('password'), null],
                    'Passwords must match',
                  ),
                })}
              >
                <Form.Group>
                  <Form.Label>{prefixT('name')}</Form.Label>
                  <Form.Input name="name" type="text" placeholder="John Doe" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{prefixT('email')}</Form.Label>
                  <Form.Input name="email" type="email" placeholder="jane@formik.com" />
                </Form.Group>
                <Form.GroupHorizontal label={prefixT('phoneNumber')}>
                  <PhoneInput
                    country="us"
                    inputStyle={{ width: '100%', paddingBlock: '8px' }}
                    onChange={(value, country) => {
                      setCountryCode(country.dialCode);
                      setPhoneNumber(value.slice(countryCode.length));
                    }}
                  />
                </Form.GroupHorizontal>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>{prefixT('password')}</Form.Label>
                    </Col>
                  </Row>
                  <Form.Input
                    type="password"
                    name="password"
                    placeholder={prefixT('passwordPlaceholder')}
                  />
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>{prefixT('rePassword')}</Form.Label>
                    </Col>
                  </Row>
                  <Form.Input
                    type="password"
                    name="passwordRepeat"
                    placeholder={prefixT('rePasswordPlaceholder')}
                  />
                </Form.Group>

                <Wrapper mb-3>
                  <Form.Submit size="lg" variant="dark" block>
                    {prefixT('signUp')}
                  </Form.Submit>
                </Wrapper>
                <Wrapper d-flex justify-content-center align-items-center mt-5>
                  <p className="pr-3"> {prefixT('backToLogin')} </p>
                  <Link to={ADMIN_TOOL_LOGIN} className="h3 text-muted">
                    {prefixT('login')}
                  </Link>
                </Wrapper>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterPage;
