import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Domain = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 5px;
  /* flex: 1 0 0; */
  align-self: stretch;
  border-top: 1px solid var(--borders-border-input-disabled, #a3a3a3);
  border-bottom: 1px solid var(--borders-border-input-disabled, #a3a3a3);
  border-left: 1px solid var(--borders-border-input-disabled, #a3a3a3);
  background: var(--neutrals-100, #f5f5f5);

  color: var(--fonts-icons-dark-text-medium, #525252);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  outline: none;
  flex-grow: 0;
  min-width: 0;
`;

export const PathNameWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  background: var(--surface-default, #fff);
  position: relative;
  left: -1px;
  min-width: 0;
`;

export const PathName = styled.input`
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  background: var(--surface-default, #fff);
  border: 1px solid var(--borders-border-input-default, #525252);

  color: var(--fonts-icons-dark-text-strong, #000);
  /* Paragraph/Body 2/Semibold */
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  min-width: 0;
  padding-right: 65px;
  text-overflow: ellipsis;
`;

export const Button = styled.button`
  position: absolute;
  right: 16px;

  color: var(--fonts-icons-dark-text-strong, #000);
  /* Paragraph/Body 2/Underline */
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  top: 13px;
`;

export const MessageWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 12px;
  align-items: flex-start;
`;

export const Message = styled.span`
  color: ${({ type }) =>
    type === 'success'
      ? 'var(--system-text-success, #087443)'
      : type === 'info'
      ? ' #0040c1'
      : 'var(--system-text-danger, #a80000)'};
  /* Paragraph/Body 2/Regular */
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.07px;
`;
