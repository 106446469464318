import { getBrandUid } from 'utils/brandService';
import CoreService from './CoreService';

class AuthService extends CoreService {
  /* eslint-disable no-useless-constructor */
  constructor(endpoint) {
    super(endpoint);
  }

  login(email, password) {
    return this.api.post('/authentication/login', { email, password }, { requireAuth: false });
  }

  register(email, password, name, countryCode, phoneNumber) {
    return this.api.post(
      '/authentication/signup',
      {
        email,
        password,
        name,
        country_code: countryCode,
        phone_number: phoneNumber,
      },
      { requireAuth: false },
    );
  }

  registerOwnerPortal(email, password, name, verificationCode) {
    return this.api.post(
      '/authentication/signup',
      {
        email,
        password,
        name,
        verification_code: verificationCode,
      },
      { requireAuth: false },
    );
  }

  refreshToken() {
    return this.api.get('/authentication/refresh', { requireAuth: false });
  }

  fetchUser() {
    return this.api.get('/me');
  }

  authy(verificationCode) {
    return this.api.post('/me/tfa', { token: verificationCode });
  }

  addMissingAuthy(countryCode, phoneNumber) {
    return this.api.post('/me/authy', { country_code: countryCode, phone_number: phoneNumber });
  }

  checkAuthy() {
    return this.api.post('/me/authy_enabled');
  }

  completeTutorial() {
    return this.api.post('/me/complete_tutorial');
  }

  getTerms() {
    return this.api.get('/term/latest', { headers: { brand: getBrandUid() } });
  }

  acceptTerms(version) {
    return this.api.post('/accept-term', { term_version: version });
  }

  getBundleDetails(shortId) {
    return this.api.get(`/store/${shortId}`);
  }

  sendChangePasswordCode(email) {
    return this.api.post('authentication/sendResetPasswordCode', { email }, { requireAuth: false });
  }

  changePassword(email, code, password) {
    return this.api.post(
      '/authentication/useResetPasswordCode',
      { email, code, password },
      { requireAuth: false },
    );
  }

  getStores() {
    return this.api.get('/store');
  }
}
const instance = new AuthService('/v2');

export default instance;
