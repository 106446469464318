export const currencies = [
  {
    code: 'CAD',
    description: 'Canadian Dollar',
  },

  {
    code: 'EUR',
    description: 'Euro',
  },
  {
    code: 'USD',
    description: 'United States Dollar',
  },
  {
    code: 'GBP',
    description: 'British Pound',
  },
];
