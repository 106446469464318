import React from 'react';
import PropTypes from 'prop-types';
import withDefaultProps from 'helpers/withDefaultProps';
import { closeModalAction } from 'redux/modal/actions';
import { useDispatch } from 'react-redux';

const NewModal = ({ children, modalStyle = {}, ...restProps }) => (
  <div className="modal d-block" {...restProps}>
    <div className="modal-dialog modal-dialog-centered" style={modalStyle} role="document">
      <div
        className="modal-content"
        style={{ border: '1px solid grey', maxHeight: '90vh', overflow: 'scroll' }}
      >
        {children}
      </div>
    </div>
  </div>
);

NewModal.Header = ({ title, subTitle }) => {
  // eslint-disable-next-line
  const dispatch = useDispatch();

  return (
    <div className="modal-header">
      <div className="d-flex flex-column">
        <h3 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h3>

        {subTitle && <h5 className="m-0 text-muted">{subTitle}</h5>}
      </div>
      <button
        onClick={() => dispatch(closeModalAction())}
        style={{
          background: 'transparent',
          border: 'none',
          fontSize: 25,
          position: 'absolute',
          right: 20,
        }}
        type="button"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

NewModal.Body = ({ children }) => <div className="modal-body">{children}</div>;

NewModal.Footer = ({ onSave }) => {
  // eslint-disable-next-line
  const dispatch = useDispatch();

  return (
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary"
        data-dismiss="modal"
        onClick={() => dispatch(closeModalAction())}
      >
        Close
      </button>
      <button type="button" className="btn btn-primary" onClick={onSave}>
        Save
      </button>
    </div>
  );
};

NewModal.propTypes = { ...withDefaultProps() };
NewModal.Header.propTypes = { title: PropTypes.string, ...withDefaultProps() };
NewModal.Body.propTypes = { ...withDefaultProps() };
NewModal.Footer.propTypes = {
  ...withDefaultProps(),
  onSave: PropTypes.func,
};

export default NewModal;
