import React from 'react';
import PropTypes from 'prop-types';
import * as Ui from './DateRangePicker.styles';

export const DateRangePicker = ({ start, end, onClick }) => {
  if (!start) {
    return (
      <Ui.Wrapper>
        <Ui.DateButton onClick={onClick}>Set expiration date</Ui.DateButton>
      </Ui.Wrapper>
    );
  }
  return (
    <Ui.Wrapper onClick={onClick}>
      {start.date && <span className="px-2">Start Date: </span>}
      {start.date && <Ui.DateButton>{start.date}</Ui.DateButton>}
      {start.time && <Ui.HourButton>{start.time}</Ui.HourButton>}
      {end?.date && <br />}
      {end?.date && <span className="px-2">Expiration Date: </span>}
      {end?.date && <Ui.DateButton>{end.date}</Ui.DateButton>}
      {end?.time && <Ui.HourButton>{end.time}</Ui.HourButton>}
    </Ui.Wrapper>
  );
};

DateRangePicker.propTypes = {
  start: PropTypes.shape({ date: PropTypes.string, time: PropTypes.string }),
  end: PropTypes.shape({ date: PropTypes.string, time: PropTypes.string }),
  onClick: PropTypes.func,
};
