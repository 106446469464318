import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import CloseButton from 'components/CloseButton/closeButton';
import { useTranslation } from 'react-i18next';
import EntityService from '../../api/services/EntityService';

export default function FeedbackPrompt({ trigger, user }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const triggerRef = React.useRef();
  const { t } = useTranslation('ownerPortal');

  const openLink = async (review = false) => {
    // open url in new tab
    const { url } = await EntityService.generateLink(user.vanity_url);
    const fullUrl = review ? `${url}&feedback=true&preview=true` : `${url}?preview=true`;
    window.open(fullUrl, '_blank');
  };

  const popoverStyle = {
    position: 'relative',
    backgroundColor: 'white',
    maxWidth: 400,
    minWidth: 200,
    maxHeight: 600,
    overflow: 'auto',
  };

  const FeedbackToShow = React.useMemo(() => {
    if (isExpanded) {
      return user?.feedbacks || [];
    }
    return user?.feedbacks?.slice(0, 2) || [];
  });

  return (
    <div style={{ position: 'relative' }}>
      {React.cloneElement(trigger, {
        onClick: () => setIsOpen(true),
        ref: triggerRef,
        style: { cursor: 'pointer' },
      })}
      <Popover
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={triggerRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={popoverStyle}>
          <CloseButton
            onClick={() => setIsOpen(false)}
            style={{ position: 'absolute', top: 5, right: 5 }}
          />
          {FeedbackToShow.map((feedback, index) => (
            // feedback has createdAt, content
            <div key={feedback.createdAt}>
              <div className="bg-light py-3 px-3">
                {/* HEADER */}
                <p className="text-capitalize p-0 m-0 font-weight-bold">
                  {index < 2 ? (index === 0 ? 'last feedback' : 'previous feedback') : ''}
                </p>
                <p className="text-small text-muted p-0 m-0">
                  {moment(feedback.createdAt)
                    .format('MMM, DD')
                    .toUpperCase()}
                </p>
              </div>
              <p className="py-3 px-3 m-0 text-black-50 text-wrap text-break">{feedback.content}</p>
            </div>
          ))}
          {!!FeedbackToShow.length && user?.feedbacks?.length > 2 && (
            <Button
              variant="link"
              onClick={() => setIsExpanded(prev => !prev)}
              className="w-100 text-center text-small"
            >
              <p className="text-center text-small text-decoration-underline text-uppercase text-body">
                {isExpanded ? 'show less' : 'show more'}
              </p>
            </Button>
          )}
        </div>
        <Button
          className="rounded-0 text-uppercase"
          variant="dark"
          style={{ width: '100%' }}
          onClick={() => openLink(true)}
        >
          <span className="text-small">{t('allStylists.reviewPage')}</span>
        </Button>
      </Popover>
    </div>
  );
}

FeedbackPrompt.propTypes = {
  trigger: PropTypes.node.isRequired,
  user: PropTypes.shape({
    feedbacks: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
    ),
    vanity_url: PropTypes.string.isRequired,
  }).isRequired,
};
