import entitySelectors from 'redux/entities/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { entityActions } from 'redux/entities/actions';

const useLocations = () => {
  const dispatch = useDispatch();
  const locations = useSelector(entitySelectors.locationSelector);
  const fetched = useSelector(entitySelectors.locationFetchFlagSelector);
  const fetch = () => dispatch(entityActions.fetchEntity('location'));

  return {
    locations,
    fetched,
    fetch,
  };
};

export default useLocations;
