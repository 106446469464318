import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WIDGET_FEATURED_ARTISTS } from 'constants/routes';
import { StylistPureComponent } from '../Locations/PageComponents/EditBanners/OurStylists/OurStylists';
import useWidgetFetcher from './useWidgetFetcher';

// eslint-disable-next-line prettier/prettier, no-unused-vars
const onArtistClick = artist => {
  // new tab with google.com
  const link = artist.share_url || artist.link;
  if (!link) return;
  window.open(link, '_blank');
};

function FeaturedArtists({ brand, region, preview, widgetCount }) {
  const artists = useWidgetFetcher('FeaturedArtists', widgetCount, { brand, region });
  const featuredArtists = useMemo(
    () => (_.isArray(artists) ? artists : artists?.featured_artists),
    [artists],
  );

  return (
    <StylistPureComponent
      widget={!preview}
      selectedSellers={featuredArtists || []}
      title="Featured Artists"
    />
  );
}

const iFrameCode = (region, brand, widgetCount) => {
  const src = `${window.location.origin}${WIDGET_FEATURED_ARTISTS}?region=${region}&brand=${brand}${
    widgetCount ? `&count=${widgetCount}` : ''
  }`;

  return String.raw`<iframe
  src="${src}"
  title="Featured Artists"
  style="width: 100%; max-width: 1250px; height: 100%; min-height: 400px; border: none;"
></iframe>`;
};

FeaturedArtists.propTypes = {
  brand: PropTypes.string,
  region: PropTypes.string,
  preview: PropTypes.bool,
  widgetCount: PropTypes.string,
};

export default FeaturedArtists;
export { iFrameCode };
