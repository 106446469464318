import entities from 'constants/entities';
import { put, takeLatest, select, call, fork, delay } from 'redux-saga/effects';
import { entityActions } from 'redux/entities/actions';
import { isEmpty, omit } from 'lodash-es';
import EntityService from 'api/services/EntityService';
import { toastr } from 'utils';
import i18n from 'i18n';
import { openModalAction } from 'redux/modal/actions';

import {
  UPDATE_SELECTED_LOCATION,
  GET_LOCATION_GROUP,
  ADD_LOCATION_PAGE_ELEMENT,
  DELETE_LOCATION_PAGE_ELEMENT,
  UPDATE_LOCATION_PAGE_ELEMENT,
  DELETE_LOCATION_GROUP,
  CREATE_LOCATION_GROUP,
  UPDATE_LOCATION_GROUP,
  PUBLISH_LOCATION_PAGE_ELEMENTS,
  UPDATE_LOCATION_PAGE_ELEMENT_POSITION,
} from './constants';
import { locationBodySelector, locationSelector } from './selectors';
import {
  setSavingFlag,
  getLocationGroupSuccess,
  addLocationPageElementSuccess,
  deleteLocationPageElementSuccess,
  updateLocationPageElementSuccess,
  deleteLocationGroupSuccess,
  createLocationGroupSuccess,
  updateLocationGroupSuccess,
  publishLocationPageElementsSuccess,
  updateLocationPageElementPositionSuccess,
} from './actions';

function* tryUpdate(location, tryNumber = 0) {
  try {
    const updated = yield call(
      [EntityService, 'update'],
      entities.location,
      location.uid,
      location,
    );

    yield put(entityActions.updateEntitySuccess(entities.location, updated));
    toastr.success(i18n.t('toastr:updateSuccess'));
  } catch (e) {
    if (tryNumber < 1) {
      yield tryUpdate(location, tryNumber + 1);
    } else {
      toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
      yield put(setSavingFlag(false));
    }
  }
}

function* updateLocation() {
  const location = yield select(locationSelector);
  const body = yield select(locationBodySelector);
  const updatedLocation = {
    ...omit(location, 'vanity_url'),
    page: body
      .filter(component => !isEmpty(component.properties))
      .map(component => omit(component, 'isNew')),
  };
  yield tryUpdate(updatedLocation);
}

function* getLocationGroup() {
  try {
    const response = yield call([EntityService, 'fetch'], entities.owner_group_page);
    yield put(getLocationGroupSuccess(response));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:fetchError'));
  }
}

function* createLocationGroup(action) {
  const {
    payload: { values },
  } = action;

  try {
    const response = yield call([EntityService, 'create'], entities.owner_group_page, values);

    yield put(createLocationGroupSuccess(response));
    toastr.success(i18n.t('toastr:success'));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:createError'));
  }
}

function* updateLocationGroup(action) {
  const {
    payload: { groupId, values },
  } = action;

  try {
    const response = yield call(
      [EntityService, 'update'],
      entities.owner_group_page,
      groupId,
      values,
    );

    yield put(updateLocationGroupSuccess(response));
    toastr.success(i18n.t('toastr:updateSuccess'));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:fetchError'));
  }
}

function* deleteLocationGroup(action) {
  const { payload } = action;
  try {
    yield call([EntityService, 'delete'], entities.owner_group_page, payload);
    yield put(deleteLocationGroupSuccess());
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:failure'));
  }
}

function* openModalWithDelay(pageModuleName, pageModuleId) {
  yield delay(330);
  yield put(openModalAction(`${pageModuleName?.toUpperCase()}_${pageModuleId}_COMPONENT_MODAL_ID`));
}

function* addLocationPageElement(action) {
  const { payload } = action;

  try {
    const response = yield call([EntityService, 'create'], 'location_page_module', payload);
    yield put(addLocationPageElementSuccess(response));
    if (!payload.skip_modal_open) yield fork(openModalWithDelay, response.name, response.id);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 1000);
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* updateLocationPageElement(action) {
  const { payload } = action;
  const location = yield select(locationSelector);

  try {
    yield call([EntityService, 'update'], 'location_page_module', payload.uid, payload);
    const response = yield call([EntityService, 'getLocationPages'], location.uid);
    yield put(updateLocationPageElementSuccess(response));

    setTimeout(() => {
      document.getElementById(payload.id)?.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
    toastr.success(i18n.t('toastr:updateSuccess'));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* updateLocationPageElementPosition(action) {
  const { payload } = action;
  const location = yield select(locationSelector);

  try {
    yield call([EntityService, 'updateLocationPagePosition'], payload.uid, payload.position);
    const response = yield call([EntityService, 'getLocationPages'], location.uid);
    yield put(updateLocationPageElementPositionSuccess(response));

    setTimeout(() => {
      document.getElementById(payload.id)?.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
    toastr.success(i18n.t('toastr:updateSuccess'));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* deleteLocationPageElement(action) {
  const { payload } = action;
  const location = yield select(locationSelector);

  try {
    yield call([EntityService, 'delete'], 'location_page_module', payload.uid);
    const response = yield call([EntityService, 'getLocationPages'], location.uid);
    yield put(deleteLocationPageElementSuccess(response));
    toastr.success(i18n.t('toastr:updateSuccess'));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:updateError'));
  }
}

function* publishLocationPageElements() {
  const location = yield select(locationSelector);

  try {
    yield call([EntityService, 'publishPageModules'], location.uid);
    yield put(publishLocationPageElementsSuccess(true));
    toastr.success(i18n.t('toastr:publishSuccess'));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('toastr:fetchError'));
  }
}

export const locationSagas = [
  takeLatest(UPDATE_SELECTED_LOCATION, updateLocation),

  takeLatest(GET_LOCATION_GROUP, getLocationGroup),

  takeLatest(ADD_LOCATION_PAGE_ELEMENT, addLocationPageElement),
  takeLatest(UPDATE_LOCATION_PAGE_ELEMENT, updateLocationPageElement),
  takeLatest(UPDATE_LOCATION_PAGE_ELEMENT_POSITION, updateLocationPageElementPosition),
  takeLatest(DELETE_LOCATION_PAGE_ELEMENT, deleteLocationPageElement),
  takeLatest(PUBLISH_LOCATION_PAGE_ELEMENTS, publishLocationPageElements),

  takeLatest(CREATE_LOCATION_GROUP, createLocationGroup),
  takeLatest(UPDATE_LOCATION_GROUP, updateLocationGroup),
  takeLatest(DELETE_LOCATION_GROUP, deleteLocationGroup),
];
