import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Wrapper, Modal, Form, Button } from 'components';
import { FormWrapper } from 'components/Modal/styles';
import useEntityForm from 'hooks/useEntityForm';
import { pick } from 'lodash';
import { currencies } from 'constants/currenciesList';
import countries from 'constants/countries';
import { useUserRole } from 'hooks/useUserRole';
import * as Roles from 'constants/roles';

const RegionForm = ({ type = 'create', initialValues, FormGroup }) => {
  const { t: prefixT } = useTranslation('pages', { keyPrefix: 'regions' });
  const { t } = useTranslation('app');
  const { onSubmit, dependencies, validationSchema } = useEntityForm(type, 'region', {
    dependencies: ['product_group'],
  });
  const userRole = useUserRole();
  const isSuperAdmin = userRole === Roles.SUPER_ADMIN;

  return (
    <Form
      initialValues={
        {
          ...pick(initialValues, [
            'name',
            'product_groups',
            'base_currency',
            'uid',
            'is_tax_enabled',
            'shipping_country',
          ]),
        } || {
          name: null,
          product_groups: null,
          base_currency: null,
          is_tax_enabled: null,
          shipping_country: '',
        }
      }
      onSubmit={values => onSubmit(values)}
      validationSchema={validationSchema}
    >
      <FormWrapper type={type}>
        <FormGroup label={prefixT('name')}>
          <Form.Input type="text" name="name" disabled={!isSuperAdmin} />
        </FormGroup>
        <FormGroup label={prefixT('selectProductGroup')}>
          <Form.MultiSelect
            options={dependencies.product_group.map(item => ({
              value: item.id,
              label: item.name,
            }))}
            name="product_groups"
            disabled={!isSuperAdmin}
          />
        </FormGroup>
        <FormGroup label={prefixT('currency')}>
          <Form.MultiSelect
            options={currencies.map(currency => ({
              value: currency.code,
              label: currency.description,
            }))}
            name="base_currency"
            isMulti={false}
            disabled={!isSuperAdmin}
          />
        </FormGroup>
        <FormGroup label={prefixT('shippingCountry')}>
          <Form.MultiSelect
            options={countries.map(country => ({
              value: country.code,
              label: country.name,
            }))}
            name="shipping_country"
            isMulti={false}
            disabled={!isSuperAdmin}
          />
        </FormGroup>
        <FormGroup label={prefixT('isTaxEnabled')}>
          <Form.MultiSelect
            options={[
              {
                value: true,
                label: 'true',
              },
              {
                value: false,
                label: 'false',
              },
            ]}
            name="is_tax_enabled"
            isMulti={false}
            disabled={!isSuperAdmin}
          />
        </FormGroup>
      </FormWrapper>
      <Modal.Footer>
        {({ closePopup }) => (
          <Wrapper d-flex justify-content-end>
            <Button onClick={closePopup} variant="white" className="mr-3">
              {t('cancel')}
            </Button>

            <Form.Submit onClick={closePopup}>{t(type)}</Form.Submit>
          </Wrapper>
        )}
      </Modal.Footer>
    </Form>
  );
};

RegionForm.propTypes = {
  type: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.object,
  FormGroup: PropTypes.func,
};

export default RegionForm;
