import CoreService from './CoreService';

class ApproveService extends CoreService {
  /* eslint-disable no-useless-constructor */
  constructor(endpoint) {
    super(endpoint);
  }

  review(uid, isApproved) {
    return this.api.post(`/profile_info/${uid}/approve`, { approved: isApproved });
  }

  approve(uid) {
    return this.api.post(`/profile_info/${uid}/approve`, { approved: true });
  }

  disapprove(uid) {
    return this.api.post(`/profile_info/${uid}/approve`, { approved: false });
  }

  previewPage(uid, profile_page_token) {
    return this.api.get(`/profile_info/${uid}?token=${profile_page_token}`);
  }

  sendInvite(uid) {
    return this.api.post(`/retailer_info/${uid}/send_invitation`);
  }

  changeRetailerPassword(retailerUid, password) {
    return this.api.post(`/retailer_info/${retailerUid}/update_password`, { password });
  }
}

const instance = new ApproveService('/v3');

export default instance;
