import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, Form, Wrapper } from 'components';
import { ADMIN_TOOL_LOGIN, AVEDA_LOGIN } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import AuthService from 'api/services/AuthService';
import history from 'utils/history';
import { toastr } from 'utils';
import useQuery from 'hooks/useQuery';

const ForgetPassword = () => {
  const [codeSent, setCodeSent] = React.useState(false);
  const { t } = useTranslation(['pages', 'app', 'toastr']);
  const query = useQuery();
  const isAdmin = query.get('admin');

  const onSubmitFunc = async (email, code, password) => {
    try {
      if (!codeSent) {
        try {
          await AuthService.sendChangePasswordCode(email);
          setCodeSent(true);
        } catch (e) {
          toastr.error(t('toastr:error'), 'Email not recognized');
        }
        return;
      }

      await AuthService.changePassword(email, code, password);
      toastr.success('Password changed');
      history.push(isAdmin ? ADMIN_TOOL_LOGIN : AVEDA_LOGIN);
    } catch (e) {
      if (e.response.data) {
        toastr.error(Object.values(e.response.data).toString());
      }
    }
  };

  return (
    <Container>
      <Row className="justify-content-center m-0">
        <Col xs="12" md="8" lg="6">
          <Card style={{ marginTop: '72px' }}>
            <div style={{ padding: '16px 36px' }}>
              <h1 className="display-4 text-center text-uppercase mb-3">
                {t('forgotPassword.title')}
              </h1>
              <p className="text-muted text-center mb-5">{t('forgotPassword.subtitle')}</p>
              <Form
                initialValues={{
                  email: '',
                  code: '',
                  password: '',
                }}
                onSubmit={values => onSubmitFunc(values.email, values.code, values.password)}
              >
                <Form.Group>
                  <Form.Label>{t('app:email')}</Form.Label>
                  <Form.Input name="email" type="text" />
                </Form.Group>
                {codeSent && (
                  <>
                    <Form.Group>
                      <Form.Label>{t('tfa.code')}</Form.Label>
                      <Form.Input name="code" type="text" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('app:password')}</Form.Label>
                      <Form.Input name="password" type="text" />
                    </Form.Group>
                  </>
                )}
                <Wrapper mb-3>
                  <Form.Submit size="lg" variant="dark" block>
                    {codeSent ? 'Change Password' : t('tfa.submit')}
                  </Form.Submit>
                </Wrapper>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgetPassword;
