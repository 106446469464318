import React, { useRef, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import * as hints from 'constants/lookFeatureHints';
import { singleLookSelector } from 'redux/looks/selectors';
import { wrapperStyleObject, toolbarSettings } from 'constants/WYSWIGEditorStyle';
import { updateLook } from 'redux/looks/actions';
import * as Ui from './DescriptionEditor.styles';

const DescriptionEditor = ({ isEditable, editorState, setEditorState, message }) => {
  // const [touched, setTouched] = useState(false);
  const { t } = useTranslation('ownerPortal');
  const { data: look } = useSelector(singleLookSelector);

  const editorRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      const blocksFromHTML = htmlToDraft(message);

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
        ),
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const onDescriptionChange = useCallback(async () => {
    // setTouched(curr => !curr); // also add setTouch to dependency array if you want to use it

    const updatedValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const isDescriprionEmpty = !updatedValue
      .replace('<br>', '')
      .replace('<p>', '')
      .replace('</p>', '').length;

    const isDescriptionValid = !(look.is_draft && isDescriprionEmpty);

    if (message !== updatedValue) {
      dispatch(
        updateLook(
          {
            message: updatedValue,
            feature_hints: {
              [hints.MESSAGE_HINT]: isDescriptionValid,
            },
          },
          'description',
        ),
      );
    }
  }, [editorState, useDispatch]);

  if (!isEditable) {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: message }} />;
  }

  // if (!touched) {
  //   return (
  //     <Ui.DescriptionMask
  //       onClick={() => {
  //         setTouched(!touched);
  //         setTimeout(() => {
  //           editorRef?.current.focus();
  //         }, 0);
  //       }}
  //     >
  //       {editorState.getCurrentContent().getPlainText()}
  //     </Ui.DescriptionMask>
  //   );
  // }

  return (
    <Ui.Wrapper onBlur={onDescriptionChange}>
      <Editor
        editorRef={ref => {
          editorRef.current = ref;
        }}
        editorState={editorState}
        wrapperStyle={wrapperStyleObject}
        editorStyle={{
          padding: '0 20px',
          height: 'calc(100% - 50px)',
        }}
        placeholder={t('Looks.descriptionPlaceholder')}
        toolbar={{
          ...toolbarSettings,
          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        onEditorStateChange={state => setEditorState(state)}
        style={{ flex: 1 }}
      />
    </Ui.Wrapper>
  );
};

DescriptionEditor.propTypes = {
  isEditable: PropTypes.bool,
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  message: PropTypes.string,
};
export { DescriptionEditor };
